<template>
  <div class="">
    <el-form
      :model="FORM_DEFECT()"
      label-width="auto"
      ref="form"
      :rules="rules"
      label-position="top"
      autocomplete="off"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item size="mini" label="Тип брак" prop="plateTypeId">
            <el-select
              v-model="FORM_DEFECT().plateTypeId"
              placeholder="Выберите тип знака"
              @change="changePlateType"
            >
              <el-option
                v-for="item in PLATE_TYPES_LIST()"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item size="mini" label="Госномер" prop="license_plate">
            <el-input
              :disabled="FORM_DEFECT().plateTypeId ? false : true"
              :placeholder="
                FORM_DEFECT().plateTypeId
                  ? PLATE_TYPES_LIST()[FORM_DEFECT().plateTypeId - 1]
                      .placeholder
                  : 'Выберите тип знака'
              "
              v-mask="serviceNameMask"
              v-model="FORM_DEFECT().license_plate"
              autocomplete="off"
              @input="
                FORM_DEFECT().license_plate = FORM_DEFECT().license_plate.toUpperCase()
              "
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item size="mini" label="Дата изготовления" prop="date_create">
        <el-form-item size="mini" prop="date_issuing" style="width: 150px;">
          <el-input
            v-model="FORM_DEFECT().date_create"
            placeholder="дд.мм.гггг"
            v-mask="'##.##.####'"
          ></el-input>
        </el-form-item>
      </el-form-item>
      <el-form-item size="mini" label="Количество брака">
        <el-input-number
          v-model="FORM_DEFECT().count_plate"
          :min="1"
          :max="2"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <span slot="footer" class="footer-dialog">
      <div v-if="newPlate" style="display: contents">
        <el-button type="danger" @click="closeModal">
          Закрыть
        </el-button>
        <el-button type="primary" @click="postDefect" :disabled="disableBth">
          Создать
        </el-button>
      </div>
      <div v-else style="display: contents">
        <el-button type="danger" @click="closeModal">
          Отменить
        </el-button>
        <el-button type="primary" @click="putDefect">
          Сохранить
        </el-button>
      </div>
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Moment from "moment";
export default {
  props: {
    plateId: Number,
    newPlate: Boolean,
    statusId: Number
  },
  data() {
    let checkPlate = (rule, value, cb) => {
      if (!value) {
        return cb(new Error("Введите номер"));
      } else {
        let re = new RegExp(
          this.PLATE_TYPES_LIST()[this.FORM_DEFECT().plateTypeId - 1].regexp
        );

        if (re.test(value)) {
          cb();
        } else cb(new Error("Неправильный формат ввода"));
      }
    };
    return {
      serviceNameMask: "A.##",
      disableBth: false,
      form: {
        companyId: null,
        plateTypeId: null,
        count_plate: 1,
        date_create: null,
        license_plate: null
      },
      rules: {
        plateTypeId: [
          { required: true, message: "Выберите тип знака", trigger: "blur" }
        ],
        license_plate: [{ validator: checkPlate, trigger: "blur" }],
        date_create: [
          {
            required: true,
            message: "Выберите дату",
            trigger: "blue"
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions([
      "GET_PLATE_TYPES",
      "POST_DEFECT_PLATES",
      "SET_FORM_DEFECT",
      "CHANGE_PLATES",
      "PUT_DEFECT_PLATES",
      "GET_PLATES_ALL",
      "GET_PLATES_COUNT",
      "GET_LOGS"
    ]),
    ...mapGetters(["PLATE_TYPES_LIST", "FORM_DEFECT", "PLATES"]),
    postDefect() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.disableBth = true;
          this.POST_DEFECT_PLATES(this.FORM_DEFECT());
          this.$emit("closeDefect");
          this.$refs["form"].resetFields();
          this.SET_FORM_DEFECT(this.form);
          setTimeout(() => {
            this.GET_PLATES_COUNT(this.$route.params.id);
            this.GET_LOGS();
          }, 2000);
          setTimeout(() => {
            this.GET_PLATES_ALL(this.$route.params.id);
          }, 1500);
          this.disableBth = false;
        }
      });
    },

    closeModal() {
      this.$emit("closeDefect");
      this.SET_FORM_DEFECT(this.form);
    },
    changePlateType(value) {
      this.serviceNameMask = this.PLATE_TYPES_LIST()[value - 1].mask;
      this.FORM_DEFECT().license_plate = "";
    },
    putDefect() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.disableBth = true;
          this.PUT_DEFECT_PLATES(this.FORM_DEFECT());
          this.$emit("closeDefect");
          this.SET_FORM_DEFECT(this.form);
          setTimeout(() => {
            this.GET_PLATES_ALL(this.$route.params.id);
            this.GET_LOGS();
          }, 1500);
          setTimeout(() => {
            this.GET_PLATES_COUNT(this.$route.params.id);
          }, 2000);
          this.disableBth = false;
        }
      });
    },
    dateNow() {
      let date = new Moment().subtract(1, "hours").format("DD.MM.YYYY");
      this.FORM_DEFECT().date_create = date;
    }
  },
  watch: {
    plateId: function(val) {
      if (val && this.statusId == 1) {
        this.PLATES().forEach(el => {
          if (el.id == this.plateId && el.statusProductId == undefined) {
            this.SET_FORM_DEFECT(Object.assign({}, el));
            this.serviceNameMask = this.PLATE_TYPES_LIST()[
              this.FORM_DEFECT().plateTypeId - 1
            ].mask;
          }
        });
      } else {
        this.SET_FORM_DEFECT(this.form);
        this.FORM_DEFECT().companyId = this.$route.params.id;
      }
    }
  },
  created: function() {
    if (this.PLATE_TYPES_LIST().length == 0) {
      this.GET_PLATE_TYPES();
    }
    if (this.plateId && this.statusId == 1) {
      this.PLATES().forEach(el => {
        if (el.id == this.plateId && el.statusProductId == undefined) {
          this.SET_FORM_DEFECT(Object.assign({}, el));
          this.serviceNameMask = this.PLATE_TYPES_LIST()[
            this.FORM_DEFECT().plateTypeId - 1
          ].mask;
        }
      });
    } else {
      this.SET_FORM_DEFECT(this.form);
      this.FORM_DEFECT().companyId = this.$route.params.id;
    }
    this.dateNow();
  }
};
</script>

<style scoped>
.footer-dialog {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-select {
  display: block;
}
</style>
