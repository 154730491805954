<template>
  <div id="admin">
    <HeaderBar nav-show :is-admin="true"></HeaderBar>
    <HeadLogo class="container" />

    <div class="main"><router-view /></div>
    <!-- <el-dialog
      :modalAppendToBody="false"
      title="Оповещение"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="600px"
    >
      <News v-on:closeModalNews="dialogFormVisible = false" />
    </el-dialog> -->
  </div>
</template>

<script>
import HeaderBar from "../components/HeaderBar";
// import News from "../components/News";
import HeadLogo from "../components/HeadLogo";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    HeaderBar,
    // News,
    HeadLogo
  },
  data() {
    return {
      activeIndex: null,
      moder: null,
      dialogFormVisible: false
    };
  },
  methods: {
    ...mapActions(["GET_NAME_USER", "RESET_MODER", "GET_NEWS"]),
    ...mapGetters(["USERROLE", "MODER", "NEWS"])
  },
  created: async function() {
    await this.GET_NAME_USER();
    // await this.GET_NEWS();
    // if (Array.isArray(this.NEWS()) && this.NEWS().length) {
    //   this.dialogFormVisible = true;
    // }
    this.USERROLE() == "Модератор"
      ? this.RESET_MODER(true)
      : this.RESET_MODER(false);

    if (this.$route.path == "/admin/company") this.activeIndex = "1";
    if (this.$route.path == "/admin/users") this.activeIndex = "2";
    if (this.$route.path == "/admin/materials") this.activeIndex = "3";
    if (this.$route.path == "/admin/news") this.activeIndex = "4";
  }
};
</script>

<style scoped>
.main {
  height: 100%;
  width: 100%;
  /* position: fixed; */
}
</style>
