<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Message } from "element-ui";
import axios from "axios";
export default {
  name: "app",
  methods: {
    ...mapActions(["LOGOUT_FROM_API", "GET_JWT_TOKEN_FROM_LOCAL"])
  },
  created: async function() {
    // eslint-disable-next-line no-unused-vars
    const store = this.$store;
    const router = this.$router;

    axios.interceptors.response.use(
      function(res) {
        if (res.data.message) {
          Message({
            message: res.data.message,
            showClose: true,
            offset: 150
          });
          delete res.data.message;
        }

        return res;
      },
      function(e) {
        if (e.response.status === 401) {
          store.dispatch("LOGOUT_FROM_API");
          router.push({ name: "Home" });
          location.reload();
          return Promise.reject(e);
        } else {
          Message({
            message: e.response.data.message,
            type: "error",
            offset: 150
          });
          return Promise.reject(e);
        }
      }
    );
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.small-font {
  font-size: 12px;
}
.el-table .warning-row {
  background: rgba(243, 83, 62, 0.61);
}
.el-table .pereproverit-row {
  background: rgba(41, 172, 247, 0.507);
}
.el-table .need-photo {
  background: rgba(235, 250, 100, 0.4);
}
.el-table .success-row {
  background: #f0f9eb;
}
.el-table__row--striped {
  background: #f5f7fa;
}
.flat {
  background-color: rgba(228, 175, 175, 0.363);
}
.border {
  border-right: 1px solid #ebeef5;
}
.el-table__row:hover {
  text-decoration: underline;
}
.current-row .el-button--warning {
  background-color: #267938;
}
/* .el-table__row.el-table__row--striped.pereproverit-row > .no-border {
  background: rgba(41, 172, 247, 0.507);
}
.el-table__row.el-table__row--striped.success-row > .no-border {
  background: #f0f9eb;
} */
</style>
