var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container clearfix obvodka"},[_c('div',{staticClass:"people-list",attrs:{"id":"people-list"}},[_c('div',{staticClass:"search"},[_c('el-input',{attrs:{"placeholder":"Поиск","width":"85%"},on:{"input":_vm.test},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}),_c('el-button',{attrs:{"icon":"el-icon-refresh","circle":""},on:{"click":_vm.refresh}})],1),_c('div',{staticStyle:{"height":"calc(100vh - 220px)","overflow-y":"auto"}},[_vm._l((_vm.all_news),function(news,index){return [_c('div',{key:news.companyId,staticClass:"friend-drawer--onhover",class:{
            friendDrawerNew: news.user_list_confirmeds,
            friendDrawer: !news.user_list_confirmeds
          },on:{"click":function($event){return _vm.openChat(
              news.companyId,
              news.company.title,
              news.company.avatar,
              index
            )}}},[(news.company.avatar)?_c('img',{staticClass:"profile-image",attrs:{"src":'https://gibdd.znakvlg.ru/' + news.company.avatar,"alt":"Аватар"}}):_c('i',{staticClass:"el-icon-s-custom profile-image",staticStyle:{"font-size":"50px"}}),_c('div',{staticClass:"text"},[(news.user_list_confirmeds)?_c('div',[_vm._v(" "+_vm._s(news.user_list_confirmeds.length)+" ")]):_vm._e(),_c('span',{staticClass:"company"},[_vm._v(_vm._s(news.company.title))]),_c('p',{staticClass:"text-muted"},[_vm._v(_vm._s(news.text.slice(0, 100) + "..."))])])]),_c('hr',{key:news.companyId + 'lin'})]})],2)]),(_vm.activeUser)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"chat",attrs:{"element-loading-text":"Loading...","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.8)"}},[_c('div',{staticClass:"chat-header clearfix"},[(_vm.avatar)?_c('img',{staticClass:"profile-image",attrs:{"src":'https://gibdd.znakvlg.ru/' + _vm.avatar,"alt":"avatar"}}):_vm._e(),_c('div',{staticClass:"chat-about"},[_c('div',{staticClass:"chat-with"},[_vm._v(_vm._s(_vm.activeUser))])]),_c('i',{staticClass:"fa fa-star"})]),_c('div',[_c('MessengerChat'),_c('ChatInput',{attrs:{"companyId":_vm.companyId,"chatBool":true}})],1)]):_c('div',{staticClass:"chat"},[_vm._m(0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"50px","width":"220px","font-size":"18px","font-weight":"600","margin":"auto","margin-top":"45%"}},[_c('span',[_vm._v("Выберите собеседника")])])}]

export { render, staticRenderFns }