<template>
  <div class="container ">
    <div class="head-table">
      <TabsMenu :is-admin="true" :active-index2="INDEX()" />
      <div class="">
        <el-select
          v-model="companyId"
          filterable
          size="mini"
          placeholder="Выберите предприятие"
          @change="selectCompany"
        >
          <el-option
            v-for="item in COMPANY_NAMES()"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
            <span style="float: right; color: red">{{ item.count }}</span>
            <span style="float: left; color: #8492a6; font-size: 13px">{{
              item.title
            }}</span>
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="table-company">
      <el-table
        :data="logs"
        stripe
        v-if="Array.isArray(logs) && logs.length"
        style="width: 80%;     margin-left: 10%; margin-top: 20px;"
      >
        <el-table-column
          property="userName"
          label="Пользователь"
        ></el-table-column>
        <el-table-column property="date" label="Дата"> </el-table-column>
        <el-table-column property="text" label="Действие"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import TabsMenu from "./TabsMenu";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    TabsMenu
  },
  data() {
    return {
      logs: null,
      companyId: null
    };
  },
  methods: {
    ...mapActions(["RESET_HEAD", "GET_COMPANY_NAMES"]),
    ...mapGetters(["INDEX", "COMPANY_COUNT", "COMPANY_NAMES"]),
    selectCompany(companyId) {
      this.$http.get("/api/company/admin-logs/" + companyId).then(res => {
        this.logs = res.data;
      });
    }
  },
  created: function() {
    this.RESET_HEAD("История действий");
    this.GET_COMPANY_NAMES();
  },
  updated: function() {}
};
</script>

<style scoped>
.table-company {
  height: calc(100vh - 180px);
}
.head-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
