<template>
  <div class="">
    <el-form
      size="mini"
      :model="form"
      ref="form"
      label-width="auto"
      :rules="rules"
    >
      <el-form-item prop="avatar" label="Аватар">
        <el-upload
          class="avatar-wrapper"
          :on-change="beforeAvatarUpload"
          action=""
          :limit="1"
          ref="avatarUpload"
          list-type="picture-card"
          :auto-upload="false"
          v-if="!form.avatar"
          accept=" .jpg, .jpeg, .png, .bmp, .heic"
        >
          <el-button size="small" type="primary">Click to upload</el-button>
          <div slot="file" slot-scope="{ file }">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
        <div class="" v-else>
          <div class="" style="margin-left: 30%;">
            <el-image
              class="avatar-wrapper"
              :src="'/' + form.avatar"
              fit="scale-down"
            >
            </el-image>

            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="deletaAvatar"
            ></el-button>
          </div>
        </div>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </el-form-item>
      <el-form-item prop="title" label="Название предприятия">
        <el-input
          v-model="form.title"
          autocomplete="off"
          placeholder="ИП ИВАНОВ"
        ></el-input>
      </el-form-item>
      <el-form-item prop="leader_name" label="Руководитель">
        <el-input
          v-model="form.leader_name"
          autocomplete="off"
          placeholder="Иванов Иван Иванович"
        ></el-input>
      </el-form-item>
      <el-form-item prop="contact_person" label="Контактное лицо">
        <el-input
          v-model="form.contact_person"
          autocomplete="off"
          placeholder="Иванов Иван Иванович"
        ></el-input>
      </el-form-item>
      <el-form-item prop="responsible_person" label="Ответственное лицо">
        <el-input
          prop="responsible_person"
          v-model="form.responsible_person"
          autocomplete="off"
          placeholder="Иванов Иван Иванович"
        ></el-input>
      </el-form-item>
      <el-form-item label="Почта" prop="mail">
        <el-input
          v-model="form.mail"
          autocomplete="off"
          placeholder="mail@mail.ru"
        ></el-input>
      </el-form-item>
      <el-form-item prop="phone_number" label="Телефон">
        <el-input
          v-model="form.phone_number"
          autocomplete="off"
          placeholder="+79999999999"
        ></el-input>
      </el-form-item>
      <el-form-item prop="adress" label="Адрес">
        <el-input
          v-model="form.adress"
          autocomplete="off"
          placeholder="ул. Ленина"
        ></el-input>
      </el-form-item>
      <el-form-item prop="head_company" label="Шапка реестра предприятия">
        <el-input v-model="form.head_company" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item
        prop="contract_number"
        label="Номер договора"
        placeholder="Номер договор"
      >
        <el-input v-model="form.contract_number" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item
        prop="print_statement"
        label="Модуль заявления"
        v-if="USERROLE() == `Админ`"
      >
        <el-switch v-model="form.print_statement"> </el-switch>
      </el-form-item>
      <el-form-item
        prop="report_perm"
        label="Модуль отчета"
        v-if="USERROLE() == `Админ`"
      >
        <el-switch v-model="form.report_perm"> </el-switch>
      </el-form-item>
      <el-form-item
        prop="type_organization"
        label="ИП\ООО"
        v-if="USERROLE() == `Админ`"
      >
        <el-switch
          v-model="form.type_organization"
          active-text="ИП"
          inactive-text="ООО"
        >
        </el-switch>
        <div v-if="form.type_organization == true">
          <el-input
            placeholder="свидетельства ОГРНИП"
            autocomplete="off"
            v-model="form.contract_number_ip"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item
        label="Заголовок в заявление"
        prop="header_doc_bool"
        v-if="form.print_statement"
      >
        <el-col :span="4">
          <el-switch
            v-model="form.header_doc_bool"
            @change="changeDoc"
            autocomplete="off"
          ></el-switch>
        </el-col>
        <el-col :span="20">
          <el-input
            :disabled="!form.header_doc_bool"
            placeholder="ООО Знак К"
            autocomplete="off"
            v-model="form.head_other"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item
        prop="flat_plates4"
        label="Плоские пластины Тип-1"
        v-if="USERROLE() == `Админ`"
      >
        <el-switch v-model="form.flat_plates4"> </el-switch>
      </el-form-item>
      <el-form-item
        prop="flat_plates"
        label="Плоские пластины Тип-1А"
        v-if="USERROLE() == `Админ`"
      >
        <el-switch v-model="form.flat_plates"> </el-switch>
      </el-form-item>
      <el-form-item
        prop="flat_plates2"
        label="Плоские пластины Тип-1БФ"
        v-if="USERROLE() == `Админ`"
      >
        <el-switch v-model="form.flat_plates2"> </el-switch>
      </el-form-item>
      <el-form-item
        prop="flat_plates3"
        label="Плоские пластины Тип-1A БФ"
        v-if="USERROLE() == `Админ`"
      >
        <el-switch v-model="form.flat_plates3"> </el-switch>
      </el-form-item>
      <el-form-item prop="agent_information" label="Информация о предприятии">
        <template slot="label">
          <span>Информация о предприятии</span>
          <el-popover
            placement="top-start"
            title="Описание"
            width="200"
            trigger="hover"
            content="Для перевода новой строки    используйте 'Enter'.  "
          >
            <el-button
              style="font-size: larger;"
              icon="el-icon-info"
              slot="reference"
              type="text"
            ></el-button>
          </el-popover>
        </template>
        <el-input
          :autosize="true"
          :activeValue="false"
          type="textarea"
          v-model="form.agent_information"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="comment" label="Дополнительная информация">
        <el-input
          :activeValue="false"
          type="textarea"
          v-model="form.comment"
          maxlength="250"
          show-word-limit
        >
        </el-input>
      </el-form-item>
    </el-form>

    <div class="footer-dialog">
      <div v-if="create_company" style="display: contents">
        <el-button type="danger" @click="updDialogVisible('form')">
          Закрыть
        </el-button>
        <el-button type="success" @click="submitNewCompany('form')">
          Создать
        </el-button>
      </div>
      <div v-else style="display: contents">
        <el-button type="danger" @click="updDialogVisible('form')">
          Отменить
        </el-button>
        <el-button type="success" @click="submitOldCompany('form')">
          Сохранить
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    companyId: Number
  },
  data() {
    return {
      form: {
        title: null,
        leader_name: null,
        contact_person: null,
        responsible_person: null,
        phone_number: null,
        head_company: null,
        comment: null,
        mail: null,
        adress: null,
        print_statement: false,
        report_perm: false,
        header_doc_bool: false,
        head_other: null,
        flat_plates: false,
        flat_plates2: false,
        flat_plates3: false,
        flat_plates4: false,
        type_organization: false,
        contract_number_ip: null,
        contract_number: null,
        agent_information: null
      },
      formLabelWidth: "250px",
      dialogVisible: false,
      dialogImageUrl: null,
      fileList: [],
      disabled: false,
      create_company: true,
      rules: {
        title: [
          {
            required: true,
            message: "Проверьте данные",
            trigger: "change"
          }
        ],
        leader_name: [
          {
            required: true,
            message: "Проверьте данные",
            trigger: "change"
          }
        ],
        mail: [
          {
            type: "email",
            required: true,
            message: "Проверьте данные",
            trigger: "change"
          }
        ],
        phone_number: [
          {
            required: true,
            message: "Проверьте данные",
            trigger: "change"
          }
        ],
        adress: [
          {
            required: true,
            message: "Проверьте данные",
            trigger: "change"
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions([
      "CREATE_COMPANY",
      "GET_COMPANY_ONE",
      "UPDATE_COMPANY",
      "GET_COMPANY"
    ]),
    ...mapGetters(["COMPANY_ONE", "USERROLE"]),
    updDialogVisible() {
      this.$emit("updDialog");
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    changeDoc() {
      this.form.head_other = null;
    },
    handleRemove(file) {
      for (let i = 0; i < this.$refs.avatarUpload.uploadFiles.length; i++) {
        if (this.$refs.avatarUpload.uploadFiles[i].uid == file.uid) {
          this.$refs.avatarUpload.uploadFiles.splice(i, 1);
        }
      }
      this.$refs.avatarUpload.$children[1].$el.hidden = false;
    },

    beforeAvatarUpload(file) {
      const isPhoto =
        file.raw.type === "image/jpg" ||
        file.raw.type === "image/bmp" ||
        file.raw.type === "image/png" ||
        file.raw.type === "image/jpeg" ||
        file.raw.type === "image/heic";
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isPhoto) {
        this.$message.error("Не тот формат!");
      }
      if (!isLt2M) {
        this.$message.error("Файл больше 5 МБ!");
      }
      this.$refs.avatarUpload.$children[1].$el.hidden = true;
      return isPhoto && isLt2M;
    },
    deletaAvatar() {
      this.form.avatar = null;
    },
    submitNewCompany(formName) {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.type_organization == false) {
            this.form.contract_number_ip = null;
          }
          let formData = new FormData();

          for (let i = 0; i < this.$refs.avatarUpload.uploadFiles.length; i++) {
            formData.append(
              `avatar${i}`,
              this.$refs.avatarUpload.uploadFiles[i].raw
            );
          }
          formData.append("data", JSON.stringify(this.form));
          this.CREATE_COMPANY(formData).then(() => {
            this.GET_COMPANY();
            this.$emit("updDialog");
            this.$refs[formName].resetFields();
            this.form.header_doc_bool = false;
            this.form.head_other = null;
          });
        } else {
          return false;
        }
      });
    },
    submitOldCompany() {
      if (this.form.type_organization == false) {
        this.form.contract_number_ip = null;
      }
      let formData = new FormData();
      if (this.$refs.avatarUpload) {
        for (let i = 0; i < this.$refs.avatarUpload.uploadFiles.length; i++) {
          formData.append(
            `avatar${i}`,
            this.$refs.avatarUpload.uploadFiles[i].raw
          );
        }
      }
      formData.append("data", JSON.stringify(this.form));
      this.UPDATE_COMPANY(formData).then(() => {
        this.$emit("updDialog");
        this.GET_COMPANY();
      });
    }
  },
  watch: {
    companyId: function(val) {
      if (val) {
        this.GET_COMPANY_ONE(this.companyId).then(() => {
          this.form = this.COMPANY_ONE();
          this.create_company = false;
        });
      } else {
        this.$refs["form"].resetFields();
        this.form.header_doc_bool = false;
        this.form.head_other = null;
        this.create_company = true;
      }
    }
  },
  created: function() {
    if (this.companyId) {
      this.GET_COMPANY_ONE(this.companyId).then(() => {
        this.form = this.COMPANY_ONE();
        this.create_company = false;
      });
    }
  }
};
</script>

<style scoped>
.footer-dialog {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.avatar-wrapper {
  height: 150px;
  width: 150px;
  margin: auto;
}
</style>
