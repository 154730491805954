/* eslint-disable no-unused-vars */
import axios from "axios";
import Moment from "moment";
const getDefaultState = () => ({
  plates: Array,
  countPlates: Array,
  defects: Array,
  document_types_list: Array,
  owner_types_list: Array,
  plate_types_list: Array,
  owner_types_doc_list: Array,
  plateForm: Object,
  defectForm: Object,
  status: Array,
  indexPlate: Number
});

const state = getDefaultState();

const getters = {
  OWNER_TYPES_LIST: state => {
    return state.owner_types_list;
  },
  INDEX_PLATE: state => {
    return state.indexPlate;
  },
  DOCUMENT_TYPES_LIST: state => {
    return state.document_types_list;
  },
  PLATE_TYPES_LIST: state => {
    return state.plate_types_list;
  },
  OWNER_TYPES_DOC_LIST: state => {
    return state.owner_types_doc_list;
  },
  PLATES: state => {
    return state.plates;
  },
  PLATES2: state => {
    return state.defects;
  },
  FORM_PLATES: state => {
    return state.plateForm;
  },
  FORM_DEFECT: state => {
    return state.defectForm;
  },
  COUNT_PLATES: state => {
    return state.countPlates;
  },
  STATUS_PLATE: state => {
    return state.status;
  }
};

const actions = {
  RESET_PLATES: async ({ commit }) => {
    commit("resetState");
  },
  GET_DOCUMENT_TYPES: async ({ commit }) => {
    await axios.get("/api/agent/document").then(res => {
      commit("SET_DOCUMENT_TYPES", res.data);
    });
  },
  GET_OWNER_TYPES: async ({ commit }) => {
    await axios
      .get("/api/agent/owner")
      .then(res => {
        commit("SET_OWNER_TYPES", res.data);
      })
      .catch(e => {
        console.log(e);
      });
  },
  GET_STATUS: async ({ commit }) => {
    await axios.get("/api/plate/status").then(res => {
      commit("SET_STATUS", res.data);
    });
  },
  GET_PLATE_TYPES: async ({ commit }) => {
    await axios
      .get("/api/agent/plate")
      .then(res => {
        commit("SET_PLATE_TYPES", res.data);
      })
      .catch(e => {
        console.log(e);
      });
  },
  GET_OWNER_DOCUMENT_TYPES: async ({ commit }) => {
    await axios.get("/api/agent/documentowner").then(res => {
      commit("SET_DOCUMENT_OWNER_TYPES", res.data);
    });
  },
  GET_PLATES: async ({ commit }, { id, status }) => {
    await axios.get(`/api/plate/${id}?status=${status}`).then(res => {
      commit("SET_PLATES", res.data);
    });
  },
  GET_PLATES_ALL: async ({ commit }, id, page) => {
    await axios.get(`/api/plate/all/${id}?page=${page}`).then(res => {
      commit("SET_PLATES", res.data);
    });
  },
  GET_PLATES_DEFECT: async ({ commit }, id) => {
    await axios.get(`/api/plate/defect/${id}`).then(res => {
      //commit("SET_PLATES", res.data);
      commit("SET_PLATES", res.data);
    });
  },
  POST_PLATES: async ({ commit }, plateDate) => {
    await axios
      .post("/api/plate", plateDate, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
      .then(res => {
        commit("SET_STATUS", res.data);
      });
  },

  POST_DEFECT_PLATES: async (commit, plateDate) => {
    await axios.post("/api/plate/defect", plateDate).then(res => {});
  },
  SET_FORM_PLATES: async ({ commit }, form) => {
    commit("RESET_FORM", form);
  },
  SET_FORM_DEFECT: async ({ commit }, form) => {
    commit("RESET_FORM_DEFECT", form);
  },

  PUT_DEFECT_PLATES: async ({ commit }, plateDate) => {
    await axios.put("/api/plate/defect", plateDate);
  },

  PUT_PLATES: async ({ commit }, plateDate) => {
    await axios.post("/api/plate/updPlate", plateDate, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  },

  BLOCK_PLATES: async ({ commit }, plateDate) => {
    await axios.post("/api/plate/block", plateDate);
  },
  GET_PLATES_COUNT: async ({ commit }, id) => {
    await axios.get(`/api/company/store/${id}`).then(res => {
      commit("SET_COUNT", res.data);
    });
  },

  AUDIT_POST: async ({ commit }, plateDate) => {
    await axios.post("/api/plate/audit", plateDate);
  },
  DELETE_PLATE: async ({ commit }, id) => {
    commit("DEL_PLATE", id);
  },

  RESET_TABLE_PLATE: async ({ commit }, data) => {
    commit("SET_PLATES", data);
  },
  DEL_FILE: async ({ commit }, index) => {
    commit("DELETE_FILE", index);
  },
  DEL_FILE2: async ({ commit }, index) => {
    commit("DELETE_FILE2", index);
  },
  SORT_PLATE_DATE: async ({ commit }, type) => {
    commit("SORT_PLATE_DATE_MUT", type);
  },

  COPY_PLATE_POST: async ({ commit }, data) => {
    await axios.post("/api/plate/copy", data);
  },
  SET_INDEX_PLATE: async ({ commit }, data) => {
    commit("INDEX_PLATE_SET", data);
  }
};

const mutations = {
  INDEX_PLATE_SET: (state, data) => {
    state.indexPlate = data;
  },
  SORT_PLATE_DATE_MUT: (state, table) => {
    if (table.fieldName == "date_create") {
      if (table.sortingType == "descending") {
        state.plates.sort(
          (a, b) =>
            new Moment(b.date_create, "DD.MM.YYYY").format("YYYYMMDD") -
              new Moment(a.date_create, "DD.MM.YYYY").format("YYYYMMDD") ||
            b.num - a.num
        );
      }
      if (table.sortingType == "ascending") {
        state.plates.sort(
          (a, b) =>
            new Moment(a.date_create, "DD.MM.YYYY").format("YYYYMMDD") -
              new Moment(b.date_create, "DD.MM.YYYY").format("YYYYMMDD") ||
            a.num - b.num
        );
      }
      if (table.sortingType == null) {
        localStorage.removeItem("date_create");
      } else localStorage.setItem("date_create", table.sortingType);
    }
    if (table.fieldName == "plateTypeId") {
      if (table.sortingType == "descending") {
        state.plates.sort((a, b) => b.plateTypeId - a.plateTypeId);
      }
      if (table.sortingType == "ascending") {
        state.plates.sort((a, b) => a.plateTypeId - b.plateTypeId);
      }
      if (table.sortingType == null) {
        localStorage.removeItem("plateTypeId");
      } else localStorage.setItem("plateTypeId", table.sortingType);
    }
  },
  SET_DOCUMENT_TYPES: (state, data) => {
    state.document_types_list = data;
  },
  SET_OWNER_TYPES: (state, data) => {
    state.owner_types_list = data;
  },
  SET_PLATE_TYPES: (state, data) => {
    state.plate_types_list = data;
  },
  SET_DOCUMENT_OWNER_TYPES: (state, data) => {
    state.owner_types_doc_list = data;
  },
  SET_PLATES: (state, data) => {
    state.plates = data;
  },
  SET_DEFECT: (state, data) => {
    state.defects = data;
  },
  ADD_PLATES: (state, data) => {
    state.plates.push(data);
  },
  DEL_PLATE: (state, id) => {
    for (let i = 0; i < state.plates.length; i++) {
      if (state.plates[i].id == id) {
        state.plates.splice(i, 1);
      }
    }
  },
  RESET_FORM: (state, data) => {
    state.plateForm = data;
  },
  RESET_FORM_DEFECT: (state, data) => {
    state.defectForm = data;
  },
  SET_COUNT: (state, data) => {
    state.countPlates = data;
  },
  SET_STATUS: (state, data) => {
    state.status = data;
  },
  DELETE_FILE: (state, index) => {
    state.plateForm.documents_statements[index].delete = true;
  },
  DELETE_FILE2: (state, index) => {
    state.plateForm.documents_statement_tcs[index].delete = true;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
