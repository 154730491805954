<template>
  <div class="">
    <el-form
      :model="form"
      label-width="120px"
      size="mini"
      :rules="rules"
      ref="contract"
    >
      <el-form-item label="Месяц" prop="month">
        <el-select v-model="form.month" placeholder="Выберите месяц">
          <el-option
            v-for="item in filterDate"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-popover
          placement="bottom-end"
          width="500"
          trigger="click"
          style="margin-left: 150px;"
        >
          <div class="test">
            <el-table :data="history">
              <el-table-column
                width="70"
                property="num_act"
                label="Номер Акта"
              ></el-table-column>
              <el-table-column
                width="100"
                property="month_report"
                label="Месяц"
              ></el-table-column>
              <el-table-column
                width="100  "
                property="contract_date"
                label="Дата договора"
              ></el-table-column>
              <el-table-column
                width="95"
                property="contract_date"
                label="Дата генерации отчета"
              >
                <template slot-scope="scope">
                  {{ dateFormat(scope.createdAt) }}
                </template>
              </el-table-column>
              <el-table-column width="70">
                <template slot-scope="scope">
                  <el-button type="text" @click="submitOldForm(scope.$index)"
                    >Скачать</el-button
                  >
                </template>
              </el-table-column>
              <el-table-column width="70">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="deleteItemHistory(scope.$index)"
                    >Удалить</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-button slot="reference" size="medium" type="primary"
            >История</el-button
          >
        </el-popover>
      </el-form-item>
      <el-form-item label="Номер акта" prop="date_create">
        <el-input-number v-model="form.num_act" :min="1"></el-input-number>
      </el-form-item>
      <el-form-item label="Дата договора" prop="contract_date">
        <el-input
          v-model="form.contract_date"
          placeholder="дд.мм.гггг"
          v-mask="'##.##.####'"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-table
      ref="multipleTable"
      :data="form.plates_price"
      style="width: 100%"
      :cell-class-name="tableRowClassName"
      @select="test"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="Тип пластин" width="120">
        <template slot-scope="scope">{{ scope.row.title }}</template>
      </el-table-column>
      <el-table-column property="name" label="Стоимость (1 шт.)">
        <template slot-scope="scope">
          <el-input-number
            size="mini"
            v-model="form.plates_price[scope.$index].price_agent"
            step-strictly
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column
        property="address"
        label="Общая стоимость (1 шт.)"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-input-number
            size="mini"
            v-model="form.plates_price[scope.$index].price_clinet"
            step-strictly
          ></el-input-number>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="footer-dialog">
      <div
        style="display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;"
      >
        <el-button type="danger" @click="closeModal"> Закрыть</el-button>
        <el-button type="success" @click="submit">
          Получить Отчет
        </el-button>
      </div>
    </span>
  </div>
</template>

<script>
import Moment from "moment";
import saveAs from "file-saver";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      filterDate: [],
      form: {
        month: null,
        contract_date: null,
        num_act: 1,
        plates_price: [],
        save: true
      },
      history: null,
      rules: {
        month: [
          {
            required: true,
            message: "Выберите месяц",
            trigger: "blur"
          }
        ],
        contract_date: [
          {
            required: true,
            message: "Поле не может быть пустым",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    ...mapGetters(["PLATE_TYPES_LIST", "INFO_COMPANY"]),
    closeModal() {
      this.$emit("closeModalReport");
      // Modal
    },
    // test(val, oldval, id) {},
    genDate() {
      Moment.locale("ru");
      for (let i = 0; i < 6; i++) {
        const str = Moment()
          .startOf("month")
          .subtract(i, "months")
          .format("MMMM YYYY");
        this.filterDate.push({
          label: str[0].toUpperCase() + str.slice(1),
          value: Moment()
            .startOf("month")
            .subtract(i, "months")
            .format("DD.MM.YYYY")
        });
      }
    },
    test(sel, lol) {
      // console.log("dadads", sel, lol)
      this.form.plates_price[lol.plateTypeId - 1].add_table = !this.form
        .plates_price[lol.plateTypeId - 1].add_table;
    },
    genTypePlate() {
      this.PLATE_TYPES_LIST().forEach(plate => {
        this.form.plates_price.push({
          plateTypeId: plate.id,
          title: plate.title,
          price_clinet: 0,
          price_agent: 0,
          add_table: false
        });
      });
    },
    dateFormat(date) {
      return Moment(date).format("DD.MM.YYYY");
    },
    deleteItemHistory(index) {
      this.$http
        .delete("/api/company/history-reports/" + this.history[index].id)
        .then(() => {
          this.$http.get("/api/company/history-reports").then(res => {
            this.history = res.data;
          });
        });
    },
    tableRowClassName() {
      return "small-font";
    },
    submit() {
      this.$refs["contract"].validate(valid => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: "Загрузка",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          console.log(this.form)
          this.$http
            .post("/api/plate/report-doc", this.form, {
              responseType: "blob"
            })
            .then(res => {
              saveAs(
                res.data,
                `Отчет за ${Moment(this.form.month, "DD.MM.YYYY")
                  .format("MMMM")
                  .toUpperCase()} ${this.INFO_COMPANY().head_company}.xlsx`
              );
              this.$http.get("/api/company/history-reports").then(res => {
                if (Array.isArray(res.data) && res.data.length) {
                  this.history = res.data;
                }
              });
            });
          loading.close();
        }
      });
    },
    submitOldForm(index) {
      const data = {
        month: Moment(this.history[index].month_report, "MMMM YYYY").format(
          "DD.MM.YYYY"
        ),
        contract_date: this.history[index].contract_date,
        num_act: this.history[index].num_act,
        plates_price: JSON.parse(this.history[index].plates_price),
        save: false
      };
      const loading = this.$loading({
        lock: true,
        text: "Загрузка",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$http
        .post("/api/plate/report-doc", data, {
          responseType: "blob"
        })
        .then(res => {
          saveAs(
            res.data,
            `Отчет за ${Moment(data.month, "DD.MM.YYYY")
              .format("MMMM")
              .toUpperCase()} ${this.INFO_COMPANY().head_company}.xlsx`
          );
        });
      loading.close();
    },
    toggle(data) {
      if (data.length) {
        this.$nextTick(function() {
          data.forEach(item => {
            //If paid == 1 in the data, let this column be selected
            if (item.add_table) {
              //multipleTable is the ref attribute of this table, true is selected
              this.$refs.multipleTable.toggleRowSelection(item, true);
            }
          });
        });
      }
    }
  },
  created() {
    this.genDate();
    this.$http.get("/api/company/history-reports").then(res => {
      if (Array.isArray(res.data) && res.data.length) {
        this.history = res.data;
      }
    });
    this.$http.get("/api/agent/report").then(res => {
      if (res.data.cancel) {
        this.genTypePlate();
      } else {

        for (let i = 0; i < this.PLATE_TYPES_LIST().length; i++) {
          if (res.data.reports_plates.length == 11 && i == 11) {
            break
          }
          res.data.reports_plates[i].title = this.PLATE_TYPES_LIST()[i].title;
        }
        if (res.data.reports_plates.length == 11) {
          res.data.reports_plates.push({
            plateTypeId: this.PLATE_TYPES_LIST()[11].id,
            title: this.PLATE_TYPES_LIST()[11].title,
            price_clinet: 0,
            price_agent: 0,
            add_table: false
          });
          res.data.reports_plates.push({
            plateTypeId: this.PLATE_TYPES_LIST()[12].id,
            title: this.PLATE_TYPES_LIST()[12].title,
            price_clinet: 0,
            price_agent: 0,
            add_table: false
          });
        }
        this.form = {
          month: null,
          contract_date: res.data.contract_date,
          num_act: res.data.num_act,
          plates_price: res.data.reports_plates,
          save: true
        };
        this.toggle(this.form.plates_price);
      }
    });
  }
};
</script>

<style scoped></style>
