<template>
  <div class="home" v-on:keyup.enter="submitLogin">
    <HeaderBar v-bind:nav-show="false"> </HeaderBar>
    <div class="main ">
      <div class="login-form">
        <div class="login-wrapper">
          <el-form
            :model="loginData"
            ref="login"
            :rules="rules"
            label-width="70px"
            class="login-form"
          >
            <el-form-item prop="login" label="Логин">
              <el-input
                v-model="loginData.login"
                placeholder="Введите логин или email"
                prefix-icon="el-icon-user"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password" label="Пароль">
              <el-input
                v-model="loginData.password"
                prefix-icon="el-icon-key"
                placeholder="Введите пароль"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="success"
                size="small"
                style="width:100%"
                @click="submitLogin"
                >Вход</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "../components/HeaderBar";
import { mapActions, mapGetters } from "vuex";
// eslint-disable-next-line no-unused-vars
import jwt_decode from "jwt-decode";
export default {
  name: "Home",
  components: {
    HeaderBar
  },
  data() {
    return {
      loginData: {
        login: "",
        password: ""
      },
      rules: {
        login: [
          {
            required: true,
            message: "Поле Логин не может быть пустым!",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "Поле Пароль не может быть пустым！",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions(["LOGIN_FROM_API", "RESET_HEAD"]),
    ...mapGetters(["JWTTOKEN"]),
    lol() {},
    submitLogin() {
      this.$refs.login.validate(valid => {
        if (valid) {
          this.LOGIN_FROM_API(this.loginData).then(() => {
            if (jwt_decode(this.JWTTOKEN()).userRole == "Агент") {
              this.$router.push({
                name: "Company",
                params: { id: jwt_decode(this.JWTTOKEN()).companyId }
              });
            } else {
              this.$router.push({
                name: "Admin"
              });
            }
          });
        }
      });
    }
  },
  mounted() {
    // this.GET_JWT_TOKEN_FROM_LOCAL();
  }
};
</script>

<style scoped>
.main {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(156deg, #e4e7ed, 0%, #e4e7ed 100%);
}

.login-wrapper {
  width: 400px;
  padding: 10px 20px;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 25%;
}
.login-form {
  margin: 10px;
  margin-right: 20px;
}
</style>
