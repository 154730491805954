<template>
  <div class="container " st>
    <div
      class="head-table"
      v-if="Array.isArray(COMPANY_NAMES()) && COMPANY_NAMES().length"
    >
      <TabsMenu :is-admin="true" />
    </div>
    <!-- <el-table
        ref="table"
        :data="NEWS2()"
        stripe
        @cell-click="highlightRow"
        @cell-dblclick="openNewsDb"
        :cell-class-name="tableCellClassName"
        height="100%"
        highlight-current-row
        style="width: 100% "
      >
        <el-table-column type="index" label="№"> </el-table-column>
        <el-table-column label="Дата" prop="createdAt" width="100">
          <template slot-scope="scope"
            >{{ dataFormat(scope.row.createdAt) }}
          </template>
        </el-table-column>
        <el-table-column
          label="Заголовок"
          prop="title"
          v-if="Array.isArray(COMPANY_NAMES()) && COMPANY_NAMES().length"
        >
          <template slot-scope="scope">
            {{ scope.row.title }}
            {{
              companyId == null
                ? "(" + companyName(scope.row.companyId) + ")"
                : ""
            }}
          </template>
        </el-table-column>

        <el-table-column label="Текст">
          <template slot-scope="scope">
            <el-popover
              placement="bottom-end"
              :title="scope.row.title"
              width="500"
              trigger="hover"
              :content="scope.row.text"
            >
              <span slot="reference">
                {{ scope.row.text.slice(0, 100) + "..." }}</span
              >
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="Информация" width="200" size="mini">
          <template slot-scope="scope">
            <el-button
              @click.stop="openNews(scope.row.id, scope.row.companyId)"
              type="danger"
              size="mini"
              >Доп. Информация</el-button
            >
          </template>
        </el-table-column>
      </el-table> -->
    <ChatMain
      :newsAll="ALL_NEWS()"
      style="border: 1px solid rgb(172 175 177);"
    />

    <el-dialog
      :modalAppendToBody="false"
      title="Информация"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
      width="700px"
    >
      <MessengerChat :newsAll="ALL_NEWS()" />
      <ChatInput
        :companyId="companyIdForChat"
        :chatBool="true"
        v-on:updChat="newsPost"
      />
      <div class="" v-if="Array.isArray(INFO_USERS()) && INFO_USERS().length">
        <el-table
          ref="table"
          :data="INFO_USERS()"
          height="100%"
          size="mini"
          highlight-current-row
          style="width: 100% "
        >
          <el-table-column type="index" label="№"> </el-table-column>
          <el-table-column label="Пользователь" prop="user.login">
          </el-table-column>
          <el-table-column label="Прочитано/Не прочитано">
            <template slot-scope="scope">
              <span v-if="scope.row.confirmed">Прочитано</span>
              <span v-else>Не прочитано</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="footer-dialog">
        <el-button @click.stop="close">Закрыть</el-button>
      </span>
    </el-dialog>
    <el-dialog
      destroy-on-close
      :modalAppendToBody="false"
      title="Отправить новость"
      :visible.sync="newsFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="500px"
    >
      <NewsForm
        v-bind:company-id="companyId"
        v-bind:table-news="true"
        v-on:closeModalNews="newsClose()"
      />
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Moment from "moment";
import NewsForm from "./NewsForm";
import TabsMenu from "./TabsMenu";
import MessengerChat from "./MessengerChat/MessengerChat.vue";
import ChatInput from "./NewsForm.vue";
import ChatMain from "./Chat/Chat.vue";
export default {
  components: {
    NewsForm,
    TabsMenu,
    ChatInput,
    // eslint-disable-next-line vue/no-unused-components
    MessengerChat,
    ChatMain
  },
  data() {
    return {
      dialogFormVisible: false,
      dialogFormVisible2: false,
      newsFormVisible: false,
      newsIndex: 0,
      companyIdForChat: null,
      companyId: null,
      timeoutId: null
    };
  },

  methods: {
    ...mapActions([
      "GET_NAME_USER",
      "GET_NEWS_COMPANY",
      "GET_COMPANY_NAMES",
      "GET_NEWS_COMPANY_INFO_CONF",
      "RESET_HEAD",
      "GET_NEWS_ALL",
      "GET_NEWS_COMPANY_ALL",
      "SET_SCROLL",
      "RESET_SCROLL",
      "SET_ROWINDEX",
      "SET_PAGENUMBER"
    ]),
    ...mapGetters([
      "NEWS2",
      "COMPANY_NAMES",
      "INFO_USERS",
      "ROLES",
      "ALL_NEWS",
      "ROWINDEX",
      "SCROLLTOP"
    ]),
    highlightRow(row) {
      if (!this.timeoutId) {
        this.timeoutId = setTimeout(() => {
          if (this.currentRow == row) {
            this.currentRow = null;
            this.RESET_SCROLL();

            return this.setCurrent();
          }
          this.setCurrent(row);
          this.currentRow = row;
          this.SET_SCROLL({
            name: this.$route.name,
            top: this.$refs["table"].bodyWrapper.scrollTop
          });
          this.SET_ROWINDEX(row.id);
          this.timeoutId = null;
        }, 500);
      }
    },
    async openNews(id, companyId) {
      this.companyIdForChat = companyId;
      this.newsIndex = id;
      await this.GET_NEWS_COMPANY_INFO_CONF(id);
      await this.GET_NEWS_COMPANY_ALL(companyId);
      this.dialogFormVisible = true;
    },
    async openNewsDb(row) {
      clearTimeout(this.timeoutId);
      this.companyIdForChat = row.companyId;
      this.newsIndex = row.id;
      await this.GET_NEWS_COMPANY_INFO_CONF(row.id);
      await this.GET_NEWS_COMPANY_ALL(row.companyId);
      this.dialogFormVisible = true;
    },
    newsPost() {
      this.newsFormVisible = false;
      setTimeout(() => {
        this.GET_NEWS_ALL();
      }, 1000);
    },
    setCurrent(row) {
      this.$refs.table.setCurrentRow(row);
    },
    dataFormat(date) {
      let a = new Moment(date).format("DD.MM.YYYY");

      return a.toString();
    },
    newsClose() {
      this.newsFormVisible = false;
    },
    companyName(id) {
      return this.COMPANY_NAMES().find(el => {
        return el.id == id;
      }).title;
    },
    close() {
      this.dialogFormVisible = false;
    },
    openDialog() {
      this.newsFormVisible = true;
    },
    tableCellClassName({ row }) {
      if (row.addUser) {
        return "pereproverit-row no-border";
      }
    },
    changeCompany(id) {
      this.companyId = id;
      this.GET_NEWS_COMPANY(id);
    }
  },
  created: async function() {
    this.RESET_HEAD("Список новостей");
    await this.GET_COMPANY_NAMES();

    //await this.GET_NEWS_ALL();
  },
  updated: function() {
    if (this.$refs["table"]) {
      this.$refs["table"].bodyWrapper.scrollTop = this.SCROLLTOP()(
        this.$route.name
      );
      const id = this.ROWINDEX();
      this.currentRow = this.$refs.table.data.find(el => {
        if (el.id === id) {
          return true;
        }
        return false;
      });
      this.$refs.table.setCurrentRow(this.currentRow);
    }
  }
};
</script>

<style scoped>
.table-h {
  height: calc(100vh - 180px);
}
.head-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
