<template>
  <div class="container" style="position: relative; margin-top: 10px;">
    <el-button
      icon="el-icon-back"
      class="arrow-left"
      circle
      v-if="FORM_PLATES()"
      @click="previousPlate"
    ></el-button>
    <div class="buttonflex">
      <el-button
        icon="el-icon-arrow-left"
        type="info"
        size="mini"
        @click="
          $router.push({ name: 'Company', params: { id: $route.params.id } })
        "
      >
        К знакам
      </el-button>
      <span
        v-if="
          STATUS_PLATE().length > 2 &&
            Object.keys(this.FORM_PLATES()).length > 0
        "
        >Статус: {{ STATUS_PLATE()[FORM_PLATES().statusProductId - 1].title }}
      </span>
      <span slot="footer" class="footer-dialog">
        <div v-if="newPlate" style="display: contents">
          <el-button type="primary" size="mini" @click="closeModal">
            Закрыть
          </el-button>
          <el-button
            type="primary"
            @click="printDoc"
            size="mini"
            icon="el-icon-printer"
          >
            Печать заявления
          </el-button>
          <el-button @click="resetForm('plateForm')">Очистить</el-button>
          <el-button @click="postPlate">
            Создать
          </el-button>
        </div>

        <div v-else style="display: contents">
          <el-popover
            style="    margin-right: 10px;"
            v-if="
              Array.isArray(FORM_PLATES().info_edit_messages) &&
                this.FORM_PLATES().info_edit_messages.length
            "
            placement="left-start"
            title="Ошибка"
            width="400"
            trigger="hover"
            :content="FORM_PLATES().info_edit_messages[0].message"
          >
            <el-button slot="reference" type="warning" size="mini"
              >Описание ошибки</el-button
            >
          </el-popover>
          <el-button
            type="primary"
            size="mini"
            @click="printDoc"
            v-if="INFO_COMPANY().print_statement"
            icon="el-icon-printer"
          >
            Печать заявления
          </el-button>
          <el-button
            type="primary"
            size="mini"
            @click="putPlate"
            :disabled="FORM_PLATES().statusProductId == 2"
          >
            Сохранить
          </el-button>
        </div>
      </span>
    </div>
    <el-button
      icon="el-icon-right"
      class="arrow-right"
      circle
      v-if="this.PLATES().length > 1"
      @click="nextPlate"
    ></el-button>
    <div class="flex" v-if="Object.keys(this.FORM_PLATES()).length > 0">
      <div
        class="doc-img"
        v-if="Array.isArray(this.PLATES()) && this.PLATES().length"
      >
        <div class="">
          <template v-for="(item, index) in imgList">
            <el-image
              :key="item.count + 'count' + index"
              :src="item"
              fit="scale-down"
              @click="onPreview(index)"
            >
            </el-image>
          </template>
        </div>
      </div>

      <el-form
        :model="FORM_PLATES()"
        label-width="auto"
        ref="plateForm"
        style="background: #efefef;"
        :disabled="FORM_PLATES().statusProductId == 2"
        label-position="top"
        :rules="rules"
      >
        <div style="width:600px">
          <div class="info-car size-collum">
            <span style="font-weight: bold;">
              Сведения о транспортном средстве
            </span>
            <el-form-item
              size="mini"
              prop="plateTypeId"
              style="font-size: 50px"
              label="Государственный регистрационный знак"
            >
              <el-row>
                <el-col :span="12" v-if="FORM_PLATES().plateTypeId">
                  <el-select
                    v-model="FORM_PLATES().plateTypeId"
                    placeholder="Выберите тип знака"
                    @change="changePlateType"
                  >
                    <el-option
                      v-for="item in this.PLATE_TYPES_LIST()"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="12">
                  <el-form-item size="mini" prop="license_plate">
                    <el-input
                      @input="
                        FORM_PLATES().license_plate = FORM_PLATES().license_plate.toUpperCase()
                      "
                      :disabled="FORM_PLATES().plateTypeId ? false : true"
                      :placeholder="
                        FORM_PLATES().plateTypeId
                          ? PLATE_TYPES_LIST()[FORM_PLATES().plateTypeId - 1]
                              .placeholder
                          : 'Выберите тип знака'
                      "
                      v-mask="serviceNameMask"
                      v-model="FORM_PLATES().license_plate"
                      @focus="test(FORM_PLATES().plateTypeId)"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item
              size="mini"
              label="Серийный номер знака"
              prop="serial_number"
            >
              <el-input
                placeholder="Серийный номер"
                v-mask="serialNumMask"
                @input="
                  FORM_PLATES().serial_number = FORM_PLATES().serial_number.toUpperCase()
                "
                v-model="FORM_PLATES().serial_number"
              >
              </el-input>
            </el-form-item>
            <el-form-item size="mini">
              <el-row>
                <el-col :span="12">
                  <el-form-item
                    size="mini"
                    label="Дата изготовления"
                    prop="date_create"
                  >
                    <el-input
                      @input="
                        FORM_PLATES().date_create = FORM_PLATES().date_create.toUpperCase()
                      "
                      v-model="FORM_PLATES().date_create"
                      placeholder="дд.мм.гггг"
                      v-mask="'##.##.####'"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item
                    size="mini"
                    label="Дата выдачи"
                    prop="date_issuing"
                  >
                    <el-input
                      v-model="FORM_PLATES().date_issuing"
                      placeholder="дд.мм.гггг"
                      v-mask="'##.##.####'"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item
              size="mini"
              label="Документ идентифицирующий ТС"
              prop="typeDocumentTransportId"
            >
              <el-select
                style="width: 100%"
                v-model="FORM_PLATES().typeDocumentTransportId"
                placeholder="Выберите тип документа"
                @change="docTranId"
              >
                <el-option
                  v-for="item in this.DOCUMENT_TYPES_LIST()"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-show="FORM_PLATES().typeDocumentTransportId"
              size="mini"
              label="temp"
            >
              <span slot="label"> Серия номер {{ docTrancTitle }} </span>
              <el-row>
                <el-col :span="12">
                  <el-form-item size="mini" prop="document">
                    <el-input
                      :placeholder="
                        FORM_PLATES().typeDocumentTransportId
                          ? DOCUMENT_TYPES_LIST()[
                              FORM_PLATES().typeDocumentTransportId - 1
                            ].placeholder
                          : 'Выберите тип знака'
                      "
                      v-mask="serialDocMask"
                      @input="
                        FORM_PLATES().document = FORM_PLATES().document.toUpperCase()
                      "
                      v-model="FORM_PLATES().document"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item
                    size="mini"
                    prop="date_issuing_transport_document"
                  >
                    <el-input
                      v-model="FORM_PLATES().date_issuing_transport_document"
                      placeholder="дд.мм.гггг"
                      v-mask="'##.##.####'"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item size="mini" label="Кем выдан" prop="issued_doc">
              <el-input
                @input="
                  FORM_PLATES().issued_doc = FORM_PLATES().issued_doc.toUpperCase()
                "
                placeholder="Кем выдан"
                v-mask="defMask"
                v-model="FORM_PLATES().issued_doc"
              >
              </el-input>
            </el-form-item>
            <el-form-item size="mini" label="Количество пластин">
              <el-input-number
                v-model="FORM_PLATES().count_plate"
                :min="1"
                :max="2"
              ></el-input-number>
            </el-form-item>
            <el-form-item size="mini" label="Скан заявления" prop="scan">
              <el-upload
                class="upload-demo"
                ref="upload2"
                :limit="12"
                multiple
                accept=" .jpg, .jpeg, .png, .bmp, .heic"
                :file-list="FORM_PLATES().documents_statements"
                :auto-upload="false"
                :on-exceed="handleExceed"
                :on-change="beforeAvatarUpload"
                :on-remove="handleRemove"
                :on-preview="handlerPreview"
                action=""
              >
                <el-button slot="trigger" size="small" type="primary"
                  >Выберите файл</el-button
                >

                <div class="el-upload__tip" slot="tip">
                  jpg/jpeg/png/bmp/heic
                </div>
              </el-upload>
            </el-form-item>
          </div>
          <div class="info-owner size-collum">
            <span style="font-weight: bold;"> Сведения о владельце ТС</span>
            <el-form-item
              size="mini"
              label="Тип владельца ТС"
              prop="ownerTypeId"
            >
              <el-select
                style="width: 100%"
                v-model="FORM_PLATES().ownerTypeId"
                placeholder="Выберите тип владельца"
                @change="changeOwner"
              >
                <el-option
                  v-for="item in this.OWNER_TYPES_LIST()"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <div class="" v-if="FORM_PLATES().ownerTypeId == 1">
              <el-form-item
                size="mini"
                label="Тип документа удостоверяющего личность"
                prop="typeDocumentOwnerId"
              >
                <el-select
                  style="width: 100%"
                  v-model="FORM_PLATES().typeDocumentOwnerId"
                  placeholder="Выберите тип документа"
                  @change="typeDocChange"
                >
                  <el-option
                    v-for="item in this.OWNER_TYPES_DOC_LIST()"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                size="mini"
                prop="docOther"
                label="Тип прочих документов"
                v-if="FORM_PLATES().typeDocumentOwnerId == 2"
              >
                <el-input
                  @input="
                    FORM_PLATES().individual_document_other_type = FORM_PLATES().individual_document_other_type.toUpperCase()
                  "
                  placeholder="Введите тип прочих документов"
                  v-model="FORM_PLATES().individual_document_other_type"
                >
                </el-input>
              </el-form-item>
              <el-form-item size="mini">
                <el-row>
                  <el-col :span="12">
                    <el-form-item
                      size="mini"
                      label="Серия, номер документа "
                      prop="serialNumberDoc"
                    >
                      <el-input
                        v-mask="ownerSerMask"
                        :disabled="FORM_PLATES().typeDocumentOwnerId == null"
                        :placeholder="
                          FORM_PLATES().typeDocumentOwnerId == 1
                            ? '0000 000000'
                            : 'Серия, номер'
                        "
                        v-model="
                          FORM_PLATES().individual_document_serial_number
                        "
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Дата выдачи" prop="docDate">
                      <el-input
                        :disabled="FORM_PLATES().typeDocumentOwnerId == null"
                        v-model="FORM_PLATES().individual_document_date_issuing"
                        placeholder="дд.мм.гггг"
                        size="mini"
                        v-mask="'##.##.####'"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item
                size="mini"
                label="Кем выдан"
                prop="individual_document_issued_doc"
              >
                <el-input
                  @input="
                    FORM_PLATES().individual_document_issued_doc = FORM_PLATES().individual_document_issued_doc.toUpperCase()
                  "
                  v-mask="defMask"
                  placeholder="Кем выдан"
                  v-model="FORM_PLATES().individual_document_issued_doc"
                >
                </el-input>
              </el-form-item>
              <el-form-item size="mini" label="Фамилия" prop="surname">
                <el-input
                  @input="
                    FORM_PLATES().individual_document_surname = FORM_PLATES().individual_document_surname.toUpperCase()
                  "
                  v-mask="defMask"
                  placeholder="Введите фамилию"
                  v-model="FORM_PLATES().individual_document_surname"
                >
                </el-input>
              </el-form-item>
              <el-form-item size="mini" label="Имя" prop="name">
                <el-input
                  placeholder="Введите имя"
                  v-mask="defMask"
                  @input="
                    FORM_PLATES().individual_document_name = FORM_PLATES().individual_document_name.toUpperCase()
                  "
                  v-model="FORM_PLATES().individual_document_name"
                >
                </el-input>
              </el-form-item>
              <el-form-item size="mini" label="Отчество" prop="patronymic">
                <el-input
                  @input="
                    FORM_PLATES().individual_document_patronymic = FORM_PLATES().individual_document_patronymic.toUpperCase()
                  "
                  placeholder="Введите отчество"
                  v-mask="defMask"
                  v-model="FORM_PLATES().individual_document_patronymic"
                >
                </el-input>
              </el-form-item>
            </div>
            <div class="" v-if="FORM_PLATES().ownerTypeId == 2">
              <el-form-item
                size="mini"
                label="Наименование юридического лица"
                prop="entityName"
              >
                <el-input
                  placeholder="Название"
                  v-mask="defMask"
                  @input="
                    FORM_PLATES().entity_document_name = FORM_PLATES().entity_document_name.toUpperCase()
                  "
                  v-model="FORM_PLATES().entity_document_name"
                >
                </el-input>
              </el-form-item>
              <el-form-item size="mini" label="ИНН" prop="entityInn">
                <el-input
                  placeholder="ИНН"
                  v-mask="'############'"
                  v-model="FORM_PLATES().entity_document_inn"
                >
                </el-input>
              </el-form-item>
              <el-form-item size="mini" label="ОГРН" prop="entityOrgn">
                <el-input
                  placeholder="ОГРН"
                  v-mask="'#############'"
                  v-model="FORM_PLATES().entity_document_orgn"
                >
                </el-input>
              </el-form-item>
            </div>
            <el-form-item size="mini" label="Скан документа ТС" prop="scan2">
              <el-upload
                class="upload-demo"
                ref="upload3"
                :limit="12"
                multiple
                accept=" .jpg, .jpeg, .png, .bmp, .heic"
                :file-list="FORM_PLATES().documents_statement_tcs"
                :auto-upload="false"
                :on-exceed="handleExceed"
                :on-change="beforeAvatarUpload"
                :on-remove="handleRemove"
                :on-preview="handlerPreview"
                action=""
              >
                <el-button slot="trigger" size="small" type="primary"
                  >Выберите файл</el-button
                >

                <div class="el-upload__tip" slot="tip">
                  jpg/jpeg/png/bmp/heic
                </div>
              </el-upload>
            </el-form-item>
          </div>
          <div class="info-owner-member size-collum">
            <span style="font-weight: bold;">
              Сведения о представителя владельца ТС</span
            >
            <el-form-item
              size="mini"
              label="Тип представителя ТС"
              prop="representative_type_owner_id"
            >
              <el-select
                style="width: 100%"
                clearable
                @clear="clearOwner"
                v-model="FORM_PLATES().representative_type_owner_id"
                placeholder="Выберите тип представителя"
                @change="changeOwnerRep"
              >
                <el-option
                  v-for="item in this.OWNER_TYPES_LIST()"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <div
              class=""
              v-if="FORM_PLATES().representative_type_owner_id == 1"
            >
              <el-form-item
                size="mini"
                label="Тип документа удостоверяющего личность"
                v-if="FORM_PLATES().representative_type_owner_id == 1"
                prop="representative_document_type_ownerId"
              >
                <el-select
                  style="width: 100%"
                  v-model="FORM_PLATES().representative_document_type_ownerId"
                  placeholder="Выберите тип документа"
                  @change="typeDocChange2"
                  v-if="FORM_PLATES().representative_type_owner_id == 1"
                >
                  <el-option
                    v-for="item in this.OWNER_TYPES_DOC_LIST()"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                size="mini"
                label="Тип прочих документов"
                prop="representative_individual_document_other_type"
                v-if="FORM_PLATES().representative_document_type_ownerId == 2"
              >
                <el-input
                  @input="
                    FORM_PLATES().representative_individual_document_other_type = FORM_PLATES().representative_individual_document_other_type.toUpperCase()
                  "
                  placeholder="Введите тип прочих документов"
                  v-model="
                    FORM_PLATES().representative_individual_document_other_type
                  "
                >
                </el-input>
              </el-form-item>

              <el-form-item size="mini">
                <el-row>
                  <el-col :span="12">
                    <el-form-item
                      size="mini"
                      label="Серия, номер документа"
                      prop="representative_individual_document_serial_number"
                    >
                      <el-input
                        :placeholder="
                          FORM_PLATES().representative_document_type_ownerId ==
                          1
                            ? '0000 000000'
                            : 'Серия, номер'
                        "
                        v-model="
                          FORM_PLATES()
                            .representative_individual_document_serial_number
                        "
                        v-mask="ownerSerMask2"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      label="Дата выдачи"
                      prop="representative_individual_document_date_issuing"
                    >
                      <el-input
                        v-model="
                          FORM_PLATES()
                            .representative_individual_document_date_issuing
                        "
                        placeholder="дд.мм.гггг"
                        size="mini"
                        v-mask="'##.##.####'"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item
                size="mini"
                label="Кем выдан"
                prop="representative_document_issued_doc"
              >
                <el-input
                  @input="
                    FORM_PLATES().representative_document_issued_doc = FORM_PLATES().representative_document_issued_doc.toUpperCase()
                  "
                  placeholder="Кем выдан"
                  v-mask="defMask"
                  v-model="FORM_PLATES().representative_document_issued_doc"
                >
                </el-input>
              </el-form-item>
              <el-form-item
                size="mini"
                label="Фамилия"
                prop="representative_individual_document_surname"
              >
                <el-input
                  @input="
                    FORM_PLATES().representative_individual_document_surname = FORM_PLATES().representative_individual_document_surname.toUpperCase()
                  "
                  placeholder="Введите фамилия"
                  v-mask="defMask"
                  v-model="
                    FORM_PLATES().representative_individual_document_surname
                  "
                >
                </el-input>
              </el-form-item>
              <el-form-item
                size="mini"
                label="Имя"
                prop="representative_individual_document_name"
              >
                <el-input
                  v-mask="defMask"
                  @input="
                    FORM_PLATES().representative_individual_document_name = FORM_PLATES().representative_individual_document_name.toUpperCase()
                  "
                  placeholder="Введите имя"
                  v-model="
                    FORM_PLATES().representative_individual_document_name
                  "
                >
                </el-input>
              </el-form-item>
              <el-form-item
                size="mini"
                label="Отчество"
                prop="representative_individual_document_patronymic"
              >
                <el-input
                  @input="
                    FORM_PLATES().representative_individual_document_patronymic = FORM_PLATES().representative_individual_document_patronymic.toUpperCase()
                  "
                  v-mask="defMask"
                  placeholder="Введите отчество"
                  v-model="
                    FORM_PLATES().representative_individual_document_patronymic
                  "
                >
                </el-input>
              </el-form-item>
            </div>
            <div
              class=""
              v-if="FORM_PLATES().representative_type_owner_id == 2"
            >
              <el-form-item
                size="mini"
                label="Наименование юридического лица"
                prop="representative_entity_document_name"
              >
                <el-input
                  placeholder="Введите данные"
                  v-mask="defMask"
                  @input="
                    FORM_PLATES().representative_entity_document_name = FORM_PLATES().representative_entity_document_name.toUpperCase()
                  "
                  v-model="FORM_PLATES().representative_entity_document_name"
                >
                </el-input>
              </el-form-item>
              <el-form-item
                size="mini"
                label="ИНН"
                prop="representative_entity_document_inn"
              >
                <el-input
                  placeholder="ИНН"
                  v-mask="'############'"
                  v-model="FORM_PLATES().representative_entity_document_inn"
                >
                </el-input>
              </el-form-item>
              <el-form-item
                size="mini"
                label="ОГРН"
                prop="representative_entity_document_orgn"
              >
                <el-input
                  placeholder="ОГРН"
                  v-mask="'#############'"
                  v-model="FORM_PLATES().representative_entity_document_orgn"
                >
                </el-input>
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>
    </div>

    <el-dialog
      :modalAppendToBody="false"
      title="Описание ошибки"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
      width="600px"
    >
      <RegistryPlateForm
        v-on:nextPlate="nextPlate"
        v-on:closeModal="dialogUpd"
        :plate-id="plateId"
      />
    </el-dialog>
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgList"
      :initial-index="indexImg"
    />
    <el-image-viewer
      v-if="showViewer2"
      :on-close="closeViewer2"
      :url-list="arrImg"
      :initial-index="0"
    />
    <div class="buttonflex">
      <el-button
        size="mini"
        icon="el-icon-arrow-left"
        type="info"
        @click="
          $router.push({ name: 'Company', params: { id: $route.params.id } })
        "
      >
        К знакам
      </el-button>
      <span
        v-if="
          STATUS_PLATE().length > 2 &&
            Object.keys(this.FORM_PLATES()).length > 0
        "
        >Статус: {{ STATUS_PLATE()[FORM_PLATES().statusProductId - 1].title }}
      </span>
      <span slot="footer" class="footer-dialog">
        <div v-if="newPlate" style="display: contents">
          <el-button type="danger" @click="closeModal">
            Закрыть
          </el-button>
          <el-button
            type="success"
            @click="printDoc"
            icon="el-icon-printer"
            v-if="INFO_COMPANY().print_statement"
          >
            Печать заявления
          </el-button>
          <el-button @click="resetForm('plateForm')">Очистить</el-button>
          <el-button type="success" @click="postPlate">
            Создать
          </el-button>
        </div>

        <div v-else style="display: contents">
          <el-popover
            style="    margin-right: 10px;"
            v-if="
              Array.isArray(FORM_PLATES().info_edit_messages) &&
                this.FORM_PLATES().info_edit_messages.length
            "
            placement="left-start"
            title="Ошибка"
            width="400"
            size="mini"
            trigger="hover"
            :content="FORM_PLATES().info_edit_messages[0].message"
          >
            <el-button size="mini" slot="reference" type="warning"
              >Описание ошибки</el-button
            >
          </el-popover>
          <el-button
            type="primary"
            size="mini"
            @click="printDoc"
            v-if="INFO_COMPANY().print_statement"
            icon="el-icon-printer"
          >
            Печать заявления
          </el-button>
          <el-button
            type="primary"
            size="mini"
            @click="putPlate"
            :disabled="FORM_PLATES().statusProductId == 2"
          >
            Сохранить
          </el-button>
        </div>
      </span>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import RegistryPlateForm from "./RegistryPlateForm";
import ElImageViewer from "../../node_modules/element-ui/packages/image/src/image-viewer";

import Docxtemplater from "docxtemplater";
import { Message } from "element-ui";
import { saveAs } from "file-saver";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}
// eslint-disable-next-line no-unused-vars
function replaceErrors(key, value) {
  if (value instanceof Error) {
    return Object.getOwnPropertyNames(value).reduce(function(error, key) {
      error[key] = value[key];
      return error;
    }, {});
  }
  return value;
}
export default {
  components: { ElImageViewer, RegistryPlateForm },

  data() {
    let checkPlate = (rule, value, cb) => {
      if (!value) {
        return cb(new Error("Введите номер"));
      } else {
        let re = new RegExp(
          this.PLATE_TYPES_LIST()[this.FORM_PLATES().plateTypeId - 1].regexp
        );
        if (re.test(value)) {
          cb();
        } else cb(new Error("Неправильный формат ввода"));
      }
    };
    let checkSerialNumber = (rule, value, cb) => {
      if (!value) {
        cb();
      } else {
        let re = new RegExp("[А-ЯA-Z0-9]+?");
        if (re.test(value)) {
          cb();
        } else cb(new Error("Неправильный формат ввода"));
      }
    };
    let checkPtc = (rule, value, cb) => {
      if (!value) {
        return cb(new Error("Выберите тип документа"));
      } else {
        let re = new RegExp(
          this.DOCUMENT_TYPES_LIST()[
            this.FORM_PLATES().typeDocumentTransportId - 1
          ].regexp
        );
        if (re.test(value)) {
          cb();
        } else cb(new Error("Неправильный формат ввода"));
      }
    };
    let checkDoc1 = (rule, value, cb) => {
      if (this.$refs.upload2.uploadFiles.length > 0) {
        for (let i = 0; i < this.$refs.upload2.uploadFiles.length; i++) {
          if (this.$refs.upload2.uploadFiles[i].status != "success") {
            if (this.$refs.upload2.uploadFiles[i].size > 1048576 * 5) {
              return cb(new Error("Файл больше 1 МБ."));
            }
          }
        }
      }
      return cb();
    };
    let checkDocumentOwner = (rule, value, cb) => {
      if (this.FORM_PLATES().ownerTypeId == 2) {
        return cb();
      }
      if (this.FORM_PLATES().ownerTypeId == 1 && value) {
        return cb();
      } else {
        return cb(new Error("Выберите тип владельца"));
      }
    };
    let checkDocOther = (rule, value, cb) => {
      if (
        this.FORM_PLATES().ownerTypeId == 2 ||
        this.FORM_PLATES().typeDocumentOwnerId == 1
      ) {
        return cb();
      }

      if (
        this.FORM_PLATES().typeDocumentOwnerId == 2 &&
        this.FORM_PLATES().individual_document_other_type
      ) {
        return cb();
      } else {
        return cb(new Error("Проверьте данные"));
      }
    };
    let checkSerialNumberDoc = (rule, value, cb) => {
      if (this.FORM_PLATES().ownerTypeId == 2) {
        return cb();
      }
      if (
        this.FORM_PLATES().individual_document_serial_number &&
        this.FORM_PLATES().typeDocumentOwnerId == 2
      ) {
        return cb();
      }
      const re = /[0-9]{4}\s[0-9]{6}\s*/;
      if (
        this.FORM_PLATES().individual_document_serial_number &&
        re.test(this.FORM_PLATES().individual_document_serial_number)
      ) {
        return cb();
      } else {
        return cb(new Error("Проверьте данные"));
      }
    };
    let checkdocDate = (rule, value, cb) => {
      if (this.FORM_PLATES().ownerTypeId == 2) {
        return cb();
      }
      if (this.FORM_PLATES().individual_document_date_issuing) {
        return cb();
      } else {
        return cb(new Error("Выберите дату"));
      }
    };
    let checkSurname = (rule, value, cb) => {
      if (this.FORM_PLATES().ownerTypeId == 2) {
        return cb();
      }
      if (this.FORM_PLATES().individual_document_surname) {
        return cb();
      } else {
        return cb(new Error("Проверьте данные"));
      }
    };
    let checkName = (rule, value, cb) => {
      if (this.FORM_PLATES().ownerTypeId == 2) {
        return cb();
      }
      if (this.FORM_PLATES().individual_document_name) {
        return cb();
      } else {
        return cb(new Error("Проверьте данные"));
      }
    };
    let checkPatronymic = (rule, value, cb) => {
      if (this.FORM_PLATES().ownerTypeId == 2) {
        return cb();
      }
      if (this.FORM_PLATES().individual_document_patronymic) {
        return cb();
      } else {
        return cb(new Error("Проверьте данные"));
      }
    };
    let checkDoc2 = (rule, value, cb) => {
      if (this.$refs.upload3.uploadFiles.length > 0) {
        for (let i = 0; i < this.$refs.upload3.uploadFiles.length; i++) {
          if (this.$refs.upload3.uploadFiles[i].status != "success") {
            if (this.$refs.upload3.uploadFiles[i].size > 1048576 * 5) {
              return cb(new Error("Файл больше 1 МБ."));
            }
          }
        }
      }
      return cb();
    };
    let checkNameComp = (rule, value, cb) => {
      if (this.FORM_PLATES().ownerTypeId == 1) {
        return cb();
      }
      if (this.FORM_PLATES().entity_document_name) {
        return cb();
      } else {
        return cb(new Error("Проверьте данные"));
      }
    };
    let checkInn = (rule, value, cb) => {
      if (this.FORM_PLATES().ownerTypeId == 1) {
        return cb();
      }
      let re = /[0-9]+/;
      if (
        this.FORM_PLATES().entity_document_inn &&
        re.test(this.FORM_PLATES().entity_document_inn)
      ) {
        return cb();
      } else {
        return cb(new Error("Проверьте данные"));
      }
    };
    let checkOrgn = (rule, value, cb) => {
      if (this.FORM_PLATES().ownerTypeId == 1) {
        return cb();
      }
      let re = /[0-9]+/;
      if (
        this.FORM_PLATES().entity_document_orgn &&
        re.test(this.FORM_PLATES().entity_document_orgn)
      ) {
        return cb();
      } else {
        return cb(new Error("Проверьте данные"));
      }
    };
    let checkTypeOwner = (rule, value, cb) => {
      if (
        rule.required &&
        this.FORM_PLATES().representative_type_owner_id == 2
      ) {
        return cb(new Error("Представитель компании должен быть физ. лицо."));
      } else return cb();
    };
    return {
      serviceNameMask: "DDDDDDDDDDDDDDDDDDD",

      serialNumMask: "DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD",

      serialDocMask: "DD DD ######",

      ownerSerMask: "#### ######",

      ownerSerMask2: "#### ######",
      defMask:
        "DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD",

      indexImg: 0,
      nameRoute: null,
      statusAr: null,
      docTrancTitle: "",
      showViewer: false,
      fileList: [],
      fileList2: null,
      dialogFormVisible: false,
      plateId: null,
      imgList: [],
      showViewer2: false,
      newPlate: false,
      rules: {
        plateTypeId: [
          { required: true, message: "Выберите документ", trigger: "blur" }
        ],
        representative_type_owner_id: [
          { validator: checkTypeOwner, trigger: "blur", required: false }
        ],
        license_plate: [{ validator: checkPlate, trigger: "blur" }],
        serial_number: [
          {
            validator: checkSerialNumber,
            trigger: "change",
            required: false
          }
        ],
        date_create: [
          {
            required: true,
            message: "Выберите дату",
            trigger: "blur"
          }
        ],
        date_issuing: [
          {
            required: true,
            message: "Выберите дату",
            trigger: "blur"
          }
        ],
        typeDocumentTransportId: [
          { required: true, message: "Выберите тип знака", trigger: "blur" }
        ],
        document: [{ validator: checkPtc, trigger: "blur", required: true }],
        date_issuing_transport_document: [
          {
            required: true,
            message: "Выберите дату",
            trigger: "blur"
          }
        ],
        scan: [{ validator: checkDoc1, trigger: "change", required: true }],
        ownerTypeId: [
          { required: true, message: "Выберите тип владельца", trigger: "blur" }
        ],
        typeDocumentOwnerId: [
          { required: true, validator: checkDocumentOwner, trigger: "blur" }
        ],
        docOther: [
          { required: true, validator: checkDocOther, trigger: "blur" }
        ],
        serialNumberDoc: [
          { required: true, validator: checkSerialNumberDoc, trigger: "blur" }
        ],
        docDate: [{ required: true, validator: checkdocDate, trigger: "blur" }],
        surname: [{ required: true, validator: checkSurname, trigger: "blur" }],
        name: [{ required: true, validator: checkName, trigger: "blur" }],
        patronymic: [
          { required: true, validator: checkPatronymic, trigger: "blur" }
        ],
        scan2: [{ validator: checkDoc2, trigger: "blur", required: true }],
        entityName: [
          { validator: checkNameComp, trigger: "blur", required: true }
        ],
        entityInn: [{ validator: checkInn, trigger: "blur", required: true }],
        entityOrgn: [{ validator: checkOrgn, trigger: "blur", required: true }]
      }
    };
  },
  methods: {
    ...mapActions([
      "GET_DOCUMENT_TYPES",
      "GET_OWNER_TYPES",
      "GET_PLATE_TYPES",
      "GET_OWNER_DOCUMENT_TYPES",
      "GET_PLATES",
      "SET_FORM_PLATES",
      "GET_STATUS",
      "POST_PLATES",
      "SET_FORM_PLATES",
      "PUT_PLATES",
      "GET_PLATES_ALL",
      "DEL_FILE",
      "GET_PLATES_COUNT",
      "DEL_FILE2",
      "RESET_HEAD",

      "GET_LOGS"
    ]),
    ...mapGetters([
      "OWNER_TYPES_LIST",
      "DOCUMENT_TYPES_LIST",
      "PLATE_TYPES_LIST",
      "OWNER_TYPES_DOC_LIST",
      "STATUS_PLATE",
      "PLATES",
      "FORM_PLATES",
      "INFO_COMPANY"
    ]),
    onPreview(index) {
      this.indexImg = index;
      this.showViewer = true;
    },
    dialogUpd() {
      this.dialogFormVisible = false;
    },
    //Close viewer
    closeViewer() {
      this.showViewer = false;
    },
    closeViewer2() {
      this.showViewer2 = false;
    },
    indexPlate() {
      return this.PLATES().findIndex(el => {
        if (el.id == this.$route.params.plateId) {
          return true;
        }
      });
    },
    clearOwner() {
      this.FORM_PLATES().representative_type_owner_id = null;
      this.FORM_PLATES().representative_individual_document_other_type = null;
      this.FORM_PLATES().representative_individual_document_surname = null;
      this.FORM_PLATES().representative_individual_document_name = null;
      this.FORM_PLATES().representative_individual_document_patronymic = null;
      this.FORM_PLATES().representative_individual_document_serial_number = null;
      this.FORM_PLATES().representative_individual_document_date_issuing = null;
      this.FORM_PLATES().representative_entity_document_name = null;
      this.FORM_PLATES().representative_entity_document_inn = null;
      this.FORM_PLATES().representative_entity_document_orgn = null;
      this.FORM_PLATES().representative_document_issued_doc = null;
      this.FORM_PLATES().representative_document_type_ownerId = null;
    },
    test(lol) {
      this.changePlateType(lol);
    },
    async nextPlate() {
      const index = this.indexPlate();

      if (!(Array.isArray(this.PLATES()) && this.PLATES().length)) {
        this.$router.push({
          name: "Register",
          params: { id: this.$route.params.id }
        });
      }
      if (this.PLATES().length - 1 == index && this.PLATES().length > 1) {
        this.serviceNameMask = "DDDDDDDDDDDDDDDDDDD";
        this.SET_FORM_PLATES(this.PLATES()[0]);
        this.updImageList();
        this.$router.push({
          name: this.nameRoute,
          params: { plateId: this.PLATES()[0].id }
        });
      }
      if (this.PLATES().length - 1 > index) {
        this.serviceNameMask = "DDDDDDDDDDDDDDDDDDD";
        this.SET_FORM_PLATES(this.PLATES()[index + 1]);
        this.updImageList();
        this.$router.push({
          name: this.nameRoute,
          params: { plateId: this.PLATES()[index + 1].id }
        });
      }
    },
    previousPlate() {
      const index = this.indexPlate();

      if (0 == index) {
        this.serviceNameMask = "DDDDDDDDDDDDDDDDDDD";
        this.SET_FORM_PLATES(this.PLATES()[this.PLATES().length - 1]);
        this.updImageList();
        this.$router.push({
          name: this.nameRoute,
          params: { plateId: this.PLATES()[this.PLATES().length - 1].id }
        });
        return;
      }
      if (this.PLATES().length - 1 > index) {
        this.serviceNameMask = "DDDDDDDDDDDDDDDDDDD";
        this.SET_FORM_PLATES(this.PLATES()[index - 1]);
        this.updImageList();
        this.$router.push({
          name: this.nameRoute,
          params: { plateId: this.PLATES()[index - 1].id }
        });
      }

      if (this.PLATES().length - 1 == index) {
        this.serviceNameMask = "DDDDDDDDDDDDDDDDDDD";
        this.SET_FORM_PLATES(this.PLATES()[index - 1]);
        this.updImageList();
        this.$router.push({
          name: this.nameRoute,
          params: { plateId: this.PLATES()[index - 1].id }
        });
      }
    },
    submitLol() {},

    updImageList() {
      this.FORM_PLATES().documents_statements.map(el => {
        this.imgList.push("/" + el.url);
      });
      this.FORM_PLATES().documents_statement_tcs.map(el => {
        this.imgList.push("/" + el.url);
      });
    },
    closeModal() {
      this.$emit("updDialog");
      this.$refs["plateForm"].resetFields();
      this.SET_FORM_PLATES(this.form);
    },
    docTranId(value) {
      this.docTrancTitle = this.DOCUMENT_TYPES_LIST()[
        this.FORM_PLATES().typeDocumentTransportId - 1
      ].title;
      this.serialDocMask = this.DOCUMENT_TYPES_LIST()[value - 1].mask;
      this.FORM_PLATES().document = "";
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    changeOwner(val) {
      this.FORM_PLATES().typeDocumentOwnerId = null;
      val == 1
        ? (this.rules.representative_type_owner_id[0].required = false)
        : (this.rules.representative_type_owner_id[0].required = true);
    },
    changeOwnerRep() {
      this.FORM_PLATES().representative_individual_document_other_type = null;
      this.FORM_PLATES().representative_individual_document_surname = null;
      this.FORM_PLATES().representative_individual_document_name = null;
      this.FORM_PLATES().representative_individual_document_patronymic = null;
      this.FORM_PLATES().representative_individual_document_serial_number = null;
      this.FORM_PLATES().representative_individual_document_date_issuing = null;
      this.FORM_PLATES().representative_entity_document_name = null;
      this.FORM_PLATES().representative_entity_document_inn = null;
      this.FORM_PLATES().representative_entity_document_orgn = null;
      this.FORM_PLATES().representative_document_issued_doc = null;
      this.FORM_PLATES().representative_document_type_ownerId = null;
    },
    typeDocChange() {
      this.ownerSerMask =
        this.FORM_PLATES().typeDocumentOwnerId == 1
          ? "#### ######"
          : "DDDDDDDDDDDDDDDDDDDDDDDD";
      //this.FORM_PLATES().individual_document_serial_number = "";
    },
    typeDocChange2() {
      this.ownerSerMask2 =
        this.FORM_PLATES().representative_document_type_ownerId == 1
          ? "#### ######"
          : "DDDDDDDDDDDDDDDDDDDDDDDD";
      //this.FORM_PLATES().representative_individual_document_serial_number = "";
    },
    changeRep() {
      this.FORM_PLATES().typeDocumentOwnerId = null;
    },
    changePlateType(value) {
      this.serviceNameMask = this.PLATE_TYPES_LIST()[value - 1].mask;
      //this.FORM_PLATES().license_plate = "";
    },
    putPlate() {
      this.$refs.plateForm.validate(async valid => {
        if (valid) {
          let formData = new FormData();
          for (let i = 0; i < this.$refs.upload2.uploadFiles.length; i++) {
            if (this.$refs.upload2.uploadFiles[i].status === "ready") {
              formData.append(
                `document_statement${i}`,
                this.$refs.upload2.uploadFiles[i].raw
              );
            }
          }
          for (let i = 0; i < this.$refs.upload3.uploadFiles.length; i++) {
            if (this.$refs.upload3.uploadFiles[i].status == "ready") {
              formData.append(
                `document_statement_tc${i}`,
                this.$refs.upload3.uploadFiles[i].raw
              );
            }
          }

          await formData.append("data", JSON.stringify(this.FORM_PLATES()));
          const loading = this.$loading({
            lock: true,
            text: "Загрузка",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          await this.PUT_PLATES(formData);
          loading.close();
          this.$emit("updDialog");
          this.$router.push({
            name: "Company",
            params: { id: this.$route.params.id }
          });
          setTimeout(() => {
            this.GET_PLATES_COUNT(this.$route.params.id);
          }, 2000);
          setTimeout(() => {
            this.GET_PLATES_ALL(this.$route.params.id);
            this.GET_LOGS();
          }, 2000);
        }
      });
    },
    printDoc() {
      loadFile(
        "https://gibdd.znakvlg.ru/data/exp/test2.docx",
        (error, content) => {
          if (error) {
            throw error;
          }
          let zip = new PizZip(content);
          let doc = new Docxtemplater().loadZip(zip);

          let signature = this.excelName().split(' '); 
          let signatureResult = signature[0] + ' ' + signature[1][0].toUpperCase() + '. ' + signature[2][0].toUpperCase() + '.';

          doc.setData({
            signature: signatureResult,
            FIO: this.excelName(),
            license_plate: this.FORM_PLATES().license_plate,
            count: this.FORM_PLATES().count_plate,
            doctype1:
              this.FORM_PLATES().ownerTypeId == 1
                ? this.FORM_PLATES().typeDocumentOwnerId == 1
                  ? this.OWNER_TYPES_DOC_LIST()[0].title
                  : this.FORM_PLATES().individual_document_other_type
                : "",
            doctype2:
              this.FORM_PLATES().representative_type_owner_id == 1
                ? this.FORM_PLATES().representative_document_type_ownerId == 1
                  ? this.OWNER_TYPES_DOC_LIST()[0].title
                  : this.FORM_PLATES()
                      .representative_individual_document_other_type
                : "",
            document:
              this.DOCUMENT_TYPES_LIST()[
                this.FORM_PLATES().typeDocumentTransportId - 1
              ].title +
              " " +
              this.FORM_PLATES().document,
            issued_doc: this.FORM_PLATES().issued_doc
              ? this.FORM_PLATES().issued_doc
              : "",
            date_issuing: this.FORM_PLATES().date_issuing_transport_document,
            individual_doc_name:
              this.FORM_PLATES().ownerTypeId == 1
                ? this.FORM_PLATES().individual_document_surname +
                  " " +
                  this.FORM_PLATES().individual_document_name +
                  " " +
                  this.FORM_PLATES().individual_document_patronymic
                : this.FORM_PLATES().entity_document_name,
            serial_number:
              this.FORM_PLATES().ownerTypeId == 1
                ? this.FORM_PLATES().individual_document_serial_number
                : "",
            individual_doc_issued:
              this.FORM_PLATES().ownerTypeId == 1
                ? this.FORM_PLATES().individual_document_issued_doc
                  ? this.FORM_PLATES().individual_document_issued_doc
                  : ""
                : "",
            individual_doc_date_issiung:
              this.FORM_PLATES().ownerTypeId == 1
                ? this.FORM_PLATES().individual_document_date_issuing
                : "",
            representative_doc_name:
              this.FORM_PLATES().representative_type_owner_id != null
                ? this.FORM_PLATES().representative_type_owner_id == 1
                  ? this.FORM_PLATES()
                      .representative_individual_document_surname +
                    " " +
                    this.FORM_PLATES().representative_individual_document_name +
                    " " +
                    this.FORM_PLATES()
                      .representative_individual_document_patronymic
                  : this.FORM_PLATES().representative_entity_document_name
                : "",
            serial_number2:
              this.FORM_PLATES().representative_type_owner_id == 1
                ? this.FORM_PLATES()
                    .representative_individual_document_serial_number
                : "",
            representative_doc_issued:
              this.FORM_PLATES().representative_type_owner_id == 1
                ? this.FORM_PLATES().representative_document_issued_doc
                  ? this.FORM_PLATES().representative_document_issued_doc
                  : ""
                : "",
            representative_doc_date_issiung:
              this.FORM_PLATES().representative_type_owner_id == 1
                ? this.FORM_PLATES()
                    .representative_individual_document_date_issuing
                : "",
            date: this.FORM_PLATES().date_issuing,
            person: this.INFO_COMPANY().responsible_person,
            FIO_OBR: this.obr1(),
            PAS_OBR: this.obr2(),
            PAS2_OBR: this.obr3(),
            head_company: this.INFO_COMPANY().header_doc_bool
              ? this.INFO_COMPANY().head_other
              : 'ООО "Знак К"'
          });
          try {
            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render();
          } catch (error) {
            // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).

            if (error.properties && error.properties.errors instanceof Array) {
              // errorMessages is a humanly readable message looking like this :
              // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
          }
          let out = doc.getZip().generate({
            type: "blob",
            mimeType:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          }); //Output the document using Data-URI
          saveAs(out, "Заявление.docx");
        }
      );
    },
    obr1() {
      if (this.FORM_PLATES().ownerTypeId == 1) {
        return (
          this.FORM_PLATES().individual_document_surname +
          " " +
          this.FORM_PLATES().individual_document_name +
          " " +
          this.FORM_PLATES().individual_document_patronymic
        );
      }
      if (this.FORM_PLATES().ownerTypeId == 2) {
        return (
          this.FORM_PLATES().representative_individual_document_surname +
          " " +
          this.FORM_PLATES().representative_individual_document_name +
          " " +
          this.FORM_PLATES().representative_individual_document_patronymic
        );
      }
    },
    obr2() {
      if (this.FORM_PLATES().ownerTypeId == 1) {
        return this.FORM_PLATES().individual_document_serial_number
          ? this.FORM_PLATES().individual_document_serial_number
          : "";
      }
      if (this.FORM_PLATES().ownerTypeId == 2) {
        return this.FORM_PLATES()
          .representative_individual_document_serial_number
          ? this.FORM_PLATES().representative_individual_document_serial_number
          : "";
      }
    },
    obr3() {
      if (this.FORM_PLATES().ownerTypeId == 1) {
        return this.FORM_PLATES().individual_document_date_issuing
          ? this.FORM_PLATES().individual_document_date_issuing
          : "" + " " + this.FORM_PLATES().individual_document_issued_doc
          ? this.FORM_PLATES().individual_document_issued_doc
          : "";
      }
      if (this.FORM_PLATES().ownerTypeId == 2) {
        return this.FORM_PLATES()
          .representative_individual_document_date_issuing
          ? this.FORM_PLATES().representative_individual_document_date_issuing +
              " "
          : "" + this.FORM_PLATES().representative_document_issued_doc
          ? this.FORM_PLATES().representative_document_issued_doc
          : "";
      }
    },
    handleExceed() {
      Message.warning(
        `Лимит файлов 12. Чтоб загрузить новый файл, удалите один из загруженных.`
      );
    },

    handleRemove(file) {
      if (this.newPlate) {
        for (let i = 0; i < this.$refs.upload2.uploadFiles.length; i++) {
          if (this.$refs.upload2.uploadFiles[i].uid == file.uid) {
            this.$refs.upload2.uploadFiles.splice(i, 1);
          }
        }
      } else {
        for (
          let i = 0;
          i < this.FORM_PLATES().documents_statements.length;
          i++
        ) {
          if (this.FORM_PLATES().documents_statements[i].uid == file.uid) {
            this.DEL_FILE(i);
          }
        }

        for (
          let i = 0;
          i < this.FORM_PLATES().documents_statement_tcs.length;
          i++
        ) {
          if (this.FORM_PLATES().documents_statement_tcs[i].uid == file.uid) {
            this.DEL_FILE2(i);
          }
        }
      }
    },
    beforeAvatarUpload(file) {
      const isPhoto =
        file.raw.type === "image/jpg" ||
        file.raw.type === "image/bmp" ||
        file.raw.type === "image/png" ||
        file.raw.type === "image/jpeg" ||
        file.raw.type === "image/heic";
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isPhoto) {
        Message.error("Не тот формат");
        for (let i = 0; i < this.$refs.upload2.uploadFiles.length; i++) {
          if (this.$refs.upload2.uploadFiles[i].uid == file.uid) {
            this.$refs.upload2.uploadFiles.splice(i, 1);
          }
        }
        for (let i = 0; i < this.$refs.upload3.uploadFiles.length; i++) {
          if (this.$refs.upload3.uploadFiles[i].uid == file.uid) {
            this.$refs.upload3.uploadFiles.splice(i, 1);
          }
        }
      }
      if (!isLt2M) {
        Message.error("Фотография больше 1 МБ.");
        for (let i = 0; i < this.$refs.upload2.uploadFiles.length; i++) {
          if (this.$refs.upload2.uploadFiles[i].uid == file.uid) {
            this.$refs.upload2.uploadFiles.splice(i, 1);
          }
        }
        for (let i = 0; i < this.$refs.upload3.uploadFiles.length; i++) {
          if (this.$refs.upload3.uploadFiles[i].uid == file.uid) {
            this.$refs.upload3.uploadFiles.splice(i, 1);
          }
        }
      }

      return isPhoto && isLt2M;
    },
    handlerPreview(file) {
      if (file.status == "success") {
        this.arrImg = ["/" + file.url];
        this.showViewer2 = true;
      }
    },
    excelName() {
      if (this.FORM_PLATES().representative_type_owner_id == null) {
        return this.FORM_PLATES().ownerTypeId == 1
          ? this.FORM_PLATES().individual_document_surname.trim() +
              " " +
              this.FORM_PLATES().individual_document_name.trim() +
              " " +
              this.FORM_PLATES().individual_document_patronymic.trim()
          : this.FORM_PLATES().entity_document_name.trim();
      } else {
        return this.FORM_PLATES().representative_type_owner_id == 1
          ? this.FORM_PLATES().representative_individual_document_surname.trim() +
              " " +
              this.FORM_PLATES().representative_individual_document_name.trim() +
              " " +
              this.FORM_PLATES().representative_individual_document_patronymic.trim()
          : this.FORM_PLATES().representative_entity_document_name.trim();
      }
    }
  },
  created: async function() {
    this.RESET_HEAD("Проверка знака");
    this.nameRoute = this.$route.name;
    if (this.$route.name == "AuditAgent") {
      this.statusAr = [1, 2, 3, 4];
    } else {
      this.statusAr = 2;
    }

    await this.GET_DOCUMENT_TYPES();
    await this.GET_OWNER_TYPES();
    await this.GET_PLATE_TYPES();
    await this.GET_OWNER_DOCUMENT_TYPES();
    await this.GET_STATUS();

    await this.GET_PLATES({
      id: this.$route.params.id,
      status: this.statusAr
    });
    await this.PLATES().forEach(el => {
      if (el.id == this.$route.params.plateId) {
        this.SET_FORM_PLATES(el);
      }
    });

    this.serviceNameMask = await this.PLATE_TYPES_LIST()[
      this.FORM_PLATES().plateTypeId - 1
    ].mask;

    this.updImageList();
  }
};
</script>

<style scoped>
.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  margin-top: 15px;
  margin-bottom: 15px;
}
.info-car:last-child {
  margin-top: 10px;
}
.info-owner {
  margin-top: 25px;
}
.info-owner-member {
  margin-top: 25px;
}
.size-collum {
  min-width: 30%;
}
.doc-img {
  width: 60%;
  height: 700px;
  overflow-x: scroll;
  position: sticky;
  top: 10px;
  margin-right: 20px;
}
.el-form-item {
  margin-bottom: 0px;
}
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 0px;
}
.dialog-footer {
  margin-top: 10px;
}
.arrow-left {
  font-size: 75px;
  position: absolute;
  left: -100px;
  top: 40%;
  transition: 0.3s;
}
.arrow-right {
  font-size: 75px;
  position: absolute;
  right: -100px;
  top: 40%;
  transition: 0.3s;
}
.black {
  color: black;
}
.buttonflex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
<style></style>
