<template>
  <div class="container ">
    <div class="table-h">
      <el-table
        ref="table"
        v-if="Array.isArray(regData) && regData.length"
        :data="regData"
        stripe
        :row-class-name="tableRowClassName"
        size="mini"
        @sort-change="changeTableSort"
        @cell-click="highlightRow"
        @cell-dblclick="openFormDb"
        height="100%"
        highlight-current-row
        style="width: 100% "
      >
        <el-table-column type="index" label="№" width="40"> </el-table-column>
        <el-table-column width="140">
          <template slot-scope="scope">
            <div class="">
              <el-button
                size="mini"
                type="warning"
                icon="el-icon-s-claim"
                @click.stop="openForm(scope.row.id)"
              >
                {{ setAction(scope.row.delivered) }}
              </el-button>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="Статус"
          :filters="filterStatus"
          prop="status"
          :filter-method="filterHandler"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.addAdmin"
              style="color: #E6A23C; font-size: 8px;"
            >
              Добавлено Админом
            </span>
            <span v-else style="color: #409EFF; font-size: 8px;">
              {{ setStatus(scope.row.delivered) }}
            </span>
            <br />
            <span>{{ scope.row.company.title }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Дата"
          :filters="filterDate"
          :filter-method="filterHandler"
          prop="date"
          width="120"
          :sortable="'custom'"
        >
          <template slot-scope="scope">
            {{ scope.row.date }}
          </template>
        </el-table-column>
        <el-table-column prop="list_requested_materials" width="50">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[0].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            {{ scope.row.list_requested_materials[0].count }}
          </template>
        </el-table-column>
        <el-table-column prop="list_requested_materials" width="50">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[1].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            {{ scope.row.list_requested_materials[1].count }}
          </template>
        </el-table-column>
        <el-table-column prop="list_requested_materials" width="50px">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[2].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            {{ scope.row.list_requested_materials[2].count }}
          </template>
        </el-table-column>
        <el-table-column prop="list_requested_materials" width="55px">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[3].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            {{ scope.row.list_requested_materials[3].count }}
          </template>
        </el-table-column>
        <el-table-column prop="list_requested_materials" width="60px">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[4].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            {{ scope.row.list_requested_materials[4].count }}
          </template>
        </el-table-column>
        <el-table-column prop="list_requested_materials" width="65px">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[5].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            {{ scope.row.list_requested_materials[5].count }}
          </template>
        </el-table-column>

          <el-table-column width="70px" >
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[11].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            {{ scope.row.list_requested_materials.length == 13 ? scope.row.list_requested_materials[10].count : 0 }}
          </template>
        </el-table-column>
                <el-table-column width="75px">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[12].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            {{ scope.row.list_requested_materials.length == 13 ? scope.row.list_requested_materials[11].count : 0 }}
          </template>
        </el-table-column>

        <el-table-column width="50px">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[6].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            {{ scope.row.list_requested_materials[6].count }}
          </template>
        </el-table-column>
        <el-table-column prop="list_requested_materials" width="50px">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[8].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            {{ scope.row.list_requested_materials[7].count }}
          </template>
        </el-table-column>
        <el-table-column prop="list_requested_materials" width="50px">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[9].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            {{ scope.row.list_requested_materials[8].count }}
          </template>
        </el-table-column>
        <el-table-column prop="list_requested_materials" width="50px">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[10].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            {{ scope.row.list_requested_materials[9].count }}
          </template>
        </el-table-column>

        <el-table-column
          prop="list_requested_materials"
          label="Пленка"
          width="60px"
        >
          <template slot-scope="scope">
            {{ scope.row.list_requested_materials.length == 13 ? scope.row.list_requested_materials[12].count :  scope.row.list_requested_materials[10].count}}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :modalAppendToBody="false"
      title="Информация"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
      width="800px"
    >
      <FormReguests v-on:closeModal="close" />
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FormReguests from "./MaterialRequestsForm";
import Moment from "moment";
export default {
  components: {
    FormReguests
  },
  props: ["regData"],
  data() {
    return {
      timeoutId: null,
      currentRow: null,
      dialogFormVisible: false,
      filterStatus: [
        {
          text: "Обработанно",
          value: 1
        },
        {
          text: "Не обработанно",
          value: 2
        },
        {
          text: "Добавлено Админом",
          value: 3
        },
        {
          text: "Добавлено на склад",
          value: 4
        }
      ],
      filterDate: []
    };
  },

  methods: {
    ...mapActions([
      "GET_PLATE_TYPES",
      "GET_REGISTRY",
      "GET_COMPANY_NAMES",
      "SET_FORM_REGISTRY",
      "SORT_REGISTRY",
      "GET_REGISTRY_ALL",
      "SET_SCROLL",
      "RESET_SCROLL",
      "SET_ROWINDEX",
      "SET_PAGENUMBER"
    ]),
    ...mapGetters([
      "PLATE_TYPES_LIST",
      "REGISTRY",
      "COMPANY_NAMES",
      "REGISTRY_FORM",
      "ROWINDEX",
      "SCROLLTOP"
    ]),
    highlightRow(row) {
      if (!this.timeoutId) {
        this.timeoutId = setTimeout(() => {
          if (this.currentRow == row) {
            this.currentRow = null;
            this.RESET_SCROLL();

            return this.setCurrent();
          }
          this.setCurrent(row);
          this.currentRow = row;
          this.SET_SCROLL({
            name: this.$route.name,
            top: this.$refs["table"].bodyWrapper.scrollTop
          });
          this.SET_ROWINDEX(row.id);
          this.timeoutId = null;
        }, 500);
      }
    },
    setStatus(id) {
      if (id == 0) return "Не обработано";
      if (id == 1) return "Добавлено на склад";
      if (id == 2) return "Обработано";
    },
    setAction(id) {
      if (id == 0) return "Обработать";
      if (id == 1) return "Посмотреть";
      if (id == 2) return "Дообработать";
    },
    setCurrent(row) {
      this.$refs.table.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    close() {
      this.dialogFormVisible = false;
      setTimeout(() => {
        this.GET_REGISTRY_ALL("");
      }, 2000);
    },

    openForm(id) {
      this.regData.forEach(el => {
        if (id == el.id) {
          this.SET_FORM_REGISTRY(el);
        }
      });

      this.dialogFormVisible = true;
    },
    openFormDb(row) {
      clearTimeout(this.timeoutId);
      this.regData.forEach(el => {
        if (row.id == el.id) {
          this.SET_FORM_REGISTRY(el);
        }
      });

      this.dialogFormVisible = true;
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      if (property == "status") {
        if (value == 3 && row.addAdmin == true) {
          return true;
        }
        if (value == 1 && row.delivered == true && row.addAdmin == false) {
          return true;
        }
        if (value == 2 && row.delivered == false && row.addAdmin == false) {
          return true;
        }
        return false;
      }
      if (property == "date") {
        const start = Moment(value).format("YYYYMMDD");
        const end = Moment(value)
          .endOf("month")
          .format("YYYYMMDD");

        return (
          start <= Moment(row.date, "DD.MM.YYYY").format("YYYYMMDD") &&
          end >= Moment(row.date, "DD.MM.YYYY").format("YYYYMMDD")
        );
      }
    },
    dateArray() {
      Moment.locale("ru");

      for (let i = 0; i < 6; i++) {
        this.filterDate.push({
          text: Moment()
            .startOf("month")
            .subtract(i, "months")
            .format("MMMM YYYY"),
          value: Moment()
            .startOf("month")
            .subtract(i, "months")
            .format("YYYYMMDD")
        });
      }
    },
    changeTableSort(column) {
      this.SORT_REGISTRY({
        fieldName: column.prop,
        sortingType: column.order
      });
    },
    tableRowClassName({ row }) {
      if (row.delivered == 0) {
        return "warning-row";
      }
      if (row.delivered == 2) {
        return "pereproverit-row";
      }
      return "";
    }
  },
  created: function() {
    this.dateArray();
  },
  updated: function() {
    if (this.$refs["table"] && this.SCROLLTOP()(this.$route.name) > 0) {
      this.$refs["table"].bodyWrapper.scrollTop = this.SCROLLTOP()(
        this.$route.name
      );
      const id = this.ROWINDEX();
      this.currentRow = this.$refs.table.data.find(el => {
        if (el.id === id) {
          return true;
        }
        return false;
      });
      this.$refs.table.setCurrentRow(this.currentRow);
    }
  }
};
</script>

<style scoped>
.table-h {
  height: calc(100vh - 195px);
}
</style>
