<template>
  <div class="container ">
    <div class="head-table">
      <TabsMenu :is-admin="true" />
      <el-select
        v-model="companyId"
        filterable
        placeholder="Выберите предприятие"
        size="mini"
        @change="changeCompany"
      >
        <el-option
          v-for="item in COMPANY_NAMES()"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="table-company">
      <el-table
        :data="otchets"
        stripe
        v-if="Array.isArray(otchets) && otchets.length"
      >
        <el-table-column
          property="company.title"
          label="Компания"
        >
        </el-table-column>
        <el-table-column property="month_report" label="Дата"> </el-table-column>
        <el-table-column property="num_act" label="Номер акта"></el-table-column>
        <el-table-column label="" width="250">
          <template slot-scope="scope">
          <el-button
                type="text"
                @click.stop="download(scope.$index)"
              >
                <i class="el-icon-download bth-style"> </i>
              </el-button>
          </template>

        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
import TabsMenu from "./TabsMenu.vue";
import Moment from "moment";
import saveAs from "file-saver";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    TabsMenu,
  },
  data() {
    return {
      companyId: null,
      loading: false,
      otchets: null,
    };
  },
  methods: {
    ...mapActions(["RESET_HEAD", "SET_SCROLL", "GET_COMPANY_NAMES"]),
    ...mapGetters(["INDEX", "SCROLLTOP", "COMPANY_NAMES"]),
    download(index) {

            const data = {
        month: Moment(this.otchets[index].month_report, "MMMM YYYY").format(
          "DD.MM.YYYY"
        ),
        contract_date: this.otchets[index].contract_date,
        num_act: this.otchets[index].num_act,
        plates_price: JSON.parse(this.otchets[index].plates_price),
        save: false,
        companyIdAdmin: this.otchets[index].companyId
      };
            const loading = this.$loading({
        lock: true,
        text: "Загрузка",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$http
        .post("/api/plate/report-doc", data, {
          responseType: "blob"
        })
        .then(res => {
          saveAs(
            res.data,
            `Отчет за ${Moment(data.month, "DD.MM.YYYY")
              .format("MMMM")
              .toUpperCase()} ${this.otchets[index].company.title}.xlsx`
          );
        });
      loading.close();
    },
    changeCompany(id) {
      this.$http.get("api/company/otchet?companyId="+id).then(res=>{
      this.otchets = res.data
    })
    }
  },

  created: function() {
    this.RESET_HEAD("Статистика");
    this.GET_COMPANY_NAMES()
    this.$http.get("api/company/otchet").then(res=>{
      this.otchets = res.data
    })

  }
};
</script>

<style scoped>

.head-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-company {
  height: calc(100vh - 180px);
}
</style>
