<template>
  <div class="container ">
    <div class="">
      <div class="header-bth">
        <TabsMenu :is-admin="false" :active-index2="INDEX()" />
        <el-menu :default-active="activeIndex" mode="horizontal">
          <el-menu-item index="1" @click="indexMenu = 1">
            Не обработанные
          </el-menu-item>
          <el-menu-item index="2" @click="indexMenu = 2">
            Обработанные
          </el-menu-item>
        </el-menu>

        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="formVisible = true"
          >Запрос</el-button
        >
      </div>
    </div>

    <RegistryCompanyTable
      :reg-data="REGISTRY_NEW()"
      v-show="indexMenu == `1`"
    />
    <RegistryCompanyTable :reg-data="REGISTRY()" v-show="indexMenu == `2`" />
    <el-dialog
      :modalAppendToBody="false"
      title="Запрос на материал"
      :visible.sync="formVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="lol"
      width="500px"
    >
      <RegistryCompanyForm v-on:closeModal="closeModal" :reg-id="null" />
    </el-dialog>
  </div>
</template>

<script>
import RegistryCompanyForm from "./RegistryCompanyForm";
import RegistryCompanyTable from "./RegistryCompanyTable";
import TabsMenu from "../components/TabsMenu";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    RegistryCompanyForm,
    RegistryCompanyTable,
    TabsMenu
  },
  data() {
    return {
      activeName: "first",
      formVisible: false,
      activeIndex: "1",
      indexMenu: 1
    };
  },
  methods: {
    ...mapActions([
      "GET_PLATE_TYPES",
      "GET_REGISTRY",
      "GET_REGISTRY_NEW",
      "RESET_INDEX",
      "RESET_HEAD"
    ]),
    ...mapGetters(["PLATE_TYPES_LIST", "REGISTRY", "REGISTRY_NEW", "INDEX"]),
    closeModal() {
      this.formVisible = false;
    },
    lol() {}
  },
  created: async function() {
    this.RESET_HEAD("Реестр получения пластин");
    await this.GET_PLATE_TYPES();
    await this.GET_REGISTRY(this.$route.params.id);
    await this.GET_REGISTRY_NEW(this.$route.params.id);
    if (!this.REGISTRY_NEW().length) {
      this.indexMenu = 2;
      this.activeIndex = "2";
    }
  }
};
</script>

<style scoped>
.head-table {
  justify-content: space-between;
  align-items: center;
}
.header-bth {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
