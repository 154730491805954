<template>
  <div class="">
    <el-form
      :model="form"
      label-width="auto"
      ref="form"
      label-position="left"
      autocomplete="off"
    >
      <el-form-item>
        <el-input
          type="textarea"
          placeholder="Введите текст"
          v-model="form.message"
          maxlength="255"
          show-word-limit
          :autosize="{ minRows: 5 }"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="footer-dialog">
      <el-button type="danger" @click="closeModal">
        Закрыть
      </el-button>

      <el-button type="success" @click="close">
        Создать
      </el-button>
    </span>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    plateId: Number
  },
  data() {
    return {
      form: {
        productId: null,
        message: null
      }
    };
  },
  methods: {
    ...mapActions(["AUDIT_POST"]),

    closeModal() {
      this.$emit("closeModal");
      // Modal
    },
    close() {
      this.form.productId = this.plateId;
      this.AUDIT_POST(this.form);
      this.$emit("nextPlate");
      this.$emit("closeModal");
    }
  },
  created: function() {
    this.form.companyId = this.plateId;
  }
};
</script>

<style scoped>
.footer-dialog {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-select {
  display: block;
}
</style>
