const getDefaultState = () => ({
  head: String,
  index: String,
  moder: Boolean
});
const state = getDefaultState();

const getters = {
  HEAD: state => {
    return state.head;
  },
  INDEX: state => {
    return state.index;
  },
  MODER: state => {
    return state.moder;
  }
};

const actions = {
  RESET_HEAD: async ({ commit }, head) => {
    commit("SET_HEAD", head);
  },
  RESET_INDEX: async ({ commit }, index) => {
    commit("SET_INDEX", index);
  },
  RESET_MODER: async ({ commit }, ok) => {
    commit("SET_MODER", ok);
  }
};

const mutations = {
  SET_HEAD: (state, data) => {
    state.head = data;
  },
  SET_INDEX: (state, data) => {
    state.index = data;
  },
  SET_MODER: (state, data) => {
    state.moder = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
