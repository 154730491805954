<template>
  <div class="table-h">
    <el-table
      ref="singleTable"
      v-if="Array.isArray(regData) && regData.length"
      :data="regData"
      stripe
      size="small"
      highlight-current-row
      height="100%"
      style="width: 100%;"
    >
      <el-table-column type="index" label="№" width="33"> </el-table-column>
      <el-table-column
        property="document"
        width="65"
        v-if="!regData[0].delivered"
      >
        <template slot-scope="scope">
          <div style="text-align: center;">
            <el-button size="mini" type="text" @click.stop="editReg(scope.row)">
              <i class="el-icon-s-tools"> </i>
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column property="date" label="Дата" width="80">
      </el-table-column>
      <el-table-column
        property="document"
        label="Сопроводительные документы"
        width="130"
      >
        <template slot-scope="scope">
          <a v-if="scope.row.document" download :href="'/' + scope.row.document"
            >Документ</a
          >
        </template>
      </el-table-column>
      <el-table-column property="comment_user" label="Ваш комментрий">
      </el-table-column>
      <el-table-column property="comment_admin" label="Комментарий админа">
      </el-table-column>
      <el-table-column prop="list_requested_materials" width="50">
        <template slot="header" slot-scope="">
          {{ this.PLATE_TYPES_LIST()[0].title.slice(4) }}
        </template>
        <template slot-scope="scope">
          {{ scope.row.list_requested_materials[0].count }}
        </template>
      </el-table-column>
      <el-table-column prop="list_requested_materials" width="50">
        <template slot="header" slot-scope="">
          {{ this.PLATE_TYPES_LIST()[1].title.slice(4) }}
        </template>
        <template slot-scope="scope">
          {{ scope.row.list_requested_materials[1].count }}
        </template>
      </el-table-column>
      <el-table-column prop="list_requested_materials" width="50">
        <template slot="header" slot-scope="">
          {{ this.PLATE_TYPES_LIST()[2].title.slice(4) }}
        </template>
        <template slot-scope="scope">
          {{ scope.row.list_requested_materials[2].count }}
        </template>
      </el-table-column>
      <el-table-column prop="list_requested_materials" width="56">
        <template slot="header" slot-scope="">
          {{ this.PLATE_TYPES_LIST()[3].title.slice(4) }}
        </template>
        <template slot-scope="scope">
          {{ scope.row.list_requested_materials[3].count }}
        </template>
      </el-table-column>
      <el-table-column prop="list_requested_materials" width="58">
        <template slot="header" slot-scope="">
          {{ this.PLATE_TYPES_LIST()[4].title.slice(4) }}
        </template>
        <template slot-scope="scope">
          {{ scope.row.list_requested_materials[4].count }}
        </template>
      </el-table-column>
      <el-table-column prop="list_requested_materials" width="65">
        <template slot="header" slot-scope="">
          {{ this.PLATE_TYPES_LIST()[5].title.slice(4) }}
        </template>
        <template slot-scope="scope">
          {{ scope.row.list_requested_materials[5].count }}
        </template>
      </el-table-column>
      <el-table-column prop="list_requested_materials" width="50">
        <template slot="header" slot-scope="">
          {{ this.PLATE_TYPES_LIST()[6].title.slice(4) }}
        </template>
        <template slot-scope="scope">
          {{ scope.row.list_requested_materials[6].count }}
        </template>
      </el-table-column>
      <el-table-column prop="list_requested_materials" width="50">
        <template slot="header" slot-scope="">
          {{ this.PLATE_TYPES_LIST()[8].title.slice(4) }}
        </template>
        <template slot-scope="scope">
          {{ scope.row.list_requested_materials[7].count }}
        </template>
      </el-table-column>
      <el-table-column prop="list_requested_materials" width="50">
        <template slot="header" slot-scope="">
          {{ this.PLATE_TYPES_LIST()[9].title.slice(4) }}
        </template>
        <template slot-scope="scope">
          {{ scope.row.list_requested_materials[8].count }}
        </template>
      </el-table-column>
      <el-table-column prop="list_requested_materials" width="50">
        <template slot="header" slot-scope="">
          {{ this.PLATE_TYPES_LIST()[10].title.slice(4) }}
        </template>
        <template slot-scope="scope">
          {{ scope.row.list_requested_materials[9].count }}
        </template>
      </el-table-column>

      <el-table-column label="Пленка" width="65">
        <template slot-scope="scope">
          {{ scope.row.list_requested_materials[10].count }}
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :modalAppendToBody="false"
      title="Запрос на материал"
      :visible.sync="formVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
    >
      <RegistryCompanyForm v-on:closeModal="closeModal2" :reg-id="regObj" />
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RegistryCompanyForm from "./RegistryCompanyForm";
export default {
  components: {
    RegistryCompanyForm
  },
  props: ["regData"],
  data() {
    return {
      currentRow: null,
      formVisible: false,
      regObj: null
    };
  },

  methods: {
    ...mapGetters(["PLATE_TYPES_LIST", "REGISTRY"]),
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    closeModal2() {
      this.regObj = null;

      this.formVisible = false;
    },
    editReg(id) {
      this.regObj = id;
      this.formVisible = true;
    }
  },
  created: function() {}
};
</script>

<style scoped>
.table-h {
  height: calc(100vh - 210px);
}
.bth-style {
  font-size: 15px;
  color: #409eff;
}
</style>
