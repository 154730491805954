<template>
  <div id="user">
    <HeaderBar nav-show></HeaderBar>
    <div class="container" v-if="isComputer">
      <div class="flex">
        <div class="">
          <HeadLogo />
        </div>

        <div
          class=""
          style="    display: flex;
    align-items: center;"
        >
          <div class="" style="    margin-right: 10px;">
            <el-popover
              placement="bottom-end"
              title="История действий"
              width="500"
              trigger="hover"
            >
              <el-table
                :data="LOGS()"
                stripe
                v-if="Array.isArray(LOGS()) && LOGS().length"
              >
                <el-table-column
                  width="300"
                  property="text"
                  label="Действие"
                ></el-table-column>
                <el-table-column
                  width="100"
                  property="userName"
                  label="Пользователь"
                ></el-table-column>
                <el-table-column width="100" property="date" label="Дата">
                </el-table-column>
              </el-table>
              <div class="" v-else>Историй нету</div>
              <el-button slot="reference" type="text"
                >История действий
                <i class="el-icon-s-order"></i>
              </el-button>
            </el-popover>
          </div>
          <PlateCountTable />
        </div>
      </div>
    </div>

    <div class="main"><router-view /></div>
    <el-dialog
      :modalAppendToBody="false"
      title="Оповещение"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="600px"
    >
      <News v-on:closeModalNews="dialogFormVisible = false" />
    </el-dialog>
  </div>
</template>

<script>
import HeadLogo from "../components/HeadLogo";
import PlateCountTable from "../components/PlateCountTable";
import HeaderBar from "../components/HeaderBar";

import News from "../components/News";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    HeaderBar,

    News,
    HeadLogo,
    PlateCountTable
  },
  data() {
    return {
      dialogFormVisible: false,
      activeIndex2: null,
      isComputer: true
    };
  },
  methods: {
    ...mapActions([
      "GET_NAME_USER",
      "GET_NEWS",
      "GET_COMPANY_NAME_PERSON",
      "RESET_INDEX",
      "GET_LOGS"
    ]),
    ...mapGetters(["NEWS", "INDEX", "LOGS"])
  },
  created: async function() {
    this.GET_NAME_USER();
    if (window.innerWidth < 600) {
      this.isComputer = false;
    }
    await this.GET_NEWS();
    await this.GET_LOGS();
    await this.GET_COMPANY_NAME_PERSON(this.$route.params.id);
    if (Array.isArray(this.NEWS()) && this.NEWS().length) {
      this.dialogFormVisible = true;
    }
  }
};
</script>

<style scoped>
.main {
  height: 100%;
  width: 100%;
}
.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
