<template>
  <header
    class="header"
    v-bind:class="{ 'admin-color': isAdmin, 'user-color': !isAdmin }"
  >
    <div class="container">
      <div class="header__inner">
        <div
          class=""
          style="height: 100px;
    width: 550px;
    display: flex;
    align-items: center;
    color:white;
    font-weight: 600;
    font-size: 14px;
       max-width: 33.3%;
    "
        >
          <img class="img-menu" src="../../public/gibdd.png" alt="" />
          <span v-if="isComputer">
            Реестр учета сведений об изготовленных и выданных государственных
            регистрационных знаках транспортных средств
          </span>
        </div>
        <div
          class=""
          style="color: white; font-size: 12px;
    font-weight: 600; max-width: 33.3%;
"
          v-if="
            Object.keys(this.INFO_COMPANY()).length != 0 &&
              this.USERROLE() == `Агент`
          "
        >
          <span>{{ INFO_COMPANY().head_company }}</span>
        </div>
        <template v-if="navShow">
          <nav
            style="    display: flex;
    align-items: baseline; max-width: 33.4%;"
          >
            <div
              style="color: white;     font-size: 12px;
    font-weight: bold;"
            >
              <i class="el-icon-user-solid"></i>{{ this.USERNAME() }}
            </div>

            <el-button
              class="nav__link"
              type="text"
              @click="logout"
              style="color: white;     font-size: 12px;
    font-weight: bold;"
            >
              Выйти</el-button
            >
          </nav>
        </template>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    navShow: Boolean,
    isAdmin: Boolean
  },
  name: "HeaderBar",
  data() {
    return {
      head_company: null,
      isComputer: true
    };
  },

  methods: {
    ...mapActions([
      "LOGOUT_FROM_API",
      "RESET",
      "RESET_AUTH",
      "RESET_COMPANY",
      "RESET_NEWS",
      "RESET_PLATES",
      "RESET_USER"
    ]),
    ...mapGetters(["USERNAME", "USERROLE", "INFO_COMPANY"]),

    logout() {
      this.LOGOUT_FROM_API();
      this.RESET_AUTH();
      this.RESET_COMPANY();
      this.RESET_NEWS();
      this.RESET_PLATES();
      this.RESET_USER();
      this.$router.push({ name: "Home" });
    }
  },
  created: function() {
    if (window.innerWidth < 600) {
      this.isComputer = false;
    }
  }
};
</script>

<style scoped>
/* для админа 31568D  для логина #303133*/
.header {
  background-color: #31568d;
  width: 100%;
  height: 100px;
}
.admin-color {
  background-color: #cc0000;
}
.user-color {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#2785b7),
    to(#165b84)
  );
}
.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.img-menu {
  width: 100px;
  height: 100px;
}
.nav__link {
  display: inline-block;
  vertical-align: top;
  margin: 0 10px;
  text-decoration: none;
}
</style>
