import axios from "axios";

const getDefaultState = () => ({
  logs: Array
});

const state = getDefaultState();

const getters = {
  LOGS: state => {
    return state.logs;
  }
};

const actions = {
  GET_LOGS: async ({ commit }) => {
    axios.get("/api/company/logs").then(res => {
      commit("SET_LOGS", res.data);
    });
  }
};

const mutations = {
  SET_LOGS: (state, data) => {
    state.logs = data;
  },

  resetState(state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
