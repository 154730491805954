import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import company from "./modules/company";
import users from "./modules/users";
import plate from "./modules/plates";
import registry from "./modules/registry";
import news from "./modules/news";
import head from "./modules/head";
import scroll from "./modules/scroll";
import logs from "./modules/logs";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    company,
    users,
    plate,
    registry,
    news,
    head,
    scroll,
    logs
  }
});
