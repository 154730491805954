import { render, staticRenderFns } from "./RegistryCompanyTable.vue?vue&type=template&id=24f49b16&scoped=true&"
import script from "./RegistryCompanyTable.vue?vue&type=script&lang=js&"
export * from "./RegistryCompanyTable.vue?vue&type=script&lang=js&"
import style0 from "./RegistryCompanyTable.vue?vue&type=style&index=0&id=24f49b16&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24f49b16",
  null
  
)

export default component.exports