<template>
  <div class="container" style="position: relative; margin-top: 10px;">
    <el-button
      icon="el-icon-back"
      class="arrow-left"
      circle
      v-if="this.PLATES().length > 1"
      @click="previousPlate"
    ></el-button>
    <div class="buttonflex">
      <el-button
        icon="el-icon-arrow-left"
        type="primary"
        size="mini"
        @click="
          $router.push({ name: 'Register', params: { id: $route.params.id } })
        "
      >
        К реестру
      </el-button>
      <span
        v-if="
          STATUS_PLATE().length > 2 &&
            Object.keys(this.FORM_PLATES()).length > 0
        "
        >Статус: {{ STATUS_PLATE()[FORM_PLATES().statusProductId - 1].title }}
      </span>
      <div
        class=""
        v-if="
          FORM_PLATES().statusProductId == 1 ||
            FORM_PLATES().statusProductId == 3 ||
            FORM_PLATES().statusProductId == 4
        "
      >
        <el-popover
          style="    margin-right: 10px;"
          v-if="
            Array.isArray(FORM_PLATES().info_edit_messages) &&
              this.FORM_PLATES().info_edit_messages.length
          "
          placement="left-start"
          title="Ошибка"
          width="400"
          trigger="hover"
          :content="FORM_PLATES().info_edit_messages[0].message"
        >
          <el-button slot="reference" size="mini" type="warning"
            >Описание ошибки</el-button
          >
        </el-popover>
        <el-button
          type="primary"
          size="mini"
          :disabled="blockcheck"
          v-if="FORM_PLATES().statusProductId != 2"
          @click="blockPlate"
        >
          Заблокировать
        </el-button>
        <el-button
          v-if="FORM_PLATES().statusProductId == 2"
          type="primary"
          size="mini"
          @click="unblockPlate"
        >
          Разблокировать
        </el-button>
        <el-button
          type="primary"
          size="mini"
          class="back"
          v-if="FORM_PLATES().statusProductId != 2"
          @click="auditPlate"
        >
          Отправить на редактирование
        </el-button>
      </div>
    </div>
    <el-button
      icon="el-icon-right"
      class="arrow-right"
      circle
      v-if="this.PLATES().length > 1"
      @click="nextPlate"
    ></el-button>
    <div class="flex" v-if="Object.keys(this.FORM_PLATES()).length > 0">
      <div
        class="doc-img"
        v-if="Array.isArray(this.PLATES()) && this.PLATES().length"
      >
        <div class="">
          <template v-for="(item, index) in imgList">
            <el-image
              :key="item.count + 'count' + index"
              :src="item"
              fit="scale-down"
              @click="onPreview(index)"
            >
            </el-image>
          </template>
        </div>
      </div>
      <el-image-viewer
        v-if="showViewer"
        :on-close="closeViewer"
        :url-list="imgList"
        :initial-index="indexImg"
      />
      <el-form
        :model="FORM_PLATES()"
        label-width="auto"
        label-position="top"
        style="background: #efefef; color: black"
        size="mini"
        disabled
      >
        <div class="info-car size-collum black">
          <span style="font-weight: bold;">
            Сведения о транспортном средстве</span
          >
          <el-form-item
            label="Государственный регистрационный знак"
            class="black"
          >
            <el-row>
              <el-col :span="12" class="black">
                <el-select
                  v-model="FORM_PLATES().plateTypeId"
                  class="black"
                  v-if="
                    Array.isArray(PLATE_TYPES_LIST()) &&
                      this.PLATE_TYPES_LIST().length
                  "
                >
                  <el-option
                    v-for="item in this.PLATE_TYPES_LIST()"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                    class="black"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="12">
                <el-input
                  style="color:black"
                  v-model="FORM_PLATES().license_plate"
                >
                </el-input>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="Серийный номер знака">
            <el-input
              v-model="FORM_PLATES().serial_number"
              style="color: black;"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-row>
              <el-col :span="12">
                <el-form-item label="Дата изготовления">
                  <el-date-picker
                    v-model="FORM_PLATES().date_create"
                    type="date"
                    style="color:black"
                    placeholder="Pick a Date"
                    format="dd.MM.yyyy"
                    value-format="dd.MM.yyyy"
                  >
                    >
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="Дата выдачи">
                  <el-date-picker
                    v-model="FORM_PLATES().date_issuing"
                    type="date"
                    placeholder="Pick a Date"
                    format="dd.MM.yyyy"
                    value-format="dd.MM.yyyy"
                  >
                    >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="Документ идентифицирующий ТС">
            <el-select
              style="width: 100%"
              v-model="FORM_PLATES().typeDocumentTransportId"
              v-if="
                Array.isArray(DOCUMENT_TYPES_LIST()) &&
                  this.DOCUMENT_TYPES_LIST().length
              "
            >
              <el-option
                v-for="item in this.DOCUMENT_TYPES_LIST()"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-show="FORM_PLATES().typeDocumentTransportId">
            <span slot="label" style="    color: black;">
              Серия номер {{ docTrancTitle }}
            </span>
            <el-row>
              <el-col :span="12">
                <el-form-item>
                  <el-input v-model="FORM_PLATES().document"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item>
                  <el-date-picker
                    v-model="FORM_PLATES().date_issuing_transport_document"
                    type="date"
                    placeholder="Pick a Date"
                    format="dd.MM.yyyy"
                    value-format="dd.MM.yyyy"
                  >
                    >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="Кем выдан" prop="issued_doc">
            <el-input
              placeholder="Кем выдан"
              v-model="FORM_PLATES().issued_doc"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="Количество пластин">
            <el-input-number
              v-model="FORM_PLATES().count_plate"
              :min="1"
              :max="2"
            ></el-input-number>
          </el-form-item>
        </div>
        <div class="info-owner size-collum">
          <span style="font-weight: bold;"> Сведения о владельце ТС</span>
          <el-form-item label="Тип владельца ТС">
            <el-select
              style="width: 100%"
              v-model="FORM_PLATES().ownerTypeId"
              v-if="
                Array.isArray(OWNER_TYPES_LIST()) &&
                  this.OWNER_TYPES_LIST().length
              "
            >
              <el-option
                v-for="item in this.OWNER_TYPES_LIST()"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div class="" v-if="FORM_PLATES().ownerTypeId == 1">
            <el-form-item label="Тип документа удостоверяющего личность">
              <el-select
                style="width: 100%"
                v-model="FORM_PLATES().typeDocumentOwnerId"
                v-if="
                  Array.isArray(OWNER_TYPES_DOC_LIST()) &&
                    this.OWNER_TYPES_DOC_LIST().length
                "
              >
                <el-option
                  v-for="item in this.OWNER_TYPES_DOC_LIST()"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="Тип прочих документов"
              v-if="FORM_PLATES().typeDocumentOwnerId == 2"
            >
              <el-input
                disabled
                placeholder="Please input"
                v-model="FORM_PLATES().individual_document_other_type"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="Серия, номер документа ">
                    <el-input
                      placeholder="Please input"
                      v-model="FORM_PLATES().individual_document_serial_number"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Дата выдачи">
                    <el-date-picker
                      v-model="FORM_PLATES().individual_document_date_issuing"
                      type="date"
                      format="dd.MM.yyyy"
                      value-format="dd.MM.yyyy"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item
              label="Кем выдан"
              prop="individual_document_issued_doc"
            >
              <el-input
                placeholder="Кем выдан"
                v-model="FORM_PLATES().individual_document_issued_doc"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="Фамилия">
              <el-input v-model="FORM_PLATES().individual_document_surname">
              </el-input>
            </el-form-item>
            <el-form-item label="Имя">
              <el-input v-model="FORM_PLATES().individual_document_name">
              </el-input>
            </el-form-item>
            <el-form-item label="Отчество">
              <el-input v-model="FORM_PLATES().individual_document_patronymic">
              </el-input>
            </el-form-item>
          </div>
          <div class="" v-if="FORM_PLATES().ownerTypeId == 2">
            <el-form-item label="Наименование юридического лица">
              <el-input v-model="FORM_PLATES().entity_document_name">
              </el-input>
            </el-form-item>
            <el-form-item label="ИНН">
              <el-input v-model="FORM_PLATES().entity_document_inn"> </el-input>
            </el-form-item>
            <el-form-item label="ОГРН">
              <el-input v-model="FORM_PLATES().entity_document_orgn">
              </el-input>
            </el-form-item>
          </div>
        </div>
        <div class="info-owner-member size-collum">
          <span style="font-weight: bold;">
            Сведения о представителя владельца ТС</span
          >
          <el-form-item
            label="Тип владельца ТС"
            prop="representative_type_owner_id"
          >
            <el-select
              style="width: 100%; color: black;"
              v-model="FORM_PLATES().representative_type_owner_id"
              placeholder="Выберите тип владельца"
            >
              <el-option
                v-for="item in this.OWNER_TYPES_LIST()"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div class="" v-if="FORM_PLATES().representative_type_owner_id == 1">
            <el-form-item
              class="black"
              label="Тип документа удостоверяющего личность"
              v-if="FORM_PLATES().representative_type_owner_id == 1"
              prop="representative_document_type_ownerId"
            >
              <el-select
                style="width: 100%"
                v-model="FORM_PLATES().representative_document_type_ownerId"
                placeholder="Выберите тип документа"
                v-if="FORM_PLATES().representative_type_owner_id == 1"
              >
                <el-option
                  v-for="item in this.OWNER_TYPES_DOC_LIST()"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              class="black"
              label="Тип прочих документов"
              prop="representative_individual_document_other_type"
              v-if="FORM_PLATES().representative_document_type_ownerId == 2"
            >
              <el-input
                placeholder="Введите тип прочих документов"
                v-model="
                  FORM_PLATES().representative_individual_document_other_type
                "
              >
              </el-input>
            </el-form-item>

            <el-form-item>
              <el-row>
                <el-col :span="12">
                  <el-form-item
                    label="Серия, номер документа"
                    prop="representative_individual_document_serial_number"
                  >
                    <el-input
                      :placeholder="
                        FORM_PLATES().representative_document_type_ownerId == 1
                          ? '0000 000000'
                          : ''
                      "
                      v-model="
                        FORM_PLATES()
                          .representative_individual_document_serial_number
                      "
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="Дата выдачи"
                    prop="representative_individual_document_date_issuing"
                  >
                    <el-date-picker
                      v-model="
                        FORM_PLATES()
                          .representative_individual_document_date_issuing
                      "
                      type="date"
                      placeholder="дд.мм.гг"
                      format="dd.MM.yyyy"
                      value-format="dd.MM.yyyy"
                    >
                      >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item
              label="Кем выдан"
              prop="representative_document_issued_doc"
            >
              <el-input
                placeholder="Кем выдан"
                v-model="FORM_PLATES().representative_document_issued_doc"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="Фамилия"
              prop="representative_individual_document_surname"
            >
              <el-input
                placeholder="Введите фамилия"
                v-model="
                  FORM_PLATES().representative_individual_document_surname
                "
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="Имя"
              prop="representative_individual_document_name"
            >
              <el-input
                placeholder="Введите имя"
                v-model="FORM_PLATES().representative_individual_document_name"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="Отчество"
              prop="representative_individual_document_patronymic"
            >
              <el-input
                placeholder="Введите отчество"
                v-model="
                  FORM_PLATES().representative_individual_document_patronymic
                "
              >
              </el-input>
            </el-form-item>
          </div>
          <div class="" v-if="FORM_PLATES().representative_type_owner_id == 2">
            <el-form-item
              label="Наименование юридического лица"
              prop="representative_entity_document_name"
            >
              <el-input
                placeholder="Введите данные"
                v-model="FORM_PLATES().representative_entity_document_name"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="ИНН" prop="representative_entity_document_inn">
              <el-input
                placeholder="ИНН"
                v-model="FORM_PLATES().representative_entity_document_inn"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="ОГРН"
              prop="representative_entity_document_orgn"
            >
              <el-input
                placeholder="ОГРН"
                v-model="FORM_PLATES().representative_entity_document_orgn"
              >
              </el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <!-- <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitLol">Сохранить</el-button>
    </span> -->
    <el-dialog
      :modalAppendToBody="false"
      title="Описание ошибки"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
      width="600px"
    >
      <RegistryPlateForm
        v-on:nextPlate="nextPlate"
        v-on:closeModal="dialogUpd"
        :plate-id="plateId"
      />
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import RegistryPlateForm from "./RegistryPlateForm";
import ElImageViewer from "../../node_modules/element-ui/packages/image/src/image-viewer";
export default {
  components: { ElImageViewer, RegistryPlateForm },
  data() {
    return {
      indexImg: 0,
      nameRoute: null,
      statusAr: null,
      docTrancTitle: "",
      showViewer: false,
      dialogFormVisible: false,
      plateId: null,
      imgList: [],
      blockcheck: false
    };
  },
  methods: {
    ...mapActions([
      "GET_DOCUMENT_TYPES",
      "GET_OWNER_TYPES",
      "GET_PLATE_TYPES",
      "GET_OWNER_DOCUMENT_TYPES",
      "GET_PLATES",
      "SET_FORM_PLATES",
      "GET_STATUS",
      "BLOCK_PLATES",
      "DELETE_PLATE",
      "RESET_HEAD",
      "SET_INDEX_PLATE"
    ]),
    ...mapGetters([
      "OWNER_TYPES_LIST",
      "DOCUMENT_TYPES_LIST",
      "PLATE_TYPES_LIST",
      "OWNER_TYPES_DOC_LIST",
      "STATUS_PLATE",
      "PLATES",
      "FORM_PLATES",
      "INDEX_PLATE",
      "SCROLLTOP"
    ]),
    onPreview(index) {
      this.indexImg = index;
      this.showViewer = true;
    },
    dialogUpd() {
      this.dialogFormVisible = false;
    },
    //Close viewer
    closeViewer() {
      this.showViewer = false;
    },
    indexPlate() {
      return this.PLATES().findIndex(el => {
        if (el.id == this.$route.params.plateId) {
          return true;
        }
      });
    },
    nextPlate() {
      if (!(Array.isArray(this.PLATES()) && this.PLATES().length)) {
        this.$router.push({
          name: "Register",
          params: { id: this.$route.params.id }
        });
      }
      if (this.PLATES().length - 1 == this.INDEX_PLATE()) {
        this.SET_INDEX_PLATE(0);
        this.SET_FORM_PLATES(this.PLATES()[this.INDEX_PLATE()]);
        this.updImageList();
        this.$router.push({
          name: this.nameRoute,
          params: { plateId: this.PLATES()[this.INDEX_PLATE()].id }
        });

        return;
      }
      if (
        this.PLATES().length - 1 == this.INDEX_PLATE() &&
        this.PLATES().length > 1
      ) {
        this.SET_FORM_PLATES(this.PLATES()[0]);
        this.updImageList();
        this.$router.push({
          name: this.nameRoute,
          params: { plateId: this.PLATES()[0].id }
        });

        return;
      }
      if (this.PLATES().length - 1 > this.INDEX_PLATE()) {
        this.SET_INDEX_PLATE(this.INDEX_PLATE() + 1);
        this.SET_FORM_PLATES(this.PLATES()[this.INDEX_PLATE()]);
        this.updImageList();
        this.$router.push({
          name: this.nameRoute,
          params: { plateId: this.PLATES()[this.INDEX_PLATE()].id }
        });

        return;
      }
    },
    previousPlate() {
      if (0 == this.INDEX_PLATE()) {
        this.SET_INDEX_PLATE(this.PLATES().length - 1);
        this.SET_FORM_PLATES(this.PLATES()[this.INDEX_PLATE()]);
        this.updImageList();
        this.$router.push({
          name: this.nameRoute,
          params: { plateId: this.PLATES()[this.INDEX_PLATE()].id }
        });
        return;
      }

      if (this.PLATES().length - 1 > this.INDEX_PLATE()) {
        this.SET_INDEX_PLATE(this.INDEX_PLATE() - 1);
        this.SET_FORM_PLATES(this.PLATES()[this.INDEX_PLATE()]);
        this.updImageList();
        this.$router.push({
          name: this.nameRoute,
          params: { plateId: this.PLATES()[this.INDEX_PLATE()].id }
        });
        return;
      }

      if (this.PLATES().length - 1 == this.INDEX_PLATE()) {
        this.SET_INDEX_PLATE(this.INDEX_PLATE() - 1);
        this.SET_FORM_PLATES(this.PLATES()[this.INDEX_PLATE()]);
        this.updImageList();
        this.$router.push({
          name: this.nameRoute,
          params: { plateId: this.PLATES()[this.INDEX_PLATE()].id }
        });
        return;
      }
    },
    submitLol() {},
    auditPlate() {
      this.plateId = this.FORM_PLATES().id;

      this.dialogFormVisible = true;
    },
    blockPlate() {
      this.blockcheck = true;
      this.BLOCK_PLATES({
        statusProductId: 2,
        id: this.FORM_PLATES().id,
        companyId: this.FORM_PLATES().companyId
      });
      this.DELETE_PLATE(this.FORM_PLATES().id);
      this.SET_INDEX_PLATE(this.INDEX_PLATE() - 1);
      if (this.INDEX_PLATE() == this.PLATES().length - 1)
        this.SET_INDEX_PLATE(this.INDEX_PLATE() - 1);

      this.nextPlate();
      this.blockcheck = false;
    },
    unblockPlate() {
      this.BLOCK_PLATES({
        statusProductId: 1,
        id: this.FORM_PLATES().id,
        companyId: this.FORM_PLATES().companyId
      });
      this.DELETE_PLATE(this.FORM_PLATES().id);
      this.SET_INDEX_PLATE(this.INDEX_PLATE() - 1);
      if (this.INDEX_PLATE() == this.PLATES().length - 1)
        this.SET_INDEX_PLATE(this.INDEX_PLATE() - 1);
      this.nextPlate();
    },
    updImageList() {
      this.imgList = [];
      console.log(this.FORM_PLATES());
      this.FORM_PLATES().documents_statements.map(el => {
        this.imgList.push("/" + el.url);
      });
      this.FORM_PLATES().documents_statement_tcs.map(el => {
        this.imgList.push("/" + el.url);
      });
    }
  },
  created: async function() {
    this.RESET_HEAD("Проверка знака");
    if (this.$route.name == "Audit") {
      this.statusAr = [1, 4];
    } else if (this.$route.name == "Audit2") {
      this.statusAr = 2;
    } else {
      this.statusAr = 3;
    }
    await this.GET_DOCUMENT_TYPES();
    await this.GET_OWNER_TYPES();
    await this.GET_PLATE_TYPES();
    await this.GET_OWNER_DOCUMENT_TYPES();
    await this.GET_STATUS();
    let index = 0;

    if (this.nameRoute == null) {
      await this.GET_PLATES({
        id: this.$route.params.id,
        status: this.statusAr
      });
      for (let i = 0; i < this.PLATES().length; i++) {
        if (this.PLATES()[i].id == this.$route.params.plateId) {
          this.SET_FORM_PLATES(this.PLATES()[i]);
          break;
        }
        index++;
      }
      this.SET_INDEX_PLATE(index);
    } else {
      for (let i = 0; i < this.PLATES().length; i++) {
        if (this.PLATES()[i].id == this.$route.params.plateId) {
          this.SET_FORM_PLATES(this.PLATES()[i]);
          break;
        }
        index++;
      }

      this.SET_INDEX_PLATE(index);
    }
    this.nameRoute = this.$route.name;
    this.updImageList();
  }
};
</script>

<style scoped>
.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  margin-top: 15px;
  margin-bottom: 15px;
}
.info-car:last-child {
  margin-top: 10px;
}
.info-owner {
  margin-top: 25px;
}
.info-owner-member {
  margin-top: 25px;
}
.size-collum {
  min-width: 30%;
}
.doc-img {
  width: 60%;
  height: 700px;
  overflow-x: scroll;
  position: sticky;
  top: 10px;
}
.el-form-item {
  margin-bottom: 0px;
}
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 0px;
}
.dialog-footer {
  margin-top: 10px;
}
.arrow-left {
  font-size: 75px;
  position: absolute;
  left: -100px;
  top: 40%;
  transition: 0.3s;
}
.arrow-right {
  font-size: 75px;
  position: absolute;
  right: -100px;
  top: 40%;
  transition: 0.3s;
}
.black {
  color: black;
}
.buttonflex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>

<style></style>
