<template>
  <div class="container ">
    <div class="head-table">
      <TabsMenu :is-admin="adminAcc" />
      <el-select
        v-model="companyId"
        filterable
        placeholder="Выберите предприятие"
        size="mini"
        @change="changeCompany"
      >
        <el-option
          v-for="item in COMPANY_NAMES()"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>

    <el-tabs
      v-model="activeName"
      :stretch="true"
      style="height: 20px;
    line-height: 20px;"
    >
      <el-tab-pane label="Изготовленных пластин" name="first">
        <div class="chart">
          <chart-plate
            v-if="loading"
            :chart-data="charUserData"
            :height="400"
            :width="800"
          />
          <chart-registry
            v-if="loading2"
            :chart-data="charUserData2"
            :height="400"
            :width="800"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane label="Отгрузки" name="second">
        <div class="chart">
          <chart-store
            v-if="loading3"
            :chart-data="charUserData3"
            :height="600"
            :width="800"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import TabsMenu from "../TabsMenu";
import { mapActions, mapGetters } from "vuex";
import ChartPlate from "./User/ChartPlate.vue";
import ChartRegistry from "./User/ChartRegistry.vue";
import ChartStore from "./User/ChartStore.vue";
export default {
  components: {
    TabsMenu,
    ChartPlate,
    ChartRegistry,
    ChartStore
  },
  props: ["adminAcc"],
  data() {
    return {
      loading: false,
      loading2: false,
      loading3: false,
      activeName: "first",
      companyId: null,
      charUserData: null,
      charUserData2: null,
      charUserData3: null
    };
  },
  methods: {
    ...mapActions(["RESET_HEAD", "SET_SCROLL", "GET_COMPANY_NAMES"]),
    ...mapGetters(["INDEX", "SCROLLTOP", "COMPANY_NAMES"]),
    changeCompany(id) {
      this.$http.get("api/statistics/v3" + "?companyId=" + id).then(res => {
        this.charUserData3 = res.data;
        this.loading3 = true;
      });
      this.$http.get("api/statistics/v2" + "?companyId=" + id).then(res => {
        this.charUserData2 = res.data;
        this.loading2 = true;
      });
      this.$http.get("api/statistics/v" + "?companyId=" + id).then(res => {
        this.charUserData = res.data;
        this.loading = true;
      });
    }
  },

  created: function() {
    this.RESET_HEAD("Статистика");

    if (!this.adminAcc) {
      this.$http.get("api/statistics/v3").then(res => {
        this.charUserData3 = res.data;
        this.loading3 = true;
      });
      this.$http.get("api/statistics/v2").then(res => {
        this.charUserData2 = res.data;
        this.loading2 = true;
      });
      this.$http.get("api/statistics/v").then(res => {
        this.charUserData = res.data;
        this.loading = true;
      });
    } else {
      this.GET_COMPANY_NAMES();
    }
  }
};
</script>

<style scoped>
.chart {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.head-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
