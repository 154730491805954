<template>
  <div class="container ">
    <div class="head-table">
      <TabsMenu :is-admin="false" :active-index2="INDEX()" />
      <div class="">
        <el-button size="mini" type="primary" @click.stop="openSendNews">
          Отправить сообщение
        </el-button>
      </div>
    </div>
    <div class="table-h">
      <!-- <el-table
        v-if="Array.isArray(NEWS2()) && NEWS2().length"
        ref="singleTable"
        :data="NEWS2()"
        stripe
        height="100%"
        size="small"
        @cell-click="highlightRow"
        highlight-current-row
        style="width: 100% "
      >
        <el-table-column type="index" label="№"> </el-table-column>
        <el-table-column label="Заголовок" prop="title" width="250px">
        </el-table-column>
        <el-table-column label="Дата" prop="createdAt" width="100px">
          <template slot-scope="scope"
            >{{ dataFormat(scope.row.createdAt) }}
          </template>
        </el-table-column>
        <el-table-column label="Текст">
          <template slot-scope="scope">
            <el-button @click.stop="openNews(scope.$index)" type="text">{{
              scope.row.text.slice(0, 100) + "......"
            }}</el-button>
          </template>
        </el-table-column>
      </el-table> -->
      <Chat />
    </div>
    <el-dialog
      :modalAppendToBody="false"
      title="Новость"
      v-if="Array.isArray(NEWS2()) && NEWS2().length"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
      width="500px"
    >
      <h1 style="text-align: center;">{{ NEWS2()[newsIndex].title }}</h1>
      <span style="white-space: break-spaces;"
        >{{ NEWS2()[newsIndex].text }}
      </span>
      <span slot="footer" class="footer-dialog">
        <el-button @click.stop="close">Закрыть</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :modalAppendToBody="false"
      title="Сообщение"
      :visible.sync="newsSend"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
      width="500px"
    >
      <div class="">
        <el-form :model="form">
          <el-input
            placeholder="Заголовок "
            v-model="form.title"
            style="margin: 10px 11px 12px 0px;"
          ></el-input>
          <el-input
            style="margin: 10px 11px 20px 0px; padding: "
            type="textarea"
            :autosize="{ minRows: 5 }"
            placeholder="Текст"
            maxlength="65000"
            show-word-limit
            v-model="form.text"
          >
          </el-input>
        </el-form>
        <span slot="footer" class="footer-dialog">
          <div style="display: contents">
            <el-button type="danger" @click.stop="closeModal">
              Закрыть
            </el-button>
            <el-button type="success" @click.stop="submit">
              Отправить
            </el-button>
          </div>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TabsMenu from "../components/TabsMenu";
import Chat from "./Chat/ChatAgent.vue";
import Moment from "moment";
export default {
  components: {
    TabsMenu,
    Chat
  },
  data() {
    return {
      dialogFormVisible: false,
      newsIndex: 0,
      newsSend: false,
      currentRow: null,
      timeoutId: null,
      form: {
        text: null,
        title: null
      }
    };
  },

  methods: {
    ...mapActions([
      "GET_NAME_USER",
      "GET_NEWS_COMPANY",
      "RESET_HEAD",
      "POST_NEWS_USER",
      "SET_ROWINDEX"
    ]),
    ...mapGetters(["NEWS2", "INDEX"]),
    openNews(id) {
      this.newsIndex = id;
      this.dialogFormVisible = true;
    },
    close() {
      this.dialogFormVisible = false;
    },
    dataFormat(date) {
      let a = new Moment(date).format("DD.MM.YYYY");

      return a.toString();
    },
    openSendNews() {
      this.newsSend = true;
    },
    closeModal() {
      this.newsSend = false;
      // Modal
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    highlightRow(row) {
      if (!this.timeoutId) {
        this.timeoutId = setTimeout(() => {
          if (this.currentRow == row) {
            this.currentRow = null;
            return this.setCurrent();
          }
          this.setCurrent(row);
          this.currentRow = row;
          this.timeoutId = null;
        }, 500);
      }
    },
    submit() {
      this.POST_NEWS_USER(this.form);
      this.form = {
        text: null,
        title: null
      };
      this.closeModal();
      setTimeout(() => {
        this.GET_NEWS_COMPANY(this.$route.params.id);
      }, 1000);
    }
  },
  created: async function() {
    this.RESET_HEAD("Список новостей компании");
    await this.GET_NEWS_COMPANY(this.$route.params.id);
  }
};
</script>

<style scoped>
.table-h {
  height: calc(100vh - 220px);
}

.head-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-dialog {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
