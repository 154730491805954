<template>
  <div class="">
    <el-dialog
      title="Tips"
      :visible.sync="dialogMobileForm"
      width="100%"
      :before-close="handleClose"
    >
      <el-form label-width="auto" ref="plateForm" label-position="top">
        <el-form-item size="mini" label="Скан заявления" prop="scan">
          <el-upload
            class="upload-demo"
            ref="upload2"
            :limit="12"
            multiple
            :file-list="productPhoto.documents_statements"
            accept=" .jpg, .jpeg, .png, .bmp, .heic"
            :auto-upload="false"
            :on-exceed="handleExceed"
            :on-change="beforeAvatarUpload"
            action=""
          >
            <el-button slot="trigger" size="small" type="primary"
              >Выберите файл</el-button
            >
            <div slot="file" slot-scope="{ file }">
              <span class="el-upload-list__item-thumbnail" v-if="!file.delete">
                {{ file.name }}
              </span>
              <span class="el-upload-list__item-actions" v-if="!file.delete">
                <span
                  v-if="file.url"
                  style="margin-right: 100px;"
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  class="el-upload-list__item-preview"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
            <div class="el-upload__tip" slot="tip">
              jpg/jpeg/png/bmp/heic
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item size="mini" label="Скан документа ТС" prop="scan2">
          <el-upload
            class="upload-demo"
            ref="upload3"
            :limit="12"
            multiple
            :file-list="productPhoto.documents_statement_tcs"
            accept=" .jpg, .jpeg, .png, .bmp, .heic"
            :auto-upload="false"
            :on-exceed="handleExceed"
            :on-change="beforeAvatarUpload"
            action=""
          >
            <el-button slot="trigger" size="small" type="primary"
              >Выберите файл</el-button
            >
            <div slot="file" slot-scope="{ file }">
              <span class="el-upload-list__item-thumbnail" v-if="!file.delete">
                {{ file.name }}
              </span>
              <span class="el-upload-list__item-actions" v-if="!file.delete">
                <span
                  v-if="file.url"
                  style="margin-right: 100px;"
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  class="el-upload-list__item-preview"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>

            <div class="el-upload__tip" slot="tip">
              jpg/jpeg/png/bmp/heic
            </div>
          </el-upload>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="handleClose">Отменить</el-button>
        <el-button type="primary" @click="postPlate">Загрузить</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible" width="100%">
      <el-image :src="dialogImageUrl" />
    </el-dialog>
  </div>
</template>

<script>
import { Message } from "element-ui";
export default {
  props: ["dialogMobileForm", "productId", "productPhoto"],
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: null
    };
  },
  methods: {
    handleClose() {
      this.$emit("closeDialogMobile");
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = "https://gibdd.znakvlg.ru/" + file.url;
      this.dialogVisible = true;
    },
    handleRemove(file) {
      if (file.url) {
        file.delete = true;
      } else {
        for (let i = 0; i < this.$refs.upload2.uploadFiles.length; i++) {
          if (this.$refs.upload2.uploadFiles[i].uid == file.uid) {
            this.$refs.upload2.uploadFiles.splice(i, 1);
          }
        }
        for (let i = 0; i < this.$refs.upload3.uploadFiles.length; i++) {
          if (this.$refs.upload3.uploadFiles[i].uid == file.uid) {
            this.$refs.upload3.uploadFiles.splice(i, 1);
          }
        }
      }
    },
    handleExceed() {
      Message.warning({
        message:
          "Лимит файлов 12. Чтоб загрузить новый файл, удалите один из загруженных.",
        offset: 80
      });
    },
    async postPlate() {
      if (
        this.$refs.upload2.uploadFiles.length > 0 ||
        this.$refs.upload3.uploadFiles.length > 0
      ) {
        let formData = new FormData();
        for (let i = 0; i < this.$refs.upload2.uploadFiles.length; i++) {
          formData.append(
            `document_statement${i}`,
            this.$refs.upload2.uploadFiles[i].raw
          );
        }
        for (let i = 0; i < this.$refs.upload3.uploadFiles.length; i++) {
          formData.append(
            `document_statement_tc${i}`,
            this.$refs.upload3.uploadFiles[i].raw
          );
        }
        formData.append(
          "data",
          JSON.stringify({
            productId: this.productId,
            oldPhoto: this.productPhoto
          })
        );

        this.$refs["upload2"].clearFiles();
        this.$refs["upload3"].clearFiles();

        this.$http
          .post("/api/plate/uploadPhoto", formData, {
            headers: {
              "content-type": "multipart/form-data"
            }
          })
          .then(async res => {
            if (res.data.ok) {
              this.handleClose();
              this.$emit("updateCloseMobile");
            } else {
              Message.warning({
                message: "Проверьте формат файла",
                offset: 80
              });
            }
          });
      } else {
        Message.warning({
          message: "Загрузите хоть 1 файл",
          offset: 80
        });
      }
    },
    beforeAvatarUpload(file) {
      const isPhoto =
        file.raw.type === "image/jpg" ||
        file.raw.type === "image/bmp" ||
        file.raw.type === "image/png" ||
        file.raw.type === "image/jpeg" ||
        file.raw.type === "image/heic";

      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isPhoto) {
        Message.error("Не тот формат");
        for (let i = 0; i < this.$refs.upload2.uploadFiles.length; i++) {
          if (this.$refs.upload2.uploadFiles[i].uid == file.uid) {
            this.$refs.upload2.uploadFiles.splice(i, 1);
          }
        }
        for (let i = 0; i < this.$refs.upload3.uploadFiles.length; i++) {
          if (this.$refs.upload3.uploadFiles[i].uid == file.uid) {
            this.$refs.upload3.uploadFiles.splice(i, 1);
          }
        }
      }
      if (!isLt2M) {
        this.$alert("Файл больше 10 МБ", "Ошибка", {
          confirmButtonText: "OK",
          type: "error"
        });
        for (let i = 0; i < this.$refs.upload2.uploadFiles.length; i++) {
          if (this.$refs.upload2.uploadFiles[i].uid == file.uid) {
            this.$refs.upload2.uploadFiles.splice(i, 1);
          }
        }
        for (let i = 0; i < this.$refs.upload3.uploadFiles.length; i++) {
          if (this.$refs.upload3.uploadFiles[i].uid == file.uid) {
            this.$refs.upload3.uploadFiles.splice(i, 1);
          }
        }
      }

      return isPhoto && isLt2M;
    }
  },
  created() {}
};
</script>

<style scoped>
.footer-dialog {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-select .el-input {
  width: 110px;
}
</style>
