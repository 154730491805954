<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  props: {
    chartData: {
      type: Object,
      default: null
    }
  },
  extends: Line,
  mixins: [reactiveProp],
  data: () => ({
    options: {
      responsive: false,
      interaction: {
        mode: "index",
        intersect: false
      },
      title: {
        display: true,
        text: "Статистика изготовленных пластин по типу"
      }
    }
  }),

  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
</script>
