/* eslint-disable no-unused-vars */
import axios from "axios";

const getDefaultState = () => ({
  news: Array,
  news2: Array,
  info_users: Array,
  all_news: Array
});
const state = getDefaultState();

const getters = {
  NEWS: state => {
    return state.news;
  },
  NEWS2: state => {
    return state.news2;
  },
  INFO_USERS: state => {
    return state.info_users;
  },
  ALL_NEWS: state => {
    return state.all_news;
  }
};

const actions = {
  RESET_NEWS: async ({ commit }) => {
    commit("resetStateNews");
  },
  GET_NEWS: async ({ commit }) => {
    await axios.get(`/api/news/`).then(({ data }) => {
      commit("SET_NEWS", data);
    });
  },
  GET_NEWS_COMPANY: async ({ commit }, id) => {
    await axios.get(`/api/news/comp/${id}`).then(({ data }) => {
      commit("SET_NEWS2", data);
    });
  },
  GET_NEWS_ALL: async ({ commit }, id) => {
    await axios.get(`/api/news/all/`).then(({ data }) => {
      commit("SET_NEWS2", data);
    });
  },
  GET_NEWS_COMPANY_INFO_CONF: async ({ commit }, id) => {
    await axios.get(`/api/news/admin/${id}`).then(({ data }) => {
      data;
      commit("INFO_USERS", data);
    });
  },
  GET_NEWS_COMPANY_ALL: async ({ commit }, id) => {
    await axios.get(`/api/news/compall/${id}`).then(({ data }) => {
      commit("SET_NEWS_ALL", data);
    });
  },
  // eslint-disable-next-line no-unused-vars
  POST_NEWS: async ({ commit }, data) => {
    await axios.post(`/api/news`, data);
  },
  // eslint-disable-next-line no-unused-vars
  PUT_NEWS: async ({ commit }, id) => {
    await axios.post(`/api/news`, id);
  },
  POST_NEWS_USER: async ({ commit }, data) => {
    await axios.post(`/api/news/user`, data);
  }
};

const mutations = {
  SET_NEWS: (state, data) => {
    state.news = data;
  },
  SET_NEWS2: (state, data) => {
    state.news2 = data;
  },
  INFO_USERS: (state, data) => {
    state.info_users = data;
  },
  resetStateNews(state) {
    Object.assign(state, getDefaultState());
  },
  SET_NEWS_ALL(state, data) {
    state.all_news = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
