<template>
  <div class="container clearfix " style="margin-left: 190px;">
    <div
      class="chat"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <!-- end chat-header -->

      <div>
        <MessengerChat />
        <ChatInput :sendUser="true" :companyId="companyId" :chatBool="true" />
      </div>
      <!-- end chat-history -->

      <!-- end chat-message -->
    </div>

    <!-- end chat -->
  </div>
  <!-- end container -->
</template>

<script>
import moment from "moment";
import MessengerChat from "../MessengerChat/MessengerChaAgent.vue";
import ChatInput from "../NewsForm.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["newsAll", "tableNews", "chatBool"],
  components: {
    MessengerChat,
    ChatInput
  },
  data() {
    return {
      loading: false,
      companyId: null
    };
  },
  methods: {
    ...mapGetters(["NEWS2"]),
    ...mapActions(["GET_NEWS_COMPANY", "RESET_HEAD"]),
    newsText(text) {
      return text.replace(/\n/g, "<br>");
    },
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    async openChat(companyId, title, avatar) {
      this.loading = true;

      this.companyId = companyId;
      this.avatar = avatar;
      await this.GET_NEWS_COMPANY_ALL(companyId);
      this.loading = false;
    }
  },
  created: async function() {
    this.RESET_HEAD("Список новостей компании");
    this.companyId = this.$route.params.id;
    await this.GET_NEWS_COMPANY(this.$route.params.id);
  }
};
</script>

<style scoped>
.people-list {
  width: 35%;
  float: left;
  height: calc(100vh - 180px);
}
.people-list .search {
  padding: 20px;
}
.people-list input {
  border-radius: 3px;
  border: none;
  padding: 14px;
  color: white;
  background: #6a6c75;
  width: 90%;
  font-size: 14px;
}
.people-list .fa-search {
  position: relative;
  left: -25px;
}
.people-list ul {
  padding: 20px;

  overflow-y: scroll;
  height: 575px;
}
.people-list ul li {
  padding-bottom: 20px;
}
.people-list img {
  float: left;
}
.people-list .about {
  float: left;
  margin-top: 8px;
}
.people-list .about {
  padding-left: 8px;
}
.people-list .status {
  color: #92959e;
}
.chat {
  width: 65%;
  float: left;
  background: #f2f5f8;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #434651;
  height: calc(100vh - 220px);
}
.chat .chat-header {
  padding: 5px;
  border-bottom: 2px solid white;
}
.chat .chat-header img {
  float: left;
}
.chat .chat-header .chat-about {
  float: left;
  padding-left: 10px;
  margin-top: 17px;
}
.chat .chat-header .chat-with {
  font-weight: bold;
  font-size: 16px;
}
.chat .chat-header .chat-num-messages {
  color: #92959e;
}
.chat .chat-header .fa-star {
  float: right;
  color: #d8dadf;
  font-size: 20px;
  margin-top: 12px;
}
.chat .chat-history {
  padding: 30px 30px 20px;
  border-bottom: 2px solid white;
  overflow-y: scroll;
  height: 575px;
}
.chat .chat-history .message-data {
  margin-bottom: 15px;
}
.chat .chat-history .message-data-time {
  color: #a8aab1;
  padding-left: 6px;
}
.chat .chat-history .message {
  color: white;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  margin-bottom: 30px;
  width: 90%;
  position: relative;
}
.chat .chat-history .message:after {
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #86bb71;
  border-width: 10px;
  margin-left: -10px;
}
.chat .chat-history .my-message {
  background: #86bb71;
}
.chat .chat-history .other-message {
  background: #94c2ed;
}
.chat .chat-history .other-message:after {
  border-bottom-color: #94c2ed;
  left: 93%;
}
.chat .chat-message {
}
.chat .chat-message textarea {
  width: 100%;
  border: none;
  padding: 10px 20px;
  font: 14px/22px "Lato", Arial, sans-serif;
  margin-bottom: 10px;
  border-radius: 5px;
  resize: none;
}
.chat .chat-message .fa-file-o,
.chat .chat-message .fa-file-image-o {
  font-size: 16px;
  color: gray;
  cursor: pointer;
}
.chat .chat-message button {
  float: right;
  color: #94c2ed;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  background: #f2f5f8;
}
.chat .chat-message button:hover {
  color: #75b1e8;
}
.online,
.offline,
.me {
  margin-right: 3px;
  font-size: 10px;
}
.online {
  color: #86bb71;
}
.offline {
  color: #e38968;
}
.me {
  color: #94c2ed;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.float-right {
  float: right;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.friend-drawer {
  padding: 10px 15px;
  display: flex;
  vertical-align: baseline;
  background: #fff;
  transition: 0.3s ease;
  background: #f2f5f8;
}
.friend-drawer--grey {
  background: #eee;
}
.friend-drawer .text {
  margin-left: 12px;
  width: 70%;
}
.friend-drawer .text h6 {
  margin-top: 6px;
  margin-bottom: 0;
}
.friend-drawer .text p {
  margin: 0;
}
.friend-drawer .time {
  color: grey;
}
.friend-drawer--onhover:hover {
  background: #74b9ff;
  cursor: pointer;
}
.friend-drawer--onhover:hover p,
.friend-drawer--onhover:hover h6,
.friend-drawer--onhover:hover .time {
  color: #fff !important;
}
hr {
  margin: 5px auto;
  width: 60%;
}
.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 40px;
}
</style>
