<template>
  <div class="container ">
    <div class="">
      {{ this.COMPANY_NAME().length > 1 ? this.COMPANY_NAME() : "" }}
    </div>
    <div class="">
      <div class="header-bth" style="">
        <TabsMenu :is-admin="true" />
        <div
          class=""
          style="display: flex;
    justify-content: space-between;"
        >
          <div class="" style="padding-bottom: 10px;">
            <el-menu :default-active="activeIndex" mode="horizontal">
              <el-menu-item index="1" @click="lol2(1)">
                Добавлено
              </el-menu-item>
              <el-menu-item index="2" @click="lol2(2)">
                Архив
              </el-menu-item>
              <el-menu-item index="3" @click="lol2(3)">
                Брак
              </el-menu-item>
            </el-menu>
          </div>

          <div class="" style="display: flex;">
            <div class="">
              <el-button
                type="danger"
                size="mini"
                @click="blockPlate"
                :disabled="multipleSelection.length < 1 || indexMenu != 1"
                >Заблокировать</el-button
              >
              <el-button
                type="danger"
                size="mini"
                @click="updatePlate"
                :disabled="multipleSelection.length != 1 || indexMenu != 1"
                >Отправить на ред.
              </el-button>
              <el-button
                :disabled="indexMenu != 2"
                type="danger"
                size="mini"
                @click="unblockPlate"
                >Разблокировать
              </el-button>
              <el-button
                type="danger"
                size="mini"
                icon="el-icon-tickets"
                @click="excelDownload = true"
                >Excel</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-bind:class="{
        'table-company': showHead,
        'table-company2': !showHead
      }"
      v-if="Array.isArray(PLATES()) && PLATES().length"
    >
      <el-table
        stripe
        @sort-change="changeTableSort"
        :row-class-name="tableRowClassName"
        height="100%"
        size="mini"
        @cell-click="highlightRow"
        @cell-dblclick="auditPlateDb"
        highlight-current-row
        ref="table"
        v-if="Array.isArray(dataTable) && dataTable.length && renderComponent"
        @filter-change="test"
        style="width: 100%;  "
        :data="dataTable"
        :cell-class-name="tableCellClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column width="40" type="selection"> </el-table-column>
        <el-table-column :label="textLabels[0]" property="  " width="40px">
          <template slot="header" slot-scope="">
            <span style=" font-size: 10px;" @click.stop="forceRerender">{{
              textLabels[0]
            }}</span>
            <i
              class="el-icon-setting"
              style="margin-right: 2px"
              @click.stop="forceRerender"
            ></i>
          </template>
          <template slot-scope="scope">
            {{
              scope.$index +
                1 +
                (currentPage == 1 ? 0 : (currentPage - 1) * 250)
            }}
          </template>
        </el-table-column>

        <el-table-column
          width="94"
          :filters="filterDate"
          property="date_create"
          :sortable="'custom'"
          :filter-method="filterHandler"
          column-key="date"
          :label="textLabels[1]"
        ></el-table-column>
        <el-table-column
          width="100"
          :sortable="'custom'"
          :filter-method="filterHandler"
          :filters="filterPlate"
          column-key="plate"
          :label="textLabels[2]"
          prop="plateTypeId"
        >
          <template slot-scope="scope">
            <el-popover
            placement="top-start"
            title="Ошибка"
            :disabled="scope.row.statusProductId != 3"
            width="200"
            trigger="hover"
            :content="lolkek(scope.row)">
            <div slot="reference" >
            {{
              scope.row.plateTypeId
                ? PLATE_TYPES_LIST()[scope.row.plateTypeId - 1].title
                : ""
            }}
            {{ scope.row.hasOwnProperty("document_statement") ? "" : "(Брак)"
            }}<br />
            <el-button
              type="text"
              @click.stop="auditPlate(scope.row.id, scope.row.statusProductId)"
              style="color: #cc0000;"
            >
              {{ scope.row.license_plate }}
            </el-button>
            <br />
            {{ scope.row.serial_number }}
            </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column width="150" :label="textLabels[3]">
          <template slot-scope="scope">
            {{
              scope.row.typeDocumentTransportId
                ? DOCUMENT_TYPES_LIST()[scope.row.typeDocumentTransportId - 1]
                    .title
                : ""
            }}<br />
            {{ scope.row.document }}
            <br />
            {{ scope.row.date_issuing_transport_document }}
          </template>
        </el-table-column>
        <el-table-column header-align="center" :label="textLabels[4]">
          <el-table-column width="140" :label="textLabels[5]">
            <template slot-scope="scope">
              <div v-if="scope.row.ownerTypeId == 1">
                {{
                  scope.row.individual_document_surname +
                    " " +
                    scope.row.individual_document_name +
                    " " +
                    scope.row.individual_document_patronymic
                }}
              </div>
              <div v-else>
                {{ scope.row.entity_document_name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="120" prop="name" :label="textLabels[6]">
            <template slot-scope="scope">
              <div v-if="scope.row.ownerTypeId == 1">
                <div v-if="scope.row.typeDocumentOwnerId == 1">
                  {{
                    scope.row.typeDocumentOwnerId
                      ? OWNER_TYPES_DOC_LIST()[
                          scope.row.typeDocumentOwnerId - 1
                        ].title
                      : " "
                  }}
                </div>
                <div v-else>
                  {{ scope.row.individual_document_other_type }}
                </div>
                {{ scope.row.individual_document_serial_number }}<br />
                {{ scope.row.individual_document_date_issuing }}
              </div>
              <div v-else>
                {{ scope.row.entity_document_inn }}<br />
                {{ scope.row.entity_document_orgn }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          header-align="center"
          prop="name"
          :label="textLabels[7]"
        >
          <el-table-column width="145" prop="name" :label="textLabels[8]">
            <template slot-scope="scope">
              {{
                scope.row.representative_individual_document_surname
                  ? scope.row.representative_individual_document_surname +
                    " " +
                    scope.row.representative_individual_document_name +
                    " " +
                    scope.row.representative_individual_document_patronymic
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column width="120" prop="name" :label="textLabels[9]">
            <template slot-scope="scope">
              <div v-if="scope.row.representative_document_type_ownerId == 1">
                {{
                  scope.row.typeDocumentOwnerId
                    ? OWNER_TYPES_DOC_LIST()[scope.row.typeDocumentOwnerId - 1]
                        .title
                    : ""
                }}
              </div>
              <div v-else>
                {{ scope.row.representative_individual_document_other_type }}
              </div>
              {{ scope.row.representative_individual_document_serial_number
              }}<br />
              {{ scope.row.representative_individual_document_date_issuing }}

              <div>
                {{ scope.row.representative_entity_document_inn }}<br />
                {{ scope.row.representative_entity_document_orgn }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          width="100"
          prop="date_issuing"
          :label="textLabels[10]"
        >
        </el-table-column>
        <el-table-column width="50" prop="name" :label="textLabels[11]">
          <template slot-scope="scope">
            <el-button
              v-if="
                scope.row.hasOwnProperty('document_statement') &&
                  scope.row.documents_statements.length > 0
              "
              type="danger"
              icon="el-icon-folder-opened"
              size="mini"
              circle
              @click.stop="onPreview(scope.row.documents_statements)"
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column width="50" prop="name" :label="textLabels[12]">
          <template slot-scope="scope">
            <el-button
              type="danger"
              v-if="
                scope.row.hasOwnProperty('document_statement') &&
                  scope.row.documents_statement_tcs.length > 0
              "
              icon="el-icon-folder-opened"
              size="mini"
              circle
              @click.stop="onPreview(scope.row.documents_statement_tcs)"
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column
          width="50"
          prop="count_plate"
          :label="textLabels[13]"
        ></el-table-column>
      </el-table>
      <div class="" style="text-align: center;">
        <el-pagination
          small
          layout="prev, pager, next"
          :page-size="250"
          :current-page.sync="currentPage"
          :total="PLATES().length"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
   <el-dialog
      :modalAppendToBody="false"
      title="Выгрузка пластин"
      :visible.sync="excelDownload"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
      width="700px"
    >
    <el-form :inline="true" :v-model="excelForm">
      <el-form-item label="От">
          <el-input
            v-model="excelForm.start"
            size="small"
            placeholder="дд.мм.гггг"
            v-mask="'##.##.####'"
          ></el-input>
      </el-form-item>
      <el-form-item label="До">
          <el-input
            v-model="excelForm.end"
            size="small"
            placeholder="дд.мм.гггг"
            v-mask="'##.##.####'"
          ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="excel2('')">Скачать</el-button>
        <el-button size="small" type="danger" @click="excel2('all')">Скачать всё</el-button>
      </el-form-item>
    </el-form>
    </el-dialog>
    <el-dialog
      :modalAppendToBody="false"
      title="Описание ошибки"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
      width="600px"
    >
      <RegistryPlateForm v-on:closeModal="dialogUpd" :plate-id="plateId" />
    </el-dialog>
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgList"
      :initial-index="indexImg"
    />
  </div>
</template>

<script>
import RegistryPlateForm from "./RegistryPlateForm";
import TabsMenu from "./TabsMenu";
import { mapActions, mapGetters } from "vuex";
import ElImageViewer from "../../node_modules/element-ui/packages/image/src/image-viewer";
import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import saveAs from "file-saver";
import Moment from "moment";
export default {
  components: {
    RegistryPlateForm,
    ElImageViewer,
    TabsMenu
  },
  data() {
    return {
      timeoutId: null,
      activeIndex: "1",
      filterDate: [],
      excelForm: {
        start: "",
        end: "",
        companyId : this.$route.params.id
      },
      loading: true,
      dataTable: null,
      currentPage: 1,
      imgList: [],
      showViewer: false,
      multipleSelection: [],
      dialogFormVisible: false,
      excelDownload: false,
      indexMenu: 1,
      plateId: null,
      showHead: true,
      indexImg: 0,
      filterPlate: [],
      renderComponent: true,
      textLabels: [
        "№",
        "Дата изготовления гос. регистрационного знака",
        "Cведения о государственном регистрационном номере транспортного средства",
        "Документ, идентифицирующий транспортное средство",
        "Сведения о владельце траспортного средства",
        "ФИО(при наличии)/наимование юридического лица(при наличии)",
        "Вид, серия, номер, дата выдачи документа, удостоверяющего личность /ИНН/ОГРН",
        "Сведения о представителя владельца траспортного средства",
        "ФИО(при наличии) представителя, в том числе представителя специциалированной организации",
        "Вид, серия, номер, дата выдачи документа, удостоверяющего личность",
        "Дата выдачи гос. регистрациионного знака",
        "Скан заявления",
        "Скан документа ТС",
        "Кол-во пластин"
      ]
    };
  },
  methods: {
    ...mapActions([
      "GET_PLATES",
      "GET_PLATES_DEFECT",
      "GET_DOCUMENT_TYPES",
      "GET_OWNER_TYPES",
      "SORT_PLATE_DATE",
      "GET_PLATE_TYPES",
      "GET_OWNER_DOCUMENT_TYPES",
      "GET_COMPANY_NAME",
      "BLOCK_PLATES",
      "GET_STATUS",
      "RESET_HEAD",
      "SET_SCROLL",
      "RESET_SCROLL",
      "SET_ROWINDEX",
      "SET_PAGENUMBER",
      "RESET_INDEXMENU"
    ]),
    ...mapGetters([
      "PLATES",
      "OWNER_TYPES_LIST",
      "DOCUMENT_TYPES_LIST",
      "PLATE_TYPES_LIST",
      "OWNER_TYPES_DOC_LIST",
      "SCROLLTOP",
      "INFO_COMPANY",
      "COMPANY_NAME",
      "STATUS_PLATE",
      "INDEXMENU",
      "ROWINDEX",
      "SCROLLTOP",
      "PAGENUMBER"
    ]),

    handleCurrentChange(val) {
      this.dataTable = this.PLATES().slice(
        val == 1 ? 0 : (val - 1) * 250,
        250 * val
      );
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    tableRowClassName({ row }) {
      if (row.statusProductId == 3) {
        return "warning-row";
      }
      if (row.statusProductId == 4) {
        return "pereproverit-row";
      }
    },
    tableCellClassName() {
      return "no-border";
    },
    dialogUpd() {
      this.dialogFormVisible = false;
      this.dataTable = this.PLATES().slice(0, 250);
    },
    onPreview(arr) {
      this.showViewer = true;
      this.imgList = [];
      arr.map(el => {
        this.imgList.push("/" + el.url);
      });
    },
    setCurrent(row) {
      this.$refs.table.setCurrentRow(row);
    },
    highlightRow(row) {
      if (!this.timeoutId) {
        this.timeoutId = setTimeout(() => {
          if (this.currentRow == row) {
            this.currentRow = null;
            this.RESET_SCROLL();
            this.SET_PAGENUMBER(1);
            return this.setCurrent();
          }
          this.setCurrent(row);
          this.currentRow = row;
          this.SET_SCROLL({
            name: this.$route.name,
            top: this.$refs["table"].bodyWrapper.scrollTop
          });
          this.SET_PAGENUMBER(this.currentPage);
          this.SET_ROWINDEX(row.id);
          this.timeoutId = null;
        }, 500);
      }
    },
    forceRerender() {
      this.showHead = !this.showHead;
      localStorage.setItem("showHead", this.showHead);
      if (this.showHead) {
        this.textLabels = [
          "№",
          "Дата изготовления гос. регистрационного знака",
          "Cведения о государственном регистрационном номере транспортного средства",
          "Документ, идентифицирующий транспортное средство",
          "Сведения о владельце траспортного средства",
          "ФИО(при наличии)/наимование юридического лица(при наличии)",
          "Вид, серия, номер, дата выдачи документа, удостоверяющего личность /ИНН/ОГРН",
          "Сведения о представителя владельца траспортного средства",
          "ФИО(при наличии) представителя, в том числе представителя специциалированной организации",
          "Вид, серия, номер, дата выдачи документа, удостоверяющего личность",
          "Дата выдачи гос. регистрациионного знака",
          "Скан заявления",
          "Скан документа ТС",
          "Кол-во пластин"
        ];
      } else {
        this.textLabels = [
          "№",
          "Дата",
          "Свед",
          "Док.",
          "Свед. влад.",
          "ФИО",
          "Док.",
          "Свед. пред.",
          "ФИО",
          "Док.",
          "Дата выд.",
          "Заяв.",
          "ТС",
          "Кол"
        ];
      }

      // Сначала скроем компонент
      this.renderComponent = false;

      this.$nextTick(() => {
        // А потом покажем снова
        this.renderComponent = true;
      });
    },
    async lol2(id) {
      const loading = this.$loading({
        lock: true,
        text: "Загрузка",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      switch (id) {
        case 1:
          if (this.indexMenu != 1)
            await this.GET_PLATES({
              id: this.$route.params.id,
              status: [1, 3, 4]
            });

          this.dataTable = this.PLATES().slice(0, 250);
          this.currentPage = 1;
          loading.close();
          break;
        case 2:
          if (this.indexMenu != 2)
            await this.GET_PLATES({
              id: this.$route.params.id,
              status: 2
            });
          this.dataTable = this.PLATES().slice(0, 250);
          this.currentPage = 1;
          loading.close();
          break;
        case 3:
          if (this.indexMenu != 3)
            await this.GET_PLATES_DEFECT(this.$route.params.id);
          this.dataTable = this.PLATES().slice(0, 250);
          this.currentPage = 1;
          loading.close();
          break;
      }
      this.indexMenu = id;
      this.RESET_INDEXMENU(id);
      setTimeout(() => {
        this.RESET_SCROLL();
      }, 2000);
    },

    updatePlate() {
      this.multipleSelection.forEach(el => {
        this.plateId = el.id;
      });
      this.dialogFormVisible = true;
    },
    test(e) {
      if (e.date.length == 0) {
        this.dataTable = this.PLATES().slice(0, 250);
        return;
      }
    },
    async blockPlate() {
      this.multipleSelection.forEach(el => {
        this.BLOCK_PLATES({
          statusProductId: 2,
          id: el.id,
          companyId: el.companyId
        });
      });

      setTimeout(async () => {
        await this.GET_PLATES({
          id: this.$route.params.id,
          status: [1, 3]
        });
      }, 1500);
      this.dataTable = this.PLATES().slice(0, 250);
    },
    async unblockPlate() {
      this.multipleSelection.forEach(el => {
        this.BLOCK_PLATES({
          statusProductId: 1,
          id: el.id,
          companyId: el.companyId
        });
      });
      setTimeout(async () => {
        await this.GET_PLATES({
          id: this.$route.params.id,
          status: 2
        });
      }, 1500);
      this.dataTable = this.PLATES().slice(0, 250);
    },
    // eslint-disable-next-line no-unused-vars
    auditPlate(id, status) {
      if (status == 1 || status == 4) {
        this.RESET_HEAD("Проверка знака");
        this.$router.push({ name: "Audit", params: { plateId: id } });
      }
      if (status == 2) {
        this.RESET_HEAD("Проверка знака");
        this.$router.push({ name: "Audit2", params: { plateId: id } });
      }
      if (status == 3) {
        this.RESET_HEAD("Проверка знака");
        this.$router.push({ name: "Audit3", params: { plateId: id } });
      }
    },
    auditPlateDb(row) {
      clearTimeout(this.timeoutId);
      if (row.statusProductId == 1 || row.statusProductId == 4) {
        this.RESET_HEAD("Проверка знака");
        this.$router.push({ name: "Audit", params: { plateId: row.id } });
      }
      if (row.statusProductId == 2) {
        this.RESET_HEAD("Проверка знака");
        this.$router.push({ name: "Audit2", params: { plateId: row.id } });
      }
      if (row.statusProductId == 3) {
        this.RESET_HEAD("Проверка знака");
        this.$router.push({ name: "Audit3", params: { plateId: row.id } });
      }
    },
    excel() {
      let ExcelJSWorkbook = new ExcelJS.Workbook();
      let worksheet = ExcelJSWorkbook.addWorksheet("ExcelJS sheet");
      //       worksheet.mergeCells("A1:A2");
      //
      const columns = this.$refs.table.$refs.tableHeader.columns;
      let arrCol = [];
      for (let i = 1; i < columns.length; i++) {
        if (i != 10 && i != 11) {
          arrCol.push({
            label: columns[i].label,
            width: columns[i].width
          });
        }
      }
      const alphabet = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
      ];

      for (let i = 0; i < arrCol.length; i++) {
        if (i == 4 || i == 6) {
          worksheet.mergeCells(`${alphabet[i]}1:${alphabet[i + 1]}1`);
          let cell2 = worksheet.getCell(`${alphabet[i]}1`);
          if (i == 4)
            cell2.value = "Сведения о владельце траспортного средства";
          else
            cell2.value =
              "Сведения о представителя владельца траспортного средства";
          cell2.font = {
            name: "Times New Roman",
            size: 14,
            bold: true
          };
          cell2.alignment = {
            wrapText: true,
            vertical: "middle",
            horizontal: "center"
          };
          cell2.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          };
        }
        let currentColumnWidth = arrCol[i].width;
        worksheet.getColumn(alphabet[i]).width =
          currentColumnWidth !== undefined ? currentColumnWidth / 6 : 20;
        if (i < 4 || i > 7)
          worksheet.mergeCells(`${alphabet[i]}1:${alphabet[i]}2`);

        let cell = worksheet.getCell(`${alphabet[i]}2`);
        cell.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell.value = arrCol[i].label;
        cell.font = {
          name: "Times New Roman",
          size: 14,
          bold: true
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
      }
      const rowsTable = document.getElementsByClassName("el-table__row");
      for (let i = 0, col = 3; i < rowsTable.length; i++, col++) {
        let cell = worksheet.getCell(`${alphabet[0]}${col}`);
        let cell1 = worksheet.getCell(`${alphabet[1]}${col}`);
        let cell2 = worksheet.getCell(`${alphabet[2]}${col}`);
        let cell3 = worksheet.getCell(`${alphabet[3]}${col}`);
        let cell4 = worksheet.getCell(`${alphabet[4]}${col}`);
        let cell5 = worksheet.getCell(`${alphabet[5]}${col}`);
        let cell6 = worksheet.getCell(`${alphabet[6]}${col}`);
        let cell7 = worksheet.getCell(`${alphabet[7]}${col}`);
        let cell8 = worksheet.getCell(`${alphabet[8]}${col}`);
        let cell9 = worksheet.getCell(`${alphabet[9]}${col}`);
        cell.value = rowsTable[i].childNodes[1].outerText;
        cell1.value = rowsTable[i].childNodes[2].outerText;
        cell2.value = rowsTable[i].childNodes[3].outerText;
        cell3.value = rowsTable[i].childNodes[4].outerText;
        cell4.value = rowsTable[i].childNodes[5].outerText;
        cell5.value = rowsTable[i].childNodes[6].outerText;
        cell6.value = rowsTable[i].childNodes[7].outerText;
        cell7.value = rowsTable[i].childNodes[8].outerText;
        cell8.value = rowsTable[i].childNodes[9].outerText;
        cell9.value = rowsTable[i].childNodes[12].outerText;
        cell.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell1.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell2.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell3.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell4.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell5.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell6.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell7.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell8.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell9.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell1.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell2.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell3.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell4.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell5.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell6.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell7.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell8.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell9.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell1.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell2.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell3.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell4.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell5.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell6.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell7.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell8.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell9.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
      }
      ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `ТаблицаЗаявлений.xlsx`
        );
      });
    },
    changeTableSort(column) {
      this.SORT_PLATE_DATE({
        fieldName: column.prop,
        sortingType: column.order
      });
      this.dataTable = this.PLATES().slice(0, 250);
      this.currentPage = 1;
    },
    lolkek(scope){
      if (scope.info_edit_messages != undefined) {
        return  scope.info_edit_messages.length > 0 ? scope.info_edit_messages[0].message : ''
      }
      return ""
    },
    dateArray() {
      Moment.locale("ru");

      for (let i = 0; i < 6; i++) {
        this.filterDate.push({
          text: Moment()
            .startOf("month")
            .subtract(i, "months")
            .format("MMMM YYYY"),
          value: Moment()
            .startOf("month")
            .subtract(i, "months")
            .format("YYYYMMDD")
        });
      }
    },
    plateArray() {
      this.PLATE_TYPES_LIST().map(el => {
        this.filterPlate.push({
          text: el.title,
          value: el.id
        });
      });
      this.filterPlate.push({
        text: "Брак",
        value: "defect"
      });
    },

    closeViewer() {
      this.showViewer = false;
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      if (property == "plateTypeId") {
        if (value === "defect") {
          // eslint-disable-next-line no-prototype-builtins
          if (!row.hasOwnProperty("document_statement")) {
            return true;
          }
        } else {
          if (
            row[property] === value &&
            // eslint-disable-next-line no-prototype-builtins
            row.hasOwnProperty("document_statement")
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
      if (property == "date_create") {
        const start = Moment(value).format("YYYYMMDD");
        const end = Moment(value)
          .endOf("month")
          .format("YYYYMMDD");
        this.dataTable = this.PLATES();

        return (
          start <= Moment(row.date_create, "DD.MM.YYYY").format("YYYYMMDD") &&
          end >= Moment(row.date_create, "DD.MM.YYYY").format("YYYYMMDD")
        );
      }
      if (property == "status") {
        if (value == row.statusProductId) return true;
        else return false;
      }
    },
    async startSort() {
      if (localStorage.getItem("date_create")) {
        await this.SORT_PLATE_DATE({
          fieldName: "date_create",
          sortingType: localStorage.getItem("date_create")
        });
      }
      if (localStorage.getItem("plateTypeId")) {
        await this.SORT_PLATE_DATE({
          fieldName: "plateTypeId",
          sortingType: localStorage.getItem("plateTypeId")
        });
      }

      this.dataTable = this.PLATES().slice(0, 50);
      this.currentPage = 1;
    },
    excel2(type){
             const loading = this.$loading({
            lock: true,
            text: "Загрузка",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          if (type == "all"){
            this.excelForm.type = "all"
          } else {
            this.excelForm.type = ""
          }
          this.$http
            .post("/api/printout/admin", this.excelForm, {
              responseType: "blob"
            })
            .then(res => {
              saveAs(
                res.data,
                `${this.COMPANY_NAME()} Полная.xlsx`
              );

            });
          loading.close();

    }
  },
  created: async function() {
    this.RESET_HEAD("Реестр номерных знаков подлежащих проверке");
    const loading = this.$loading({
      lock: true,
      text: "Загрузка",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)"
    });
    this.dateArray();

    await this.GET_DOCUMENT_TYPES();
    await this.GET_OWNER_TYPES();
    await this.GET_PLATE_TYPES();
    await this.GET_OWNER_DOCUMENT_TYPES();
    if (localStorage.getItem("showHead") == "false") {
      this.textLabels = [
        "№",
        "Дата",
        "Свед",
        "Док.",
        "Свед. влад.",
        "ФИО",
        "Док.",
        "Свед. пред.",
        "ФИО",
        "Док.",
        "Дата выд.",
        "Заяв.",
        "ТС",
        "Кол"
      ];
    }
    // await this.GET_PLATES({
    //   id: this.$route.params.id,
    //   status: [1, 3, 4]
    // });
    switch (this.INDEXMENU()) {
      case 1:
        await this.GET_PLATES({
          id: this.$route.params.id,
          status: [1, 3, 4]
        });

        this.currentPage = 1;
        break;
      case 2:
        await this.GET_PLATES({
          id: this.$route.params.id,
          status: 2
        });
        this.currentPage = 1;
        break;
      case 3:
        await this.GET_PLATES_DEFECT(this.$route.params.id);
        this.currentPage = 1;
        break;
    }
    this.indexMenu = this.INDEXMENU();

    this.activeIndex = String(this.INDEXMENU());
    await this.startSort();
    await this.GET_COMPANY_NAME(this.$route.params.id);
    await this.plateArray();

    if (this.PAGENUMBER() != 1) {
      this.currentPage = this.PAGENUMBER();
      this.handleCurrentChange(this.PAGENUMBER());
    } else {
      this.dataTable = this.PLATES().slice(0, 250);
    }
    loading.close();
    this.excelDownload = false
  },
  updated: function() {
    if (this.$refs["table"] && this.SCROLLTOP()(this.$route.name) > 0) {
      this.$refs["table"].bodyWrapper.scrollTop = this.SCROLLTOP()(
        this.$route.name
      );
      const id = this.ROWINDEX();

      this.currentRow = this.$refs.table.data.find(el => {
        if (el.id === id) {
          return true;
        }
        return false;
      });
      this.$refs.table.setCurrentRow(this.currentRow);
    }
  },
  mounted: function() {}
};
</script>

<style scoped>
.header-bth {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
}
.table-company {
  height: calc(100vh - 242px);
}
.table-company2 {
  height: calc(100vh - 242px);
}
.el-table {
  color: black;
}
.head-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
