<template>
  <div class="">
    <span
      style="font-size: 22px;
    font-weight: 600;"
      >{{ this.HEAD() }}</span
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  methods: {
    ...mapGetters(["HEAD"])
  }
};
</script>

<style scoped></style>
