const getDefaultState = () => ({
  scrollTop: new Map(),
  indexMenu: 1,
  rowIndex: Number,
  pageNumber: 1
});
const state = getDefaultState();

const getters = {
  SCROLLTOP: state => page => {
    return state.scrollTop.get(page);
  },
  INDEXMENU: state => {
    return state.indexMenu;
  },
  ROWINDEX: state => {
    return state.rowIndex;
  },
  PAGENUMBER: state => {
    return state.pageNumber;
  }
};

const actions = {
  SET_SCROLL: async ({ commit }, page) => {
    commit("MUT_SCROLL", page);
  },
  RESET_SCROLL: async ({ commit }, page) => {
    commit("MUL_RESET_SCROll", page);
  },
  RESET_INDEXMENU: async ({ commit }, id) => {
    commit("SET_INDEXMENU", id);
  },
  SET_ROWINDEX: async ({ commit }, row) => {
    commit("MUT_ROW", row);
  },
  SET_PAGENUMBER: async ({ commit }, page) => {
    commit("MUT_PAGENUMBE", page);
  }
};

const mutations = {
  MUT_SCROLL: (state, page) => {
    state.scrollTop.set(page.name, page.top);
  },
  MUL_RESET_SCROll: (state, page) => {
    state.scrollTop.delete(page);
  },
  SET_INDEXMENU: (state, date) => {
    state.indexMenu = date;
  },
  MUT_PAGENUMBE: (state, date) => {
    state.pageNumber = date;
  },
  MUT_ROW: (state, date) => {
    state.rowIndex = date;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
