import Vue from "vue";
import {
  Button,
  Form,
  FormItem,
  Input,
  Table,
  TableColumn,
  Icon,
  Dialog,
  Select,
  Option,
  Checkbox,
  CheckboxGroup,
  Switch,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Image,
  Carousel,
  Alert,
  CarouselItem,
  Upload,
  MessageBox,
  Message,
  Menu,
  MenuItem,
  Popover,
  Loading,
  Pagination,
  Tabs,
  TabPane,
  Link
} from "element-ui";
// import vElementTableHeader from "v-element-table-header";
import lang from "element-ui/lib/locale/lang/ru-RU";
import locale from "element-ui/lib/locale";

locale.use(lang);

Vue.use(Button);
Vue.use(Pagination);
Vue.use(Loading);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Icon);
Vue.use(Dialog);
Vue.use(Select);
Vue.use(Option);
Vue.use(Checkbox);
Vue.use(Switch);
Vue.use(Col);
Vue.use(Row);
Vue.use(DatePicker);
Vue.use(InputNumber);
Vue.use(Image);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Upload);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Popover);
Vue.use(CheckboxGroup);
Vue.use(Alert);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(DatePicker);
Vue.use(Link)
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$message = Message;
// Vue.use(vElementTableHeader);
//Vue.prototype.$confirm = MessageBox.confirm;
//Vue.prototype.$msgbox = MessageBox;
