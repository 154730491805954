<template>
  <div class="container">
    <el-form
      :model="FORM_PLATES()"
      label-width="auto"
      ref="plateForm"
      label-position="top"
      :rules="rules"
    >
      <div class="flex">
        <div class="info-car size-collum">
          <span style="font-weight: bold;">
            Сведения о транспортном средстве
          </span>
          <el-form-item
            size="mini"
            prop="plateTypeId"
            label="Государственный регистрационный знак"
          >
            <el-row>
              <el-col :span="12">
                <el-select
                  v-model="FORM_PLATES().plateTypeId"
                  placeholder="Выберите тип знака"
                  @change="changePlateType"
                >
                  <el-option
                    v-for="item in sortPlatesList"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="12">
                <el-form-item size="mini" prop="license_plate">
                  <el-input
                    :disabled="FORM_PLATES().plateTypeId ? false : true"
                    :placeholder="
                      FORM_PLATES().plateTypeId
                        ? PLATE_TYPES_LIST()[FORM_PLATES().plateTypeId - 1]
                            .placeholder
                        : 'Выберите тип знака'
                    "
                    @input="
                      FORM_PLATES().license_plate = FORM_PLATES().license_plate.toUpperCase()
                    "
                    v-mask="serviceNameMask"
                    v-model="FORM_PLATES().license_plate"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            size="mini"
            label="Серийный номер знака"
            prop="serial_number"
          >
            <el-input
              placeholder="Серийный номер"
              v-mask="serialNumMask"
              @input="
                FORM_PLATES().serial_number = FORM_PLATES().serial_number.toUpperCase()
              "
              v-model="FORM_PLATES().serial_number"
            >
            </el-input>
          </el-form-item>
          <el-form-item size="mini">
            <el-row>
              <el-col :span="12">
                <el-form-item
                  size="mini"
                  label="Дата изготовления"
                  prop="date_create"
                >
                  <el-input
                    v-model="FORM_PLATES().date_create"
                    placeholder="дд.мм.гггг"
                    v-mask="'##.##.####'"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item
                  size="mini"
                  label="Дата выдачи"
                  prop="date_issuing"
                >
                  <el-input
                    v-model="FORM_PLATES().date_issuing"
                    placeholder="дд.мм.гггг"
                    v-mask="'##.##.####'"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            size="mini"
            label="Документ идентифицирующий ТС"
            prop="typeDocumentTransportId"
          >
            <el-select
              style="width: 100%"
              v-model="FORM_PLATES().typeDocumentTransportId"
              placeholder="Выберите тип документа"
              @change="docTranId"
            >
              <el-option
                v-for="item in this.DOCUMENT_TYPES_LIST()"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-show="FORM_PLATES().typeDocumentTransportId"
            size="mini"
            label="temp"
          >
            <span slot="label"> Серия номер {{ docTrancTitle }} </span>
            <el-row>
              <el-col :span="12">
                <el-form-item size="mini" prop="document">
                  <el-input
                    :placeholder="
                      FORM_PLATES().typeDocumentTransportId
                        ? DOCUMENT_TYPES_LIST()[
                            FORM_PLATES().typeDocumentTransportId - 1
                          ].placeholder
                        : 'Выберите тип знака'
                    "
                    v-mask="serialDocMask"
                    @input="
                      FORM_PLATES().document = FORM_PLATES().document.toUpperCase()
                    "
                    v-model="FORM_PLATES().document"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item
                  size="mini"
                  prop="date_issuing_transport_document"
                >
                  <el-input
                    v-model="FORM_PLATES().date_issuing_transport_document"
                    placeholder="дд.мм.гггг"
                    v-mask="'##.##.####'"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item size="mini" label="Кем выдан" prop="issued_doc">
            <el-input
              placeholder="Кем выдан"
              v-mask="defMask"
              @input="
                FORM_PLATES().issued_doc = FORM_PLATES().issued_doc.toUpperCase()
              "
              v-model="FORM_PLATES().issued_doc"
            >
            </el-input>
          </el-form-item>
          <el-form-item size="mini" label="Количество пластин">
            <el-input-number
              v-model="FORM_PLATES().count_plate"
              :min="1"
              :max="2"
            ></el-input-number>
          </el-form-item>
          <el-form-item size="mini" label="Скан заявления" prop="scan">
            <el-upload
              class="upload-demo"
              ref="upload2"
              :limit="12"
              multiple
              accept=" .jpg, .jpeg, .png, .bmp, .heic"
              :file-list="FORM_PLATES().documents_statements"
              :auto-upload="false"
              :on-exceed="handleExceed"
              :on-change="beforeAvatarUpload"
              :on-remove="handleRemove"
              :on-preview="handlerPreview"
              action=""
            >
              <el-button slot="trigger" size="small" type="primary"
                >Выберите файл</el-button
              >

              <div class="el-upload__tip" slot="tip">
                jpg/jpeg/png/bmp/heic
              </div>
            </el-upload>
          </el-form-item>
          <el-image-viewer
            v-if="showViewer"
            :on-close="closeViewer"
            :url-list="arrImg"
            :initial-index="0"
          />
        </div>
        <div class="info-owner size-collum">
          <span style="font-weight: bold;"> Сведения о владельце ТС</span>
          <el-form-item size="mini" label="Тип владельца ТС" prop="ownerTypeId">
            <el-select
              style="width: 100%"
              v-model="FORM_PLATES().ownerTypeId"
              placeholder="Выберите тип владельца"
              @change="changeOwner"
            >
              <el-option
                v-for="item in this.OWNER_TYPES_LIST()"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div class="" v-if="FORM_PLATES().ownerTypeId == 1">
            <el-form-item
              size="mini"
              label="Тип документа удостоверяющего личность"
              prop="typeDocumentOwnerId"
            >
              <el-select
                style="width: 100%"
                v-model="FORM_PLATES().typeDocumentOwnerId"
                placeholder="Выберите тип документа"
                @change="typeDocChange"
              >
                <el-option
                  v-for="item in this.OWNER_TYPES_DOC_LIST()"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              size="mini"
              prop="individual_document_other_type"
              label="Тип прочих документов"
              v-if="FORM_PLATES().typeDocumentOwnerId == 2"
            >
              <el-input
                placeholder="Введите тип прочих документов"
                @input="
                  FORM_PLATES().individual_document_other_type = FORM_PLATES().individual_document_other_type.toUpperCase()
                "
                v-model="FORM_PLATES().individual_document_other_type"
              >
              </el-input>
            </el-form-item>
            <el-form-item size="mini">
              <el-row>
                <el-col :span="12">
                  <el-form-item
                    size="mini"
                    label="Серия, номер документа "
                    prop="individual_document_serial_number"
                  >
                    <el-input
                      v-mask="ownerSerMask"
                      :disabled="FORM_PLATES().typeDocumentOwnerId == null"
                      :placeholder="
                        FORM_PLATES().typeDocumentOwnerId == 1
                          ? '0000 000000'
                          : 'Серия, номер'
                      "
                      @input="
                        FORM_PLATES().individual_document_serial_number = FORM_PLATES().individual_document_serial_number.toUpperCase()
                      "
                      v-model="FORM_PLATES().individual_document_serial_number"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="Дата выдачи"
                    prop="individual_document_date_issuing"
                  >
                    <el-input
                      :disabled="FORM_PLATES().typeDocumentOwnerId == null"
                      v-model="FORM_PLATES().individual_document_date_issuing"
                      placeholder="дд.мм.гггг"
                      size="mini"
                      @input="
                        FORM_PLATES().individual_document_date_issuing = FORM_PLATES().individual_document_date_issuing.toUpperCase()
                      "
                      v-mask="'##.##.####'"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item
              size="mini"
              label="Кем выдан"
              prop="individual_document_issued_doc"
            >
              <el-input
                v-mask="defMask"
                placeholder="Кем выдан"
                @input="
                  FORM_PLATES().individual_document_issued_doc = FORM_PLATES().individual_document_issued_doc.toUpperCase()
                "
                v-model="FORM_PLATES().individual_document_issued_doc"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              size="mini"
              label="Фамилия"
              prop="individual_document_surname"
            >
              <el-input
                v-mask="defMask"
                @input="
                  FORM_PLATES().individual_document_surname = FORM_PLATES().individual_document_surname.toUpperCase()
                "
                placeholder="Введите фамилию"
                v-model="FORM_PLATES().individual_document_surname"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              size="mini"
              label="Имя"
              prop="individual_document_name"
            >
              <el-input
                placeholder="Введите имя"
                v-mask="defMask"
                @input="
                  FORM_PLATES().individual_document_name = FORM_PLATES().individual_document_name.toUpperCase()
                "
                v-model="FORM_PLATES().individual_document_name"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              size="mini"
              label="Отчество"
              prop="individual_document_patronymic"
            >
              <el-input
                placeholder="Введите отчество"
                v-mask="defMask"
                @input="
                  FORM_PLATES().individual_document_patronymic = FORM_PLATES().individual_document_patronymic.toUpperCase()
                "
                v-model="FORM_PLATES().individual_document_patronymic"
              >
              </el-input>
            </el-form-item>
          </div>
          <div class="" v-if="FORM_PLATES().ownerTypeId == 2">
            <el-form-item
              size="mini"
              label="Наименование юридического лица"
              prop="entity_document_name"
            >
              <el-input
                placeholder="Название"
                v-mask="defMask"
                @input="
                  FORM_PLATES().entity_document_name = FORM_PLATES().entity_document_name.toUpperCase()
                "
                v-model="FORM_PLATES().entity_document_name"
              >
              </el-input>
            </el-form-item>
            <el-form-item size="mini" label="ИНН" prop="entity_document_inn">
              <el-input
                placeholder="ИНН"
                v-mask="'############'"
                v-model="FORM_PLATES().entity_document_inn"
              >
              </el-input>
            </el-form-item>
            <el-form-item size="mini" label="ОГРН" prop="entity_document_orgn">
              <el-input
                placeholder="ОГРН"
                v-mask="'#############'"
                v-model="FORM_PLATES().entity_document_orgn"
              >
              </el-input>
            </el-form-item>
          </div>
          <el-form-item size="mini" label="Скан документа ТС" prop="scan2">
            <el-upload
              class="upload-demo"
              ref="upload3"
              :limit="12"
              multiple
              accept=" .jpg, .jpeg, .png, .bmp, .heic"
              :file-list="FORM_PLATES().documents_statement_tcs"
              :auto-upload="false"
              :on-exceed="handleExceed"
              :on-change="beforeAvatarUpload"
              :on-remove="handleRemove"
              :on-preview="handlerPreview"
              action=""
            >
              <el-button slot="trigger" size="small" type="primary"
                >Выберите файл</el-button
              >

              <div class="el-upload__tip" slot="tip">
                jpg/jpeg/png/bmp/heic
              </div>
            </el-upload>
          </el-form-item>
        </div>
        <div class="info-owner-member size-collum">
          <span style="font-weight: bold;">
            Сведения о представителя владельца ТС</span
          >
          <el-form-item
            size="mini"
            ref="test2123"
            label="Тип представителя ТС"
            prop="representative_type_owner_id"
          >
            <el-select
              style="width: 100%"
              clearable
              v-model="FORM_PLATES().representative_type_owner_id"
              placeholder="Выберите тип представителя"
              @change="changeOwnerRep"
              @clear="clearOwner"
            >
              <el-option
                v-for="item in this.OWNER_TYPES_LIST()"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div class="" v-if="FORM_PLATES().representative_type_owner_id == 1">
            <el-form-item
              size="mini"
              label="Тип документа удостоверяющего личность"
              v-if="FORM_PLATES().representative_type_owner_id == 1"
              prop="representative_document_type_ownerId"
            >
              <el-select
                style="width: 100%"
                v-model="FORM_PLATES().representative_document_type_ownerId"
                placeholder="Выберите тип документа"
                @change="typeDocChange2"
                v-if="FORM_PLATES().representative_type_owner_id == 1"
              >
                <el-option
                  v-for="item in this.OWNER_TYPES_DOC_LIST()"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              size="mini"
              label="Тип прочих документов"
              prop="representative_individual_document_other_type"
              v-if="FORM_PLATES().representative_document_type_ownerId == 2"
            >
              <el-input
                placeholder="Введите тип прочих документов"
                @input="
                  FORM_PLATES().representative_individual_document_other_type = FORM_PLATES().representative_individual_document_other_type.toUpperCase()
                "
                v-model="
                  FORM_PLATES().representative_individual_document_other_type
                "
              >
              </el-input>
            </el-form-item>

            <el-form-item size="mini">
              <el-row>
                <el-col :span="12">
                  <el-form-item
                    size="mini"
                    label="Серия, номер документа"
                    prop="representative_individual_document_serial_number"
                  >
                    <el-input
                      @input="
                        FORM_PLATES().representative_individual_document_serial_number = FORM_PLATES().representative_individual_document_serial_number.toUpperCase()
                      "
                      :placeholder="
                        FORM_PLATES().representative_document_type_ownerId == 1
                          ? '0000 000000'
                          : 'Серия, номер'
                      "
                      v-model="
                        FORM_PLATES()
                          .representative_individual_document_serial_number
                      "
                      v-mask="ownerSerMask2"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="Дата выдачи"
                    prop="representative_individual_document_date_issuing"
                  >
                    <el-input
                      v-model="
                        FORM_PLATES()
                          .representative_individual_document_date_issuing
                      "
                      placeholder="дд.мм.гггг"
                      size="mini"
                      v-mask="'##.##.####'"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item
              size="mini"
              label="Кем выдан"
              prop="representative_document_issued_doc"
            >
              <el-input
                @input="
                  FORM_PLATES().representative_document_issued_doc = FORM_PLATES().representative_document_issued_doc.toUpperCase()
                "
                placeholder="Кем выдан"
                v-mask="defMask"
                v-model="FORM_PLATES().representative_document_issued_doc"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              size="mini"
              label="Фамилия"
              prop="representative_individual_document_surname"
            >
              <el-input
                @input="
                  FORM_PLATES().representative_individual_document_surname = FORM_PLATES().representative_individual_document_surname.toUpperCase()
                "
                placeholder="Введите фамилия"
                v-mask="defMask"
                v-model="
                  FORM_PLATES().representative_individual_document_surname
                "
              >
              </el-input>
            </el-form-item>
            <el-form-item
              size="mini"
              label="Имя"
              prop="representative_individual_document_name"
            >
              <el-input
                @input="
                  FORM_PLATES().representative_individual_document_name = FORM_PLATES().representative_individual_document_name.toUpperCase()
                "
                v-mask="defMask"
                placeholder="Введите имя"
                v-model="FORM_PLATES().representative_individual_document_name"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              size="mini"
              label="Отчество"
              prop="representative_individual_document_patronymic"
            >
              <el-input
                @input="
                  FORM_PLATES().representative_individual_document_patronymic = FORM_PLATES().representative_individual_document_patronymic.toUpperCase()
                "
                v-mask="defMask"
                placeholder="Введите отчество"
                v-model="
                  FORM_PLATES().representative_individual_document_patronymic
                "
              >
              </el-input>
            </el-form-item>
          </div>
          <div class="" v-if="FORM_PLATES().representative_type_owner_id == 2">
            <el-form-item
              size="mini"
              label="Наименование юридического лица"
              prop="representative_entity_document_name"
            >
              <el-input
                @input="
                  FORM_PLATES().representative_entity_document_name = FORM_PLATES().representative_entity_document_name.toUpperCase()
                "
                placeholder="Введите данные"
                v-mask="defMask"
                v-model="FORM_PLATES().representative_entity_document_name"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              size="mini"
              label="ИНН"
              prop="representative_entity_document_inn"
            >
              <el-input
                placeholder="ИНН"
                v-mask="'############'"
                v-model="FORM_PLATES().representative_entity_document_inn"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              size="mini"
              label="ОГРН"
              prop="representative_entity_document_orgn"
            >
              <el-input
                placeholder="ОГРН"
                v-mask="'#############'"
                v-model="FORM_PLATES().representative_entity_document_orgn"
              >
              </el-input>
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>
    <span slot="footer" class="footer-dialog">
      <div v-if="newPlate" style="display: contents">
        <el-button type="danger" @click="closeModal">
          Закрыть
        </el-button>
        <el-button
          type="primary"
          @click="printDoc"
          icon="el-icon-printer"
          v-if="INFO_COMPANY().print_statement"
        >
          Печать заявления
        </el-button>
        <el-button @click="resetForm('plateForm')">Очистить</el-button>
        <el-button type="primary" @click="postPlate" :disabled="disableBth">
          Создать
        </el-button>
      </div>

      <div v-else style="display: contents">
        <el-button type="danger" @click="closeModal">
          Отменить
        </el-button>
        <el-popover
          v-if="
            Array.isArray(FORM_PLATES().info_edit_messages) &&
              this.FORM_PLATES().info_edit_messages.length
          "
          placement="top"
          title="Ошибка"
          width="400"
          trigger="click"
          :content="FORM_PLATES().info_edit_messages[0].message"
        >
          <el-button slot="reference" type="warning">Описание ошибки</el-button>
        </el-popover>
        <el-button
          type="primary"
          @click="printDoc"
          icon="el-icon-printer"
          v-if="INFO_COMPANY().print_statement"
        >
          Печать заявления
        </el-button>
        <!-- <el-button
          type="success"
          @click="putPlate"
          :disabled="FORM_PLATES().statusProductId == 2"
        >
          Сохранить
        </el-button> -->
      </div>
    </span>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ElImageViewer from "../../node_modules/element-ui/packages/image/src/image-viewer";
import Docxtemplater from "docxtemplater";
import { Message } from "element-ui";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";

import Moment from "moment";
function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}
// eslint-disable-next-line no-unused-vars
function replaceErrors(key, value) {
  if (value instanceof Error) {
    return Object.getOwnPropertyNames(value).reduce(function(error, key) {
      error[key] = value[key];
      return error;
    }, {});
  }
  return value;
}
export default {
  components: { ElImageViewer },
  props: {
    plateId: Number,
    newPlate: Boolean,
    statusId: Number
  },
  data() {
    let checkPlate = (rule, value, cb) => {
      if (!value) {
        return cb(new Error("Введите номер"));
      } else {
        let re = new RegExp(
          this.PLATE_TYPES_LIST()[this.FORM_PLATES().plateTypeId - 1].regexp
        );
        if (re.test(value)) {
          cb();
        } else cb(new Error("Неправильный формат ввода"));
      }
    };
    let checkSerialNumber = (rule, value, cb) => {
      if (!value) {
        cb();
      } else {
        let re = new RegExp("[А-ЯA-Z0-9]+?");
        if (re.test(value)) {
          cb();
        } else cb(new Error("Неправильный формат ввода"));
      }
    };
    let checkPtc = (rule, value, cb) => {
      if (!value) {
        return cb(new Error("Выберите тип документа"));
      } else {
        let re = new RegExp(
          this.DOCUMENT_TYPES_LIST()[
            this.FORM_PLATES().typeDocumentTransportId - 1
          ].regexp
        );
        if (re.test(value)) {
          cb();
        } else cb(new Error("Неправильный формат ввода"));
      }
    };
    let checkDoc1 = (rule, value, cb) => {
      if (this.$refs.upload2.uploadFiles.length > 0) {
        for (let i = 0; i < this.$refs.upload2.uploadFiles.length; i++) {
          if (this.$refs.upload2.uploadFiles[i].status != "success") {
            if (this.$refs.upload2.uploadFiles[i].size > 1048576 * 10) {
              return cb(new Error("Файл больше 10 МБ."));
            }
          }
        }
      }
      return cb();
    };
    let checkDocumentOwner = (rule, value, cb) => {
      if (this.FORM_PLATES().ownerTypeId == 1) {
        return cb();
      }
      if (this.FORM_PLATES().ownerTypeId == 2) {
        return cb();
      } else {
        return cb(new Error("Выберите тип владельца"));
      }
    };
    let checkDocOther = (rule, value, cb) => {
      if (
        this.FORM_PLATES().ownerTypeId == 2 ||
        this.FORM_PLATES().typeDocumentOwnerId == 1
      ) {
        return cb();
      }

      if (
        this.FORM_PLATES().typeDocumentOwnerId == 2 &&
        this.FORM_PLATES().individual_document_other_type
      ) {
        return cb();
      } else {
        return cb(new Error("Проверьте данные"));
      }
    };
    let checkSerialNumberDoc = (rule, value, cb) => {
      if (this.FORM_PLATES().ownerTypeId == 2) {
        return cb();
      }
      if (
        this.FORM_PLATES().individual_document_serial_number &&
        this.FORM_PLATES().typeDocumentOwnerId == 2
      ) {
        return cb();
      }
      const re = /[0-9]{4}\s[0-9]{6}\s*/;
      if (
        this.FORM_PLATES().individual_document_serial_number &&
        re.test(this.FORM_PLATES().individual_document_serial_number)
      ) {
        return cb();
      } else {
        return cb(new Error("Проверьте данные"));
      }
    };
    let checkdocDate = (rule, value, cb) => {
      if (this.FORM_PLATES().ownerTypeId == 2) {
        return cb();
      }
      if (this.FORM_PLATES().individual_document_date_issuing) {
        return cb();
      } else {
        return cb(new Error("Выберите дату"));
      }
    };
    let checkSurname = (rule, value, cb) => {
      if (this.FORM_PLATES().ownerTypeId == 2) {
        return cb();
      }
      if (this.FORM_PLATES().individual_document_surname) {
        return cb();
      } else {
        return cb(new Error("Проверьте данные"));
      }
    };
    let checkName = (rule, value, cb) => {
      if (this.FORM_PLATES().ownerTypeId == 2) {
        return cb();
      }
      if (this.FORM_PLATES().individual_document_name) {
        return cb();
      } else {
        return cb(new Error("Проверьте данные"));
      }
    };
    let checkPatronymic = (rule, value, cb) => {
      if (this.FORM_PLATES().ownerTypeId == 2) {
        return cb();
      }
      if (this.FORM_PLATES().individual_document_patronymic) {
        return cb();
      } else {
        return cb(new Error("Проверьте данные"));
      }
    };
    let checkDoc2 = (rule, value, cb) => {
      if (this.$refs.upload3.uploadFiles.length > 0) {
        for (let i = 0; i < this.$refs.upload3.uploadFiles.length; i++) {
          if (this.$refs.upload3.uploadFiles[i].status != "success") {
            if (this.$refs.upload3.uploadFiles[i].size > 1048576 * 10) {
              return cb(new Error("Файл больше 10 МБ."));
            }
          }
        }
      }
      return cb();
    };
    let checkNameComp = (rule, value, cb) => {
      if (this.FORM_PLATES().ownerTypeId == 1) {
        return cb();
      }
      if (this.FORM_PLATES().entity_document_name) {
        return cb();
      } else {
        return cb(new Error("Проверьте данные"));
      }
    };
    let checkInn = (rule, value, cb) => {
      if (this.FORM_PLATES().ownerTypeId == 1) {
        return cb();
      }
      let re = /[0-9]+/;
      if (
        this.FORM_PLATES().entity_document_inn &&
        re.test(this.FORM_PLATES().entity_document_inn)
      ) {
        return cb();
      } else {
        return cb(new Error("Проверьте данные"));
      }
    };
    let checkOrgn = (rule, value, cb) => {
      if (this.FORM_PLATES().ownerTypeId == 1) {
        return cb();
      }
      let re = /[0-9]+/;
      if (
        this.FORM_PLATES().entity_document_orgn &&
        re.test(this.FORM_PLATES().entity_document_orgn)
      ) {
        return cb();
      } else {
        return cb(new Error("Проверьте данные"));
      }
    };
    let checkTypeOwner = (rule, value, cb) => {
      if (
        rule.required &&
        this.FORM_PLATES().representative_type_owner_id == 2
      ) {
        return cb(new Error("Представитель компании должен быть физ. лицо."));
      } else return cb();
    };
    let checkDate_create = (rule, value, cb) => {
      if (this.FORM_PLATES().date_create == null) {
        return cb(new Error("Введите дату"));
      }
      const date =
        new Moment(this.FORM_PLATES().date_issuing, "DD.MM.YYYY").format(
          "YYYYMMDD"
        ) -
        new Moment(this.FORM_PLATES().date_create, "DD.MM.YYYY").format(
          "YYYYMMDD"
        );
      if (date >= 0) {
        return cb();
      } else {
        return cb(new Error("Дата изготовления позже даты выдачи"));
      }
    };
    let checkDate_issuing = (rule, value, cb) => {
      if (this.FORM_PLATES().date_issuing == null) {
        return cb(new Error("Введите дату"));
      }
      const date =
        new Moment(this.FORM_PLATES().date_issuing, "DD.MM.YYYY").format(
          "YYYYMMDD"
        ) -
        new Moment(this.FORM_PLATES().date_create, "DD.MM.YYYY").format(
          "YYYYMMDD"
        );
      if (date >= 0) {
        return cb();
      } else {
        return cb(new Error("Дата выдачи раньше даты изготовления"));
      }
    };
    return {
      serviceNameMask: "A.##",

      serialNumMask: "DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD",
      serialDocMask: "DD DD ######",
      serialDocMask3: "DDDDDDDDDDDDDD",
      ownerSerMask: "#### ######",
      ownerSerMask2: "#### ######",
      defMask:
        "DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD",

      checked: false,
      showViewer: false,
      form: {
        license_plate: null,
        serial_number: null,
        date_create: null,
        date_issuing: null,
        count_plate: 1,
        document: null,
        date_issuing_transport_document: null,
        individual_document_other_type: null,
        individual_document_surname: null,
        individual_document_name: null,
        individual_document_patronymic: null,
        individual_document_serial_number: null,
        individual_document_date_issuing: null,
        entity_document_name: null,
        entity_document_inn: null,
        entity_document_orgn: null,
        representative_type_owner_id: null,
        representative_individual_document_other_type: null,
        representative_individual_document_surname: null,
        representative_individual_document_name: null,
        representative_individual_document_patronymic: null,
        representative_individual_document_serial_number: null,
        representative_individual_document_date_issuing: null,
        representative_entity_document_name: null,
        representative_entity_document_inn: null,
        representative_entity_document_orgn: null,
        representative_document_issued_doc: null,
        representative_document_type_ownerId: null,
        document_statement: null,
        issued_doc: null,
        document_statement_tc: null,
        typeDocumentOwnerId: null,
        companyId: null,
        plateTypeId: null,
        individual_document_issued_doc: null,
        typeDocumentTransportId: null,
        ownerTypeId: null
      },
      docTrancTitle: "",
      fileList: [],
      disableBth: false,
      sortPlatesList: [],
      fileList2: null,
      arrImg: null,
      rules: {
        plateTypeId: [
          { required: true, message: "Выберите документ", trigger: "blur" }
        ],
        license_plate: [{ validator: checkPlate, trigger: "blur" }],
        serial_number: [
          {
            validator: checkSerialNumber,
            trigger: "change",
            required: false
          }
        ],
        date_create: [
          {
            validator: checkDate_create,
            required: true,
            trigger: "blur"
          }
        ],
        date_issuing: [
          {
            validator: checkDate_issuing,
            required: true,

            trigger: "blur"
          }
        ],
        typeDocumentTransportId: [
          { required: true, message: "Выберите тип знака", trigger: "blur" }
        ],
         issued_doc: [
          { required: true, message: "Введите данные", trigger: "blur" }
        ],
        document: [{ validator: checkPtc, trigger: "blur", required: true }],
        date_issuing_transport_document: [
          {
            required: true,
            message: "Выберите дату",
            trigger: "blur"
          }
        ],
        scan: [{ validator: checkDoc1, trigger: "change", required: true }],
        ownerTypeId: [
          { required: true, message: "Выберите тип владельца", trigger: "blur" }
        ],
        typeDocumentOwnerId: [
          { required: true, validator: checkDocumentOwner, trigger: "blur" }
        ],
        individual_document_other_type: [
          { required: true, validator: checkDocOther, trigger: "blur" }
        ],
        individual_document_serial_number: [
          { required: true, validator: checkSerialNumberDoc, trigger: "blur" }
        ],
        individual_document_date_issuing: [
          { required: true, validator: checkdocDate, trigger: "blur" }
        ],
        individual_document_surname: [
          { required: true, validator: checkSurname, trigger: "blur" }
        ],
        individual_document_name: [
          { required: true, validator: checkName, trigger: "blur" }
        ],
        individual_document_patronymic: [
          { required: true, validator: checkPatronymic, trigger: "blur" }
        ],
        scan2: [{ validator: checkDoc2, trigger: "blur", required: true }],
        entity_document_name: [
          { validator: checkNameComp, trigger: "blur", required: true }
        ],
        entity_document_inn: [
          { validator: checkInn, trigger: "blur", required: true }
        ],
        entity_document_orgn: [
          { validator: checkOrgn, trigger: "blur", required: true }
        ],

        representative_type_owner_id: [
          { validator: checkTypeOwner, trigger: "blur", required: false }
        ]
      }
    };
  },
  methods: {
    ...mapActions([
      "GET_DOCUMENT_TYPES",
      "GET_OWNER_TYPES",
      "GET_PLATE_TYPES",
      "GET_OWNER_DOCUMENT_TYPES",
      "POST_PLATES",
      "SET_FORM_PLATES",
      "PUT_PLATES",
      "GET_PLATES_COUNT",
      "GET_PLATES_ALL",
      "DEL_FILE",
      "DEL_FILE2"
    ]),
    ...mapGetters([
      "OWNER_TYPES_LIST",
      "DOCUMENT_TYPES_LIST",
      "PLATE_TYPES_LIST",
      "OWNER_TYPES_DOC_LIST",
      "PLATES",
      "FORM_PLATES",
      "INFO_COMPANY"
    ]),
    closeModal() {
      this.$emit("updDialog");
      this.$refs["plateForm"].resetFields();
      this.$refs["upload2"].clearFiles();
      this.$refs["upload3"].clearFiles();
      this.SET_FORM_PLATES(this.form);
    },
    docTranId(value) {
      this.docTrancTitle = this.DOCUMENT_TYPES_LIST()[
        this.FORM_PLATES().typeDocumentTransportId - 1
      ].title;
      this.serialDocMask = this.DOCUMENT_TYPES_LIST()[value - 1].mask;
      this.FORM_PLATES().document = "";
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    changeOwner(val) {
      this.FORM_PLATES().typeDocumentOwnerId = null;
      val == 1
        ? (this.rules.representative_type_owner_id[0].required = false)
        : (this.rules.representative_type_owner_id[0].required = true);
    },
    changeOwnerRep() {
      this.FORM_PLATES().representative_individual_document_other_type = null;
      this.FORM_PLATES().representative_individual_document_surname = null;
      this.FORM_PLATES().representative_individual_document_name = null;
      this.FORM_PLATES().representative_individual_document_patronymic = null;
      this.FORM_PLATES().representative_individual_document_serial_number = null;
      this.FORM_PLATES().representative_individual_document_date_issuing = null;
      this.FORM_PLATES().representative_entity_document_name = null;
      this.FORM_PLATES().representative_entity_document_inn = null;
      this.FORM_PLATES().representative_entity_document_orgn = null;
      this.FORM_PLATES().representative_document_issued_doc = null;
      this.FORM_PLATES().representative_document_type_ownerId = null;
    },
    typeDocChange() {
      this.ownerSerMask =
        this.FORM_PLATES().typeDocumentOwnerId == 1
          ? "#### ######"
          : "DDDDDDDDDDDDDDDDDDDDDDDD";
      this.FORM_PLATES().individual_document_serial_number = "";
    },
    typeDocChange2() {
      this.ownerSerMask2 =
        this.FORM_PLATES().representative_document_type_ownerId == 1
          ? "#### ######"
          : "DDDDDDDDDDDDDDDDDDDDDDDD";
      this.FORM_PLATES().representative_individual_document_serial_number = "";
    },
    changeRep() {
      this.FORM_PLATES().typeDocumentOwnerId = null;
    },
    changePlateType(value) {
      this.FORM_PLATES().license_plate = "";
      this.serviceNameMask = this.PLATE_TYPES_LIST()[value - 1].mask;
    },
    postPlate() {
      this.$refs.plateForm.validate(async valid => {
        if (valid) {
          this.disableBth = true;
          let formData = new FormData();
          for (let i = 0; i < this.$refs.upload2.uploadFiles.length; i++) {
            formData.append(
              `document_statement${i}`,
              this.$refs.upload2.uploadFiles[i].raw
            );
          }
          for (let i = 0; i < this.$refs.upload3.uploadFiles.length; i++) {
            formData.append(
              `document_statement_tc${i}`,
              this.$refs.upload3.uploadFiles[i].raw
            );
          }
          await formData.append("data", JSON.stringify(this.FORM_PLATES()));
          this.disableBth = false;
          this.$emit("updDialog");
          await this.POST_PLATES(formData);

          this.$refs["plateForm"].resetFields();
          this.$refs["upload2"].clearFiles();
          this.$refs["upload3"].clearFiles();
          this.SET_FORM_PLATES(this.form);
        }
      });
    },
    clearOwner() {
      this.FORM_PLATES().representative_type_owner_id = null;
      this.FORM_PLATES().representative_individual_document_other_type = null;
      this.FORM_PLATES().representative_individual_document_surname = null;
      this.FORM_PLATES().representative_individual_document_name = null;
      this.FORM_PLATES().representative_individual_document_patronymic = null;
      this.FORM_PLATES().representative_individual_document_serial_number = null;
      this.FORM_PLATES().representative_individual_document_date_issuing = null;
      this.FORM_PLATES().representative_entity_document_name = null;
      this.FORM_PLATES().representative_entity_document_inn = null;
      this.FORM_PLATES().representative_entity_document_orgn = null;
      this.FORM_PLATES().representative_document_issued_doc = null;
      this.FORM_PLATES().representative_document_type_ownerId = null;
    },

    excelName() {
      if (this.FORM_PLATES().representative_type_owner_id == null) {
        return this.FORM_PLATES().ownerTypeId == 1
          ? this.FORM_PLATES().individual_document_surname.trim() +
              " " +
              this.FORM_PLATES().individual_document_name.trim() +
              " " +
              this.FORM_PLATES().individual_document_patronymic.trim()
          : this.FORM_PLATES().entity_document_name.trim();
      } else {
        return this.FORM_PLATES().representative_type_owner_id == 1
          ? this.FORM_PLATES().representative_individual_document_surname.trim() +
              " " +
              this.FORM_PLATES().representative_individual_document_name.trim() +
              " " +
              this.FORM_PLATES().representative_individual_document_patronymic.trim()
          : this.FORM_PLATES().representative_entity_document_name.trim();
      }
    },
    printDoc() {
      loadFile(
        "https://gibdd.znakvlg.ru/data/exp/test2.docx",
        (error, content) => {
          if (error) {
            throw error;
          }
          let zip = new PizZip(content);
          let doc = new Docxtemplater().loadZip(zip);
          
          let signature = this.excelName().split(' '); 
          let signatureResult = signature[0] + ' ' + signature[1][0].toUpperCase() + '. ' + signature[2][0].toUpperCase() + '.';

          doc.setData({
            signature: signatureResult,
            FIO: this.excelName(),
            license_plate: this.FORM_PLATES().license_plate,
            count: this.FORM_PLATES().count_plate,
            doctype1:
              this.FORM_PLATES().ownerTypeId == 1
                ? this.FORM_PLATES().typeDocumentOwnerId == 1
                  ? this.OWNER_TYPES_DOC_LIST()[0].title
                  : this.FORM_PLATES().individual_document_other_type
                : "",
            doctype2:
              this.FORM_PLATES().representative_type_owner_id == 1
                ? this.FORM_PLATES().representative_document_type_ownerId == 1
                  ? this.OWNER_TYPES_DOC_LIST()[0].title
                  : this.FORM_PLATES()
                      .representative_individual_document_other_type
                : "",
            document:
              this.DOCUMENT_TYPES_LIST()[
                this.FORM_PLATES().typeDocumentTransportId - 1
              ].title +
              " " +
              this.FORM_PLATES().document,
            issued_doc: this.FORM_PLATES().issued_doc
              ? this.FORM_PLATES().issued_doc
              : "",
            date_issuing: this.FORM_PLATES().date_issuing_transport_document,
            individual_doc_name:
              this.FORM_PLATES().ownerTypeId == 1
                ? this.FORM_PLATES().individual_document_surname +
                  " " +
                  this.FORM_PLATES().individual_document_name +
                  " " +
                  this.FORM_PLATES().individual_document_patronymic
                : this.FORM_PLATES().entity_document_name,
            serial_number:
              this.FORM_PLATES().ownerTypeId == 1
                ? this.FORM_PLATES().individual_document_serial_number
                : "",
            individual_doc_issued:
              this.FORM_PLATES().ownerTypeId == 1
                ? this.FORM_PLATES().individual_document_issued_doc
                  ? this.FORM_PLATES().individual_document_issued_doc
                  : ""
                : "",
            individual_doc_date_issiung:
              this.FORM_PLATES().ownerTypeId == 1
                ? this.FORM_PLATES().individual_document_date_issuing
                : "",
            representative_doc_name:
              this.FORM_PLATES().representative_type_owner_id != null
                ? this.FORM_PLATES().representative_type_owner_id == 1
                  ? this.FORM_PLATES()
                      .representative_individual_document_surname +
                    " " +
                    this.FORM_PLATES().representative_individual_document_name +
                    " " +
                    this.FORM_PLATES()
                      .representative_individual_document_patronymic
                  : this.FORM_PLATES().representative_entity_document_name
                : "",
            serial_number2:
              this.FORM_PLATES().representative_type_owner_id == 1
                ? this.FORM_PLATES()
                    .representative_individual_document_serial_number
                : "",
            representative_doc_issued:
              this.FORM_PLATES().representative_type_owner_id == 1
                ? this.FORM_PLATES().representative_document_issued_doc
                  ? this.FORM_PLATES().representative_document_issued_doc
                  : ""
                : "",
            representative_doc_date_issiung:
              this.FORM_PLATES().representative_type_owner_id == 1
                ? this.FORM_PLATES()
                    .representative_individual_document_date_issuing
                : "",
            date: this.FORM_PLATES().date_issuing,
            person: this.INFO_COMPANY().responsible_person,
            FIO_OBR: this.obr1(),
            PAS_OBR: this.obr2(),
            PAS2_OBR: this.obr3(),
            head_company: this.INFO_COMPANY().header_doc_bool
              ? this.INFO_COMPANY().head_other
              : 'ООО "Знак К"'
          });
          try {
            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render();
          } catch (error) {
            // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).

            if (error.properties && error.properties.errors instanceof Array) {
              // errorMessages is a humanly readable message looking like this :
              // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
          }
          let out = doc.getZip().generate({
            type: "blob",
            mimeType:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          }); //Output the document using Data-URI
          saveAs(out, "Заявление.docx");
        }
      );
    },
    obr1() {
      if (this.FORM_PLATES().ownerTypeId == 1) {
        return (
          this.FORM_PLATES().individual_document_surname +
          " " +
          this.FORM_PLATES().individual_document_name +
          " " +
          this.FORM_PLATES().individual_document_patronymic
        );
      }
      if (this.FORM_PLATES().ownerTypeId == 2) {
        return (
          this.FORM_PLATES().representative_individual_document_surname +
          " " +
          this.FORM_PLATES().representative_individual_document_name +
          " " +
          this.FORM_PLATES().representative_individual_document_patronymic
        );
      }
    },
    obr2() {
      if (this.FORM_PLATES().ownerTypeId == 1) {
        return this.FORM_PLATES().individual_document_serial_number
          ? this.FORM_PLATES().individual_document_serial_number
          : "";
      }
      if (this.FORM_PLATES().ownerTypeId == 2) {
        return this.FORM_PLATES()
          .representative_individual_document_serial_number
          ? this.FORM_PLATES().representative_individual_document_serial_number
          : "";
      }
    },
    obr3() {
      if (this.FORM_PLATES().ownerTypeId == 1) {
        return this.FORM_PLATES().individual_document_date_issuing
          ? this.FORM_PLATES().individual_document_date_issuing
          : "" + " " + this.FORM_PLATES().individual_document_issued_doc
          ? this.FORM_PLATES().individual_document_issued_doc
          : "";
      }
      if (this.FORM_PLATES().ownerTypeId == 2) {
        return this.FORM_PLATES()
          .representative_individual_document_date_issuing
          ? this.FORM_PLATES().representative_individual_document_date_issuing +
              " "
          : "" + this.FORM_PLATES().representative_document_issued_doc
          ? this.FORM_PLATES().representative_document_issued_doc
          : "";
      }
    },
    handleExceed() {
      Message.warning(
        `Лимит файлов 12. Чтоб загрузить новый файл, удалите один из загруженных.`
      );
    },
    closeViewer() {
      this.showViewer = false;
    },
    handleRemove(file) {
      if (this.newPlate) {
        for (let i = 0; i < this.$refs.upload2.uploadFiles.length; i++) {
          if (this.$refs.upload2.uploadFiles[i].uid == file.uid) {
            this.$refs.upload2.uploadFiles.splice(i, 1);
          }
        }
        for (let i = 0; i < this.$refs.upload3.uploadFiles.length; i++) {
          if (this.$refs.upload3.uploadFiles[i].uid == file.uid) {
            this.$refs.upload3.uploadFiles.splice(i, 1);
          }
        }
      } else {
        for (
          let i = 0;
          i < this.FORM_PLATES().documents_statements.length;
          i++
        ) {
          if (this.FORM_PLATES().documents_statements[i].uid == file.uid) {
            this.DEL_FILE(i);
          }
        }

        for (
          let i = 0;
          i < this.FORM_PLATES().documents_statement_tcs.length;
          i++
        ) {
          if (this.FORM_PLATES().documents_statement_tcs[i].uid == file.uid) {
            this.DEL_FILE2(i);
          }
        }
      }
    },
    beforeAvatarUpload(file) {
      const isPhoto =
        file.raw.type === "image/jpg" ||
        file.raw.type === "image/bmp" ||
        file.raw.type === "image/png" ||
        file.raw.type === "image/jpeg" ||
        file.raw.type === "image/heic";

      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isPhoto) {
        Message.error("Не тот формат");
        for (let i = 0; i < this.$refs.upload2.uploadFiles.length; i++) {
          if (this.$refs.upload2.uploadFiles[i].uid == file.uid) {
            this.$refs.upload2.uploadFiles.splice(i, 1);
          }
        }
        for (let i = 0; i < this.$refs.upload3.uploadFiles.length; i++) {
          if (this.$refs.upload3.uploadFiles[i].uid == file.uid) {
            this.$refs.upload3.uploadFiles.splice(i, 1);
          }
        }
      }
      if (!isLt2M) {
        this.$alert("Файл больше 10 МБ", "Ошибка", {
          confirmButtonText: "OK",
          type: "error"
        });
        for (let i = 0; i < this.$refs.upload2.uploadFiles.length; i++) {
          if (this.$refs.upload2.uploadFiles[i].uid == file.uid) {
            this.$refs.upload2.uploadFiles.splice(i, 1);
          }
        }
        for (let i = 0; i < this.$refs.upload3.uploadFiles.length; i++) {
          if (this.$refs.upload3.uploadFiles[i].uid == file.uid) {
            this.$refs.upload3.uploadFiles.splice(i, 1);
          }
        }
      }

      return isPhoto && isLt2M;
    },
    handlerPreview(file) {
      if (file.status == "success") {
        this.arrImg = ["/" + file.url];
        this.showViewer = true;
      }
    },
    dateNow() {
      let date = new Moment().subtract(1, "hours").format("DD.MM.YYYY");
      this.FORM_PLATES().date_create = date;
      this.FORM_PLATES().date_issuing = date;
    }
  },
  watch: {
    plateId: function(val) {
      if (val && this.statusId == 0) {
        this.PLATES().forEach(el => {
          if (el.id == this.plateId && el.statusProductId != undefined) {
            this.SET_FORM_PLATES(Object.assign({}, el));
            this.serviceNameMask = this.PLATE_TYPES_LIST()[
              this.FORM_PLATES().plateTypeId - 1
            ].mask;
          }
        });
      } else {
        this.SET_FORM_PLATES(this.form);
        this.FORM_PLATES().companyId = this.$route.params.id;
      }
    }
  },
  created: function() {
    if (this.OWNER_TYPES_DOC_LIST().length == 0) {
      this.GET_DOCUMENT_TYPES();
      this.GET_OWNER_TYPES();
      this.GET_PLATE_TYPES();
      this.GET_OWNER_DOCUMENT_TYPES();
    }
    if (this.plateId && this.statusId == 0) {
      this.PLATES().forEach(el => {
        if (el.id == this.plateId && el.statusProductId != undefined) {
          this.SET_FORM_PLATES(Object.assign({}, el));
          this.serviceNameMask = this.PLATE_TYPES_LIST()[
            this.FORM_PLATES().plateTypeId - 1
          ].mask;
        }
      });
    } else {
      this.SET_FORM_PLATES(this.form);
      this.FORM_PLATES().companyId = this.$route.params.id;
      this.dateNow();
    }
    this.sortPlatesList.push(this.PLATE_TYPES_LIST()[0]);
    this.sortPlatesList.push(this.PLATE_TYPES_LIST()[1]);
    this.sortPlatesList.push(this.PLATE_TYPES_LIST()[2]);
    this.sortPlatesList.push(this.PLATE_TYPES_LIST()[3]);
    this.sortPlatesList.push(this.PLATE_TYPES_LIST()[11]);
    this.sortPlatesList.push(this.PLATE_TYPES_LIST()[12]);
    this.sortPlatesList.push(this.PLATE_TYPES_LIST()[4]);
    this.sortPlatesList.push(this.PLATE_TYPES_LIST()[5]);
    this.sortPlatesList.push(this.PLATE_TYPES_LIST()[6]);
    this.sortPlatesList.push(this.PLATE_TYPES_LIST()[7]);
    this.sortPlatesList.push(this.PLATE_TYPES_LIST()[8]);
    this.sortPlatesList.push(this.PLATE_TYPES_LIST()[9]);
    this.sortPlatesList.push(this.PLATE_TYPES_LIST()[10]);
  }
};
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-right: 10px;
}
.info-car:last-child {
  margin-top: 10px;
}
.size-collum {
  min-width: 30%;
}
.el-form-item {
  margin-bottom: 0px;
}
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 10px;
}
.dialog-footer {
  margin-top: 10px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 200px;
}
.footer-dialog {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-upload-dragger {
  background-color: black;
}
</style>
