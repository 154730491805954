import axios from "axios";
import jwt_decoder from "jwt-decode";
const getDefaultState = () => ({
  jwt: localStorage.getItem("jwtToken") || "",
  login: String,
  role: String,
  flat_plates: Boolean,
  flat_plates2: Boolean,
  flat_plates3: Boolean,
  flat_plates4: Boolean,
  report_perm: Boolean
});

// initial state
const state = getDefaultState();

const getters = {
  JWTTOKEN(state) {
    return state.jwt;
  },
  USERNAME(state) {
    return state.login;
  },
  USERROLE(state) {
    return state.role;
  },
  FLATPLATE(state) {
    return state.flat_plates;
  },
  FLATPLATE2(state) {
    return state.flat_plates2;
  },
  FLATPLATE3(state) {
    return state.flat_plates2;
  },
  FLATPLATE4(state) {
    return state.flat_plates4;
  },
  REPORT_PERM(state) {
    return state.report_perm;
  }
};

const actions = {
  RESET_AUTH: async ({ commit }) => {
    commit("resetState");
  },
  GET_JWT_TOKEN_FROM_API: async ({ commit }) => {
    let data = axios.post("/api/auth/refresh-token/");
    commit("SET_JWT_TOKEN", data.token);
  },
  GET_JWT_TOKEN_FROM_LOCAL: async ({ commit }) => {
    const jwt = localStorage.getItem("jwt");

    if (jwt) {
      commit("SET_JWT_TOKEN", jwt);
    }
  },
  GET_NAME_USER: async ({ commit, state }) => {
    commit("SET_NAME_USER", state.jwt);
  },
  // eslint-disable-next-line no-unused-vars
  LOGIN_FROM_API: async ({ commit }, loginData) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/auth/login/", loginData)
        .then(res => {
          const token = res.data.token;
          axios.defaults.headers.common["Authorization"] = token;
          commit("SET_JWT_TOKEN", token);
          resolve();
        })
        .catch(e => {
          reject();
          console.log(e);
        });
    });
  },
  LOGOUT_FROM_API: async ({ commit }) => {
    localStorage.removeItem("jwtToken");
    commit("DEL_JWT_TOKEN");
    delete axios.defaults.headers.common["Authorization"];
  }
};

const mutations = {
  SET_JWT_TOKEN: (state, jwt) => {
    localStorage.setItem("jwtToken", jwt);
    state.login = jwt_decoder(jwt).login;
    state.role = jwt_decoder(jwt).userRole;
    state.flat_plates = jwt_decoder(jwt).flat_plates;
    state.flat_plates2 = jwt_decoder(jwt).flat_plates2;
    state.flat_plates3 = jwt_decoder(jwt).flat_plates3;
    state.flat_plates4 = jwt_decoder(jwt).flat_plates4;
    state.report_perm = jwt_decoder(jwt).report_perm;
    state.jwt = jwt;
  },
  DEL_JWT_TOKEN: state => {
    state.jwt = "";
  },
  SET_NAME_USER: (state, data) => {
    state.login = jwt_decoder(data).login;
    state.role = jwt_decoder(data).userRole;
    state.flat_plates = jwt_decoder(data).flat_plates;
    state.flat_plates2 = jwt_decoder(data).flat_plates2;
    state.flat_plates3 = jwt_decoder(data).flat_plates3;
    state.flat_plates4 = jwt_decoder(data).flat_plates4;
    state.report_perm = jwt_decoder(data).report_perm;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
