<template>
  <div class="container ">
    <div class="head-table">
      <TabsMenu :is-admin="true" />
      <div class="">
        <el-button
          v-show="multipleSelection.length > 0"
          icon="el-icon-minus"
          type="danger"
          size="mini"
          @click.stop="deleteUsers"
        >
          Удалить
        </el-button>
        <el-button
          type="danger"
          size="mini"
          icon="el-icon-plus"
          @click.stop="openDialog()"
          >Добавить</el-button
        >
      </div>
    </div>
    <div class="table-company">
      <el-table
        stripe
        ref="table"
        size="mini"
        highlight-current-row
        height="100%"
        @cell-click="highlightRow"
        @cell-dblclick="goToUsersPageDb"
        style="width: 100%;  "
        v-if="Array.isArray(this.USERS()) && this.USERS().length"
        :data="this.USERS()"
        @selection-change="handleSelectionChange"
      >
        >
        <el-table-column label="№" width="50" type="index"> </el-table-column>
        <el-table-column width="50" type="selection"> </el-table-column>
        <el-table-column
          width="300"
          property="company.title"
          label="Название предприятия"
        ></el-table-column>
        <el-table-column label="ФИО">
          <template slot-scope="scope">
            {{
              scope.row.users_info.surname +
                " " +
                scope.row.users_info.name +
                " " +
                scope.row.users_info.patronymic
            }}
          </template>
        </el-table-column>
        <el-table-column prop="email" label="Роль">
          <template slot-scope="scope">
            {{ test(scope.row.roleId) }}
          </template>
        </el-table-column>
        <el-table-column prop="email" label="Почта"> </el-table-column>
        <el-table-column prop="name" label="Контактные данные">
          <template slot-scope="scope">
            {{ scope.row.users_info.phone_number }} <br />
            {{ scope.row.users_info.adress }}
          </template>
        </el-table-column>
        <el-table-column width="50">
          <template slot-scope="scope">
            <el-button
              icon="el-icon-s-tools"
              type="text"
              style="color: #cc0000;"
              @click.stop="goToUsersPage(scope.row.id)"
            >
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :modalAppendToBody="false"
      title="Создать пользователя"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="600px"
    >
      <UsersForm v-bind:user-id="id" v-on:updDialog="handleClose()" />
    </el-dialog>
  </div>
</template>

<script>
import UsersForm from "./UsersForm";
import TabsMenu from "./TabsMenu";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    UsersForm,
    TabsMenu
  },
  data() {
    return {
      multipleSelection: [],
      dialogFormVisible: false,
      id: null,
      timeoutId: null
    };
  },
  methods: {
    ...mapActions([
      "GET_LIST_USERS",
      "GET_LIST_USERS_COMPANY",
      "GET_USERS_TYPE",
      "GET_COMPANY",
      "RESET_HEAD",
      "SET_SCROLL",
      "RESET_SCROLL",
      "SET_ROWINDEX",
      "SET_PAGENUMBER"
    ]),
    ...mapGetters(["USERS", "ROLES", "INDEX", "ROWINDEX", "SCROLLTOP"]),
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleClose() {
      this.dialogFormVisible = false;
    },
    openDialog() {
      this.id = null;
      this.dialogFormVisible = true;
    },
    goToUsersPage(id) {
      this.id = id;
      this.dialogFormVisible = true;
    },
    goToUsersPageDb(row) {
      clearTimeout(this.timeoutId);
      this.id = row.id;
      this.dialogFormVisible = true;
    },
    test(id) {
      return this.ROLES()[id - 1].role;
    },
    deleteUsers() {
      let arr = [];
      this.multipleSelection.forEach(e => {
        arr.push(e.id);
      });

      axios.post("/api/auth/delete/", { usersId: arr }).then(() => {
        this.GET_LIST_USERS();
      });
    },
    setCurrent(row) {
      this.$refs.table.setCurrentRow(row);
    },
    highlightRow(row) {
      if (!this.timeoutId) {
        this.timeoutId = setTimeout(() => {
          if (this.currentRow == row) {
            this.currentRow = null;
            this.RESET_SCROLL();

            return this.setCurrent();
          }
          this.setCurrent(row);
          this.currentRow = row;
          this.SET_SCROLL({
            name: this.$route.name,
            top: this.$refs["table"].bodyWrapper.scrollTop
          });
          this.SET_ROWINDEX(row.id);
          this.timeoutId = null;
        }, 500);
      }
    }
  },
  created: async function() {
    this.RESET_HEAD("Список пользователей");
    await this.GET_USERS_TYPE();
    await this.GET_COMPANY();

    if (this.$route.params.id) {
      await this.GET_LIST_USERS_COMPANY(this.$route.params.id);
    } else {
      await this.GET_LIST_USERS();
    }
  },
  updated: function() {
    if (this.$refs["table"] && this.SCROLLTOP()(this.$route.name) > 0) {
      this.$refs["table"].bodyWrapper.scrollTop = this.SCROLLTOP()(
        this.$route.name
      );
      const id = this.ROWINDEX();
      this.currentRow = this.$refs.table.data.find(el => {
        if (el.id === id) {
          return true;
        }
        return false;
      });
      this.$refs.table.setCurrentRow(this.currentRow);
    }
  }
};
</script>

<style scoped>
.table-company {
  height: calc(100vh - 180px);
}
.el-table {
  color: black;
}
.head-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
