<template>
  <div class="menu">
    <div class="container" style="padding-bottom: 1count0px;">
      <div v-if="isAdmin">
        <el-menu :default-active="INDEX()" mode="horizontal">
          <el-menu-item index="1" @click="toCompany">Предприятия </el-menu-item>
          <el-menu-item index="2" @click="toUsers" v-if="!MODER()">
            <span>Пользователи</span>
          </el-menu-item>

          <el-menu-item index="3" @click="toRequest" v-if="!MODER()">
            <span>Заявки на материал</span>
            <i class="el-icon-warning" style="color: red;" v-if="count"></i>
          </el-menu-item>
          <el-menu-item index="7" @click="toSTATMAT">
            <span>Статистика по материалам</span>
          </el-menu-item>
          <el-menu-item index="4" @click="toNewsTableAdmit">
            <span>Оповещения</span>
          </el-menu-item>
          <el-menu-item index="5" @click="toStatisticAdmin">
            <span>Статистика</span>
          </el-menu-item>
          <el-menu-item index="6" @click="toLogsAdmin">
            <span>История действий</span>
          </el-menu-item>
          <el-menu-item index="8" @click="toOtchet">
            <span>Отчеты Агентов</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div v-else class="flex">
        <div class="">
          <el-menu :default-active="INDEX()" mode="horizontal">
            <el-menu-item index="1" @click="toPlate"
              ><span>Знаки </span>
            </el-menu-item>
            <el-menu-item index="2" @click="toAgentReq"
              ><span>Реестр</span>
            </el-menu-item>
            <el-menu-item index="3" @click="toNewsTable">
              <span>Новости</span>
            </el-menu-item>
            <el-menu-item index="4" @click="toStatistic">
              <span>Статистика</span>
            </el-menu-item>
          </el-menu>
        </div>
        <div class=""></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {},
  props: {
    isAdmin: Boolean
  },
  data() {
    return {
      count: null,
      countNews: null
    };
  },
  methods: {
    ...mapActions(["RESET_HEAD", "RESET_INDEX"]),
    ...mapGetters(["INDEX", "MODER"]),
    toLogsAdmin() {
      if (this.$route.path != "/admin/logs") {
        this.RESET_HEAD("История Действий");
        this.$router.push({ name: "LogsAdmin" });
      }
    },
    toCompany() {
      if (this.$route.path != "/admin/company") {
        this.RESET_HEAD("Список предприятий");
        this.$router.push({ name: "CompanyAdmin" });
      }
    },
    toUsers() {
      if (this.$route.path != "/admin/users") {
        this.RESET_HEAD("Список пользователей");
        this.$router.push({ name: "UsersTable" });
      }
    },
    toRequest() {
      if (this.$route.path != "/admin/materials") {
        this.RESET_HEAD("Список заявок на материал");
        this.$router.push({ name: "MaterialRequests" });
      }
    },
    toStatistic() {
      if (this.$route.name != "StatisticPage") {
        this.$router.push({ name: "StatisticPage" });
      }
    },
    toStatisticAdmin() {
      if (this.$route.name != "StatisticPageAdmin(") {
        this.$router.push({ name: "StatisticPageAdmin" });
      }
    },
    toPlate() {
      if (this.$route.name != "CompanyPlate") {
        this.RESET_HEAD("Список изготовленных знаков");
        this.$router.push({ name: "CompanyPlate" });
      }
    },
    toAgentReq() {
      if (this.$route.name != "RegistryCompany") {
        this.RESET_HEAD("Реестр получения пластин");
        this.$router.push({ name: "RegistryCompany" });
      }
    },
    toNewsTable() {
      if (this.$route.name != "NewsTable") {
        this.RESET_HEAD("Список новостей компании");
        this.$router.push({ name: "NewsTable" });
      }
    },
    toNewsTableAdmit() {
      if (this.$route.name != "NewsTableAdmin") {
        this.RESET_HEAD("Список новостей");
        this.$router.push({ name: "NewsTableAdmin" });
      }
    },
    toOtchet() {
      if (this.$route.name != "OtchetsAgent") {
        this.RESET_HEAD("Отчеты Агентов");
        this.$router.push({ name: "OtchetsAgent" });
      }
    },
    toSTATMAT() {
      if (this.$route.name != "STATMAT") {
        this.RESET_HEAD("STATMAT");
        this.$router.push({ name: "STATMAT" });
      }
    }
  },
  created: function() {
    this.RESET_INDEX("0");
    if (this.$route.name == "CompanyPlate") this.RESET_INDEX("1");
    if (this.$route.name == "RegistryCompany") this.RESET_INDEX("2");
    if (this.$route.name == "NewsTable") this.RESET_INDEX("3");
    if (this.$route.name == "StatisticPage") this.RESET_INDEX("4");
    if (this.$route.name == "StatisticPageAdmin") this.RESET_INDEX("5");
    if (this.$route.name == "LogsAdmin") this.RESET_INDEX("6");
    if (this.$route.name == "CompanyAdmin") this.RESET_INDEX("1");
    if (this.$route.name == "UsersTable") this.RESET_INDEX("2");
    if (this.$route.name == "UsersCompany") this.RESET_INDEX("2");
    if (this.$route.name == "MaterialRequests") this.RESET_INDEX("3");
    if (this.$route.name == "NewsTableAdmin") this.RESET_INDEX("4");
    if (this.$route.name == "STATMAT") this.RESET_INDEX("7");
    if (this.$route.name == "OtchetsAgent") this.RESET_INDEX("8");
    this.$http.get("/api/registry/count").then(res => {
      this.count = res.data.count;
    });
    // if (this.isAdmin) {
    //   this.$http.get("/api/news/count").then(res => {
    //     res.data.count;
    //     this.countNews = res.data.count;
    //   });
    // }
  }
};
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
