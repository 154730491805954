<template>
  <div class="">
    <el-form
      :model="form"
      label-width="auto"
      ref="form"
      label-position="left"
      autocomplete="off"
    >
      <div
        style="display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;"
      >
        <div v-for="(item, i) in PLATE_TYPES_LIST()" :key="i" class="text item">
          <el-form-item
            size="mini"
            v-if="item.title != 'Тип-2'"
            :label="item.title"
          >
            <el-input-number
              :disabled="
                (flatPlates && i == 3) ||
                  (flatPlates2 && i == 5) ||
                  (flatPlates3 && i == 12) ||
                  (flatPlates4 && i == 1)
              "
              size="mini"
              v-model="form.list_requested_materials[i].count"
            ></el-input-number>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <span slot="footer" class="footer-dialog">
      <el-button type="danger" @click="closeModal">
        Закрыть
      </el-button>

      <el-button type="success" @click="update">
        Добавить
      </el-button>
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: [
    "companyId",
    "flatPlates",
    "flatPlates2",
    "flatPlates3",
    "flatPlates4"
  ],
  data() {
    return {
      form: {
        companyId: null,
        list_requested_materials: []
      }
    };
  },
  methods: {
    ...mapActions(["PUT_STORES", "GET_COMPANY"]),
    ...mapGetters(["PLATE_TYPES_LIST"]),
    closeModal() {
      this.$emit("closeModalStores");
    },
    update() {
      this.$emit("closeModalStores");
      this.form.companyId = this.companyId;
      this.PUT_STORES(this.form);
      setTimeout(() => {
        this.GET_COMPANY();
      }, 1000);
    },

    submit() {}
  },
  created: function() {
    this.PLATE_TYPES_LIST().map(el => {
      this.form.list_requested_materials.push({
        count: 0,
        plateTypeId: el.id
      });
    });
  }
};
</script>

<style scoped>
.footer-dialog {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-select {
  display: block;
}
</style>
