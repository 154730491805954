<template>
  <div
    class="el-table el-table--fit el-table--border el-table--scrollable-x el-table--enable-row-hover el-table--enable-row-transition"
  >
    <table
      v-if="
        Array.isArray(PLATE_TYPES_LIST()) &&
          this.PLATE_TYPES_LIST().length &&
          Array.isArray(COUNT_PLATES()) &&
          this.COUNT_PLATES().length
      "
      border="0"
      cellspacing="0"
      class="el-table__header"
      cellpadding
    >
      <thead class="has-gutter">
        <tr>
          <th style="background: rgb(232 236 241);"></th>
          <template v-if="!INFO_COMPANY().flat_plates4">
            <th
              colspan="1"
              rowspan="1"
              is-leaf
              style="font-size: 10px; padding: 5px; background: rgb(232 236 241);"
            >
              {{ PLATE_TYPES_LIST()[0].title.slice(4) }}
            </th>

            <th
              colspan="1"
              rowspan="1"
              is-leaf
              style="font-size: 10px; padding: 5px; background: rgb(232 236 241);"
            >
              {{ PLATE_TYPES_LIST()[1].title.slice(4) }}
            </th>
          </template>
          <template v-else>
            <th
              colspan="1"
              rowspan="1"
              is-leaf
              style="font-size: 10px; padding: 5px; background: rgb(232 236 241);"
            >
              1
            </th>
          </template>
          <template v-if="!INFO_COMPANY().flat_plates">
            <th
              colspan="1"
              rowspan="1"
              is-leaf
              style="font-size: 10px; padding: 5px; background: rgb(232 236 241);"
            >
              {{ PLATE_TYPES_LIST()[2].title.slice(4) }}
            </th>

            <th
              colspan="1"
              rowspan="1"
              is-leaf
              style="font-size: 10px; padding: 5px; background: rgb(232 236 241);"
            >
              {{ PLATE_TYPES_LIST()[3].title.slice(4) }}
            </th>
          </template>
          <template v-else>
            <th
              colspan="1"
              rowspan="1"
              is-leaf
              style="font-size: 10px; padding: 5px; background: rgb(232 236 241);"
            >
              1А
            </th>
          </template>
          <!-- new plates -->
          <template v-if="!INFO_COMPANY().flat_plates3">
            <th
              colspan="1"
              rowspan="1"
              is-leaf
              style="font-size: 10px; padding: 5px; background: rgb(232 236 241);"
            >
              {{ PLATE_TYPES_LIST()[11].title.slice(4) }}
            </th>

            <th
              colspan="1"
              rowspan="1"
              is-leaf
              style="font-size: 10px; padding: 5px; background: rgb(232 236 241);"
            >
              {{ PLATE_TYPES_LIST()[12].title.slice(4) }}
            </th>
          </template>
          <template v-else>
            <th
              colspan="1"
              rowspan="1"
              is-leaf
              style="font-size: 10px; padding: 5px; background: rgb(232 236 241);"
            >
              1А БФ
            </th>
          </template>
          <template v-if="!INFO_COMPANY().flat_plates2">
            <th
              colspan="1"
              rowspan="1"
              is-leaf
              style="font-size: 10px; padding: 5px; background: rgb(232 236 241);"
            >
              {{ PLATE_TYPES_LIST()[4].title.slice(4) }}
            </th>

            <th
              colspan="1"
              rowspan="1"
              is-leaf
              style="font-size: 10px; padding: 5px; background: rgb(232 236 241);"
            >
              {{ PLATE_TYPES_LIST()[5].title.slice(4) }}
            </th>
          </template>
          <template v-else>
            <th
              colspan="1"
              rowspan="1"
              is-leaf
              style="font-size: 10px; padding: 5px; background: rgb(232 236 241);"
            >
              1БФ
            </th>
          </template>

          <th
            colspan="1"
            rowspan="1"
            is-leaf
            style="font-size: 10px; padding: 5px; background: rgb(232 236 241);"
          >
            {{ PLATE_TYPES_LIST()[6].title.slice(4) }}
          </th>

          <th
            colspan="1"
            rowspan="1"
            is-leaf
            style="font-size: 10px; padding: 5px; background: rgb(232 236 241);"
          >
            {{ PLATE_TYPES_LIST()[7].title.slice(4) }}
          </th>

          <th
            colspan="1"
            rowspan="1"
            is-leaf
            style="font-size: 10px; padding: 5px; background: rgb(232 236 241);"
          >
            {{ PLATE_TYPES_LIST()[8].title.slice(4) }}
          </th>

          <th
            colspan="1"
            rowspan="1"
            is-leaf
            style="font-size: 10px; padding: 5px; background: rgb(232 236 241);"
          >
            {{ PLATE_TYPES_LIST()[9].title.slice(4) }}
          </th>

          <th
            colspan="1"
            rowspan="1"
            is-leaf
            style="font-size: 10px; padding: 5px; background: rgb(232 236 241);"
          >
            {{ PLATE_TYPES_LIST()[10].title.slice(4) }}
          </th>
        </tr>
      </thead>
      <tr>
        <td
          style="font-size: 10px; text-align: center; font-weight: bold; border: 0px;"
        >
          {{ "Осталось" }}
        </td>
        <template v-if="!INFO_COMPANY().flat_plates4">
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ COUNT_PLATES()[0].count }}
          </td>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ COUNT_PLATES()[1].count }}
          </td>
        </template>
        <template v-else>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ COUNT_PLATES()[0].count + COUNT_PLATES()[1].count }}
          </td>
        </template>
        <template v-if="!INFO_COMPANY().flat_plates">
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ COUNT_PLATES()[2].count }}
          </td>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ COUNT_PLATES()[3].count }}
          </td>
        </template>
        <template v-else>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ COUNT_PLATES()[2].count + COUNT_PLATES()[3].count }}
          </td>
        </template>
        <!-- new plates -->
        <template v-if="!INFO_COMPANY().flat_plates3">
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ COUNT_PLATES()[10].count }}
          </td>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ COUNT_PLATES()[11].count }}
          </td>
        </template>
        <template v-else>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ COUNT_PLATES()[10].count + COUNT_PLATES()[11].count }}
          </td>
        </template>
        <template v-if="!INFO_COMPANY().flat_plates2">
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ COUNT_PLATES()[4].count }}
          </td>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ COUNT_PLATES()[5].count }}
          </td>
        </template>
        <template v-else>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ COUNT_PLATES()[4].count + COUNT_PLATES()[5].count }}
          </td>
        </template>

        <td style="font-size: 10px; text-align: center; border: 0px;">
          {{ COUNT_PLATES()[6].count }}
        </td>
        <td style="font-size: 10px; text-align: center; border: 0px;">
          {{ COUNT_PLATES()[12].count }}
        </td>
        <td style="font-size: 10px; text-align: center; border: 0px;">
          {{ COUNT_PLATES()[7].count }}
        </td>
        <td style="font-size: 10px; text-align: center; border: 0px;">
          {{ COUNT_PLATES()[8].count }}
        </td>
        <td style="font-size: 10px; text-align: center; border: 0px;">
          {{ COUNT_PLATES()[9].count }}
        </td>
      </tr>

      <tr style="background: rgb(232 236 241);">
        <td
          style="font-size: 10px; text-align: center; border: 0px; font-weight: bold;"
        >
          {{ "За сутки" }}
        </td>
        <template v-if="!INFO_COMPANY().flat_plates4">
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text2(0) }}
          </td>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text2(1) }}
          </td>
        </template>
        <template v-else>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text2(0) + this.text2(1) }}
          </td>
        </template>
        <template v-if="!INFO_COMPANY().flat_plates">
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text2(2) }}
          </td>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text2(3) }}
          </td>
        </template>
        <template v-else>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text2(2) + this.text2(3) }}
          </td>
        </template>
        <!-- new plates -->
        <template v-if="!INFO_COMPANY().flat_plates3">
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text2(10) }}
          </td>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text2(11) }}
          </td>
        </template>
        <template v-else>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text2(10) + this.text2(11) }}
          </td>
        </template>
        <template v-if="!INFO_COMPANY().flat_plates2">
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text2(4) }}
          </td>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text2(5) }}
          </td>
        </template>
        <template v-else>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text2(4) + this.text2(5) }}
          </td>
        </template>

        <td style="font-size: 10px; text-align: center; border: 0px;">
          {{ this.text2(6) }}
        </td>
        <td style="font-size: 10px; text-align: center; border: 0px;">
          {{ this.text2(12) }}
        </td>
        <td style="font-size: 10px; text-align: center; border: 0px;">
          {{ this.text2(7) }}
        </td>
        <td style="font-size: 10px; text-align: center; border: 0px;">
          {{ this.text2(8) }}
        </td>
        <td style="font-size: 10px; text-align: center; border: 0px;">
          {{ this.text2(9) }}
        </td>
      </tr>
      <tr>
        <td
          style="font-size: 10px; text-align: center; border: 0px; font-weight: bold; "
        >
          {{ "Всего доб." }}
        </td>
        <template v-if="!INFO_COMPANY().flat_plates4">
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text(0) }}
          </td>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text(1) }}
          </td>
        </template>
        <template v-else>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text(0) + this.text(1) }}
          </td>
        </template>
        <template v-if="!INFO_COMPANY().flat_plates">
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text(2) }}
          </td>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text(3) }}
          </td>
        </template>
        <template v-else>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text(2) + this.text(3) }}
          </td>
        </template>
        <template v-if="!INFO_COMPANY().flat_plates3">
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text(10) }}
          </td>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text(11) }}
          </td>
        </template>
        <template v-else>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text(10) + this.text(11) }}
          </td>
        </template>
        <template v-if="!INFO_COMPANY().flat_plates2">
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text(4) }}
          </td>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text(5) }}
          </td>
        </template>
        <template v-else>
          <td style="font-size: 10px; text-align: center; border: 0px;">
            {{ this.text(4) + this.text(5) }}
          </td>
        </template>

        <td style="font-size: 10px; text-align: center; border: 0px;">
          {{ this.text(6) }}
        </td>
        <td style="font-size: 10px; text-align: center; border: 0px;">
          {{ this.text(12) }}
        </td>
        <td style="font-size: 10px; text-align: center; border: 0px;">
          {{ this.text(7) }}
        </td>
        <td style="font-size: 10px; text-align: center; border: 0px;">
          {{ this.text(8) }}
        </td>
        <td style="font-size: 10px; text-align: center; border: 0px;">
          {{ this.text(9) }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      currentRow: null
    };
  },

  methods: {
    ...mapActions(["GET_PLATES_COUNT", "GET_PLATE_TYPES"]),
    ...mapGetters(["COUNT_PLATES", "PLATE_TYPES_LIST", "INFO_COMPANY"]),
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    text(id) {
      return this.COUNT_PLATES()[id].plateAll
        ? this.COUNT_PLATES()[id].plateAll
        : 0;
    },
    text2(id) {
      return this.COUNT_PLATES()[id].platesDay
        ? this.COUNT_PLATES()[id].platesDay
        : 0;
    }
  },

  created: async function() {
    await this.GET_PLATES_COUNT(this.$route.params.id);
    await this.GET_PLATE_TYPES();
  }
};
</script>
