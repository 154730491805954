import axios from "axios";

const getDefaultState = () => ({
  users: Array,
  roles: Array,
  userForm: Object
});

const state = getDefaultState();

const getters = {
  USERS: state => {
    return state.users;
  },
  ROLES: state => {
    return state.roles;
  },
  USER_ONE: state => {
    return state.userForm;
  }
};

const actions = {
  RESET_USER: async ({ commit }) => {
    commit("resetState");
  },
  GET_LIST_USERS: async ({ commit }) => {
    axios
      .get("/api/users/")
      .then(res => {
        commit("SET_USERS", res.data);
      })
      .catch(e => {
        console.log(e);
      });
  },
  GET_LIST_USERS_COMPANY: async ({ commit }, id) => {
    axios
      .get(`/api/users/company/${id}`)
      .then(res => {
        commit("SET_USERS", res.data);
      })
      .catch(e => {
        console.log(e);
      });
  },
  GET_USERS_TYPE: async ({ commit }) => {
    axios
      .get("/api/users/roles")
      .then(res => {
        commit("SET_ROLES", res.data);
      })
      .catch(e => {
        e;
      });
  },

  GET_USER_ONE: async ({ commit }, id) => {
    id;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/users/${id}`)
        .then(res => {
          commit("SET_USER_ONE", res.data);
          resolve();
        })
        .catch(e => {
          reject();
          e;
        });
    });
  },
  UPDATE_USER: async ({ commit }, userData) => {
    return new Promise((resolve, reject) => {
      axios
        .put("/api/auth", userData)
        .then(() => {
          commit("SET_UPDATE_USER", userData);
          resolve();
        })
        .catch(e => {
          e;
          reject();
        });
    });
  }
};

const mutations = {
  SET_USERS: (state, data) => {
    state.users = data;
  },
  SET_ROLES: (state, data) => {
    state.roles = data;
  },
  SET_USER_ONE: (state, data) => {
    data;
    if (data.roleId > 2) {
      state.userForm = {
        id: data.id,
        company_id: data.arrCompany != null ? "" : data.company.id,
        surname: data.users_info.surname,
        role: data.roleId,
        name: data.users_info.name,
        patronymic: data.users_info.patronymic,
        phone_number: data.users_info.phone_number,
        login: data.login,
        email: data.email,
        adress: data.users_info.adress,
        arrCompany: data.arrCompany,
        password: "",
        passwordRep: ""
      };
    } else {
      state.userForm = {
        id: data.id,
        company_id: "",
        surname: data.users_info.surname,
        role: data.roleId || "",
        name: data.users_info.name,
        patronymic: data.users_info.patronymic,
        phone_number: data.users_info.phone_number,
        login: data.login,
        email: data.email,
        adress: data.users_info.adress,
        password: "",
        passwordRep: "",
        arrCompany: data.arrCompany
      };
    }
  },
  SET_UPDATE_USER: (state, data) => {
    state.userForm = {
      id: data.id,
      company_id: data.company_id,
      surname: data.surname,
      role: data.roleId || "",
      name: data.name,
      patronymic: data.patronymic,
      phone_number: data.phone_number,
      login: data.login,
      email: data.email,
      adress: data.adress,
      arrCompany: data.arrCompany,
      password: "",
      passwordRep: ""
    };
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
