import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import "normalize.css";
import Axios from "axios";
import VueMask from "v-mask";
Vue.use(VueMask, {
  placeholders: {
    "#": /[0-9]/,
    D: /./,
    Я: /[авекмнорстухАВЕКМНОРСТУХ]/
  }
});
Vue.config.productionTip = false;
Vue.prototype.$http = Axios;
const token = localStorage.getItem("jwtToken");
Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = token;
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
