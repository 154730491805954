<template>
  <div class="">
    <el-form
      size="mini"
      :model="form"
      ref="form"
      :rules="rules"
      label-width="auto"
      autocomplete="off"
    >
      <el-form-item prop="role" label="Тип пользователя">
        <el-select
          v-model="form.role"
          placeholder="Выбрать роль"
          @change="changeRoles"
        >
          <el-option
            v-for="item in this.ROLES()"
            :key="item.id"
            :label="item.role"
            :value="item.id"
            :disabled="item.disabled"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="company_id" label="Название предприятия">
        <span slot="label">
          Название предприятия
          <el-popover
            placement="bottom-start"
            title="Информация"
            trigger="hover"
            content="Если модератору присвоенно предприятие, то у него доступ только к нему, иначе ко всем."
          >
            <i slot="reference" class="el-icon-question"></i>
          </el-popover>
        </span>

        <el-select
          :disabled="selectDisable"
          filterable
          v-if="form.role == 1 || form.role == 4 || form.role == null"
          v-model="form.company_id"
          placeholder="Выбрать предприятие"
          clearable
          ref="selectCompany"
        >
          <el-option
            v-for="item in this.COMPANY()"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <el-checkbox-group
          v-model="form.arrCompany"
          v-if="form.role == 2 || form.role == 3"
        >
          <template v-for="item in this.COMPANY()">
            <el-checkbox :label="item.id" :key="item.id"
              >{{ item.title }}
            </el-checkbox>
          </template>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item prop="surname" label="Фамилия">
        <el-input
          v-model="form.surname"
          autocomplete="off"
          placeholder="Иванов"
        ></el-input>
      </el-form-item>
      <el-form-item prop="name" label="Имя">
        <el-input
          v-model="form.name"
          autocomplete="off"
          placeholder="Иван"
        ></el-input>
      </el-form-item>
      <el-form-item prop="patronymic" label="Отчество">
        <el-input
          v-model="form.patronymic"
          autocomplete="off"
          placeholder="Иванович"
        ></el-input>
      </el-form-item>
      <el-form-item prop="email" label="Почта">
        <el-input
          v-model="form.email"
          autocomplete="off"
          placeholder="mail@mail.ru"
        ></el-input>
      </el-form-item>
      <el-form-item prop="phone_number" label="Телефон">
        <el-input
          v-model="form.phone_number"
          autocomplete="off"
          placeholder="+79999999999"
        ></el-input>
      </el-form-item>
      <el-form-item prop="adress" label="Адрес">
        <el-input v-model="form.adress" placeholder="ул. Ленина"></el-input>
      </el-form-item>
      <el-form-item prop="login" label="Логин">
        <el-input
          v-model="form.login"
          autocomplete="new-password"
          placeholder="Логин"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password" label="Пароль">
        <el-input
          show-password
          v-model="form.password"
          autocomplete="new-password"
        ></el-input>
      </el-form-item>
      <el-form-item prop="passwordRep" label="Пароль еще раз">
        <el-input
          show-password
          v-model="form.passwordRep"
          autocomplete="new-password"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="footer-dialog">
      <div v-if="create_user" style="display: contents">
        <el-button type="danger" @click="updDialogVisible('form')">
          Закрыть
        </el-button>
        <el-button type="success" @click="submitNewCompany('form')">
          Создать
        </el-button>
      </div>
      <div v-else style="display: contents">
        <el-button type="danger" @click="updDialogVisible('form')">
          Отменить
        </el-button>
        <el-button type="success" @click="submitOldCompany('form')">
          Сохранить
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// eslint-disable-next-line no-unused-vars
import axios from "axios";

export default {
  props: {
    userId: String
  },
  data() {
    let checkCompany = (rule, value, cb) => {
      if (this.form.role == 1 || this.form.role == 3) {
        return cb();
      } else if (this.form.arrCompany) {
        if (
          this.form.arrCompany.length == 0 &&
          (this.form.role == 2 || this.form.role == 3)
        ) {
          return cb(new Error("Выберите предприятие"));
        }
      }

      if (!value && this.form.role == 4)
        return cb(new Error("Выберите предприятие"));
      return cb();
    };
    let checkPasswordRep = (rule, value, cb) => {
      if (!this.create_user) {
        if (!value && !this.form.password) return cb();
        if (!value && this.form.password)
          return cb(new Error("Введите данные"));
        if (value == this.form.password) {
          return cb();
        } else {
          return cb(new Error("Пароли не совпадают"));
        }
      }
      if (!value) {
        return cb(new Error("Введите данные"));
      }
      if (value == this.form.password) {
        return cb();
      } else {
        return cb(new Error("Пароли не совпадают"));
      }
    };
    let checkPassword = (rule, value, cb) => {
      if (!value && !this.create_user) {
        return cb();
      }
      if (!value) {
        return cb(new Error("Введите данные"));
      }
      return cb();
    };
    return {
      form: {
        company_id: null,
        surname: null,
        role: null,
        name: null,
        patronymic: null,
        phone_number: null,
        login: null,
        email: null,
        adress: null,
        password: null,
        passwordRep: null,
        arrCompany: []
      },

      selectDisable: true,
      create_user: true,
      rules: {
        role: [
          {
            required: true,
            message: "Проверьте данные",
            trigger: "change"
          }
        ],
        company_id: [
          { required: true, validator: checkCompany, trigger: "blur" }
        ],
        name: [
          {
            required: true,
            message: "Проверьте данные",
            trigger: "change"
          }
        ],
        patronymic: [
          {
            required: true,
            message: "Проверьте данные",
            trigger: "change"
          }
        ],
        surname: [
          {
            required: true,
            message: "Проверьте данные",
            trigger: "change"
          }
        ],
        login: [
          {
            required: true,
            message: "Проверьте данные",
            trigger: "change"
          }
        ],
        email: [
          {
            required: false,
            message: "Проверьте данные",
            trigger: "change"
          }
        ],
        phone_number: [
          {
            required: false,
            message: "Проверьте данные",
            trigger: "change"
          }
        ],
        adress: [
          {
            required: false,
            message: "Проверьте данные",
            trigger: "change"
          }
        ],
        password: [
          { validator: checkPassword, trigger: "blur" },
          { min: 6, message: "Минимальная длина 6", trigger: "blur" }
        ],
        passwordRep: [{ validator: checkPasswordRep, trigger: "blur" }]
      }
    };
  },
  methods: {
    ...mapActions([
      "GET_COMPANY",
      "GET_LIST_USERS",
      "GET_USERS_TYPE",
      "GET_USER_ONE",
      "UPDATE_USER"
    ]),
    ...mapGetters(["COMPANY", "ROLES", "USER_ONE", "USERROLE"]),
    updDialogVisible() {
      this.$emit("updDialog");
    },
    changeRoles() {
      this.form.arrCompany = [];
      this.form.companyId = null;
      if (this.form.role > 1) {
        this.selectDisable = false;
      } else {
        this.form.company_id = "";
        this.selectDisable = true;
      }
    },

    submitNewCompany() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.role == 1 || this.form.role == 4) {
            this.form.arrCompany = null;
          } else {
            this.form.company_id = null;
          }

          axios.post("/api/auth/singup/", this.form);

          this.$emit("updDialog");
          this.$refs["form"].resetFields();

          this.GET_LIST_USERS();
          this.form.arrCompany = [];
        }
      });
    },
    submitOldCompany() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.role == 1 || this.form.role == 4) {
            this.form.arrCompany = null;
          } else {
            this.form.company_id = null;
          }
          this.UPDATE_USER(this.form)
            .then(() => {
              this.$emit("updDialog");
              this.GET_LIST_USERS();
            })
            .catch(e => {
              console.log(e);
            });
        }
      });
    }
  },
  watch: {
    userId: function(val) {
      if (val) {
        this.GET_USER_ONE(this.userId).then(() => {
          this.form = this.USER_ONE();
          this.create_user = false;
          if (this.form.role > 2) {
            this.selectDisable = false;
          } else {
            this.selectDisable = true;
            this.form.company_id = "";
          }
        });
      } else {
        this.$refs["form"].resetFields();
        this.selectDisable = true;
        this.create_user = true;
      }
    }
  },
  created: async function() {
    if (this.COMPANY().length == 0) {
      await this.GET_COMPANY();
    }

    if (this.userId) {
      await this.GET_USER_ONE(this.userId).then(() => {
        this.form = this.USER_ONE();
        this.create_user = false;
        if (this.form.role > 2) {
          this.selectDisable = false;
        } else {
          this.selectDisable = true;
          this.form.company_id = "";
        }
      });
    }

    if (this.USERROLE() == "Младший Админ") this.ROLES()[0].disabled = true;
    if (this.USERROLE() == "Младший Админ") this.ROLES()[1].disabled = true;
  }
};
</script>

<style scoped>
.footer-dialog {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-select {
  display: block;
}
</style>
