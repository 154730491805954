<template>
  <div class="container ">
    <div class="head-table">
      <TabsMenu :is-admin="true" :active-index2="INDEX()" />
      <div>
        <el-button
          v-show="multipleSelection.length > 0"
          type="danger"
          icon="el-icon-minus"
          size="mini"
          v-if="!isModer"
          @click.stop="deleteCompany"
        >
          Удалить
        </el-button>
        <el-button
          type="danger"
          size="mini"
          icon="el-icon-plus"
          v-if="!isModer"
          @click.stop="openDialog"
          >Добавить</el-button
        >
        <el-button
          v-show="multipleSelection.length > 1"
          type="danger"
          size="mini"
          icon="el-icon-plus"
          @click.stop="submitAllNews"
        >
          Оповещение
        </el-button>
      </div>
    </div>
    <div class="table-company">
      <el-table
        stripe
        ref="table"
        border
        :cell-class-name="tableCellClassName"
        size="small"
        highlight-current-row
        v-loading="loading"
        height="100%"
        style="width: 100%;  "
        :data="this.COMPANY()"
        @cell-dblclick="goRegisterPlateDb"
        @cell-click="highlightRow"
        v-if="
          Array.isArray(this.COMPANY()) &&
            this.COMPANY().length &&
            Array.isArray(this.PLATE_TYPES_LIST()) &&
            this.PLATE_TYPES_LIST().length
        "
        @selection-change="handleSelectionChange"
      >
        >
        <el-table-column label="№" width="35" type="index"> </el-table-column>
        <el-table-column width="35" type="selection"> </el-table-column>
        <el-table-column
          width="290  "
          property="title"
          label="Название предприятия"
        >
          <template slot-scope="scope">
            <el-button
              @click.stop="companyEdit(scope.row.id)"
              type="text"
              style="color: #cc0000;
               font-weight: bold;
               padding: 0;
               padding-top: 15px;
  "
            >
              {{ scope.row.title }}
            </el-button>
            <br />
            <span
              style="
          font-weight: 400;
          "
            >
              ({{ scope.row.adress }})
            </span>

            <div
              class=""
              v-if="
                Array.isArray(scope.row.plateInfoCheck) &&
                  scope.row.plateInfoCheck.length
              "
              style=" font-weight: 400; padding-top: 15px; font-size: 10px"
            >
              <span v-if="scope.row.plateInfoCheck[0].status == 1">
                Ожидается проверок:
                <b>{{ scope.row.plateInfoCheck[0].count }}</b>
                <br />
              </span>
              <span
                v-if="
                  scope.row.plateInfoCheck.length == 2
                    ? scope.row.plateInfoCheck[1].status == 1
                    : false
                "
              >
                Ожидается проверок:
                <b>{{ scope.row.plateInfoCheck[1].count }}</b>
                <br />
              </span>

              <span v-if="scope.row.plateInfoCheck[0].status == 4">
                Ожидается перепроверок:
                <b>{{ scope.row.plateInfoCheck[0].count }}</b>
              </span>
              <span
                v-if="
                  scope.row.plateInfoCheck.length == 2
                    ? scope.row.plateInfoCheck[1].status == 4
                    : false
                "
              >
                Ожидается перепроверок:
                <b>{{ scope.row.plateInfoCheck[1].count }}</b>
              </span>
            </div>
            <br />
            <span v-if="scope.row.last_auths.length > 0">
              Последний вход:
              {{ scope.row.last_auths[0].auth_date }}
            </span>
            <div
              style="margin-top: 10px;
    margin-bottom: 10px;"
            >
              <el-button
                plain
                type="danger"
                size="mini"
                @click.stop="goRegisterPlate(scope.row.id)"
                >Реестр госномеров</el-button
              >
              <el-button
                type="danger"
                plain
                v-if="!isModer"
                size="mini"
                @click.stop="
                  goToStoreUpdate(
                    scope.row.id,
                    scope.row.flat_plates,
                    scope.row.flat_plates2,
                    scope.row.flat_plates3,
                    scope.row.flat_plates4
                  )
                "
                >Добавить пластин</el-button
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column width="35">
          <template slot="header">
            <i class="el-icon-user-solid"></i>
          </template>
          <template slot-scope="scope">
            <el-button
              type="text"
              :disabled="isModer"
              @click.stop="goToUsersPage(scope.row.id)"
              style="color: #cc0000;"
            >
              {{ scope.row.userCount ? scope.row.userCount : 0 }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column width="37">
          <template slot="header">
            <i class="el-icon-message-solid"></i>
          </template>
          <template slot-scope="scope">
            <el-button
              style="color: #cc0000;"
              type="text"
              icon="el-icon-message-solid"
              @click.stop="sendNews(scope.row.id)"
            >
            </el-button>
          </template>
        </el-table-column>

        <el-table-column width="50">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[0].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            <div style="text-align: center;">
              <el-button
                type="text"
                class="bth-count"
                size="mini"
                @click.stop="goRegisterPlate(scope.row.id)"
              >
                <div class="center-cell">
                  {{ scope.row.stores[0].count }}
                </div>
                <hr />
                <div class="center-cell" v-if="!scope.row.flat_plates4">
                  {{
                    typeof scope.row.stores[0]["plateAll"] !== "undefined"
                      ? scope.row.stores[0].plateAll
                      : "0"
                  }}
                </div>
                <div class="center-cell" v-else>
                  {{
                    countPlate(
                      scope.row.stores[0]["plateAll"],
                      scope.row.stores[1]["plateAll"]
                    )
                  }}
                </div>
                <hr />
                <div class="center-cell" v-if="!scope.row.flat_plates4">
                  {{
                    typeof scope.row.stores[0]["platesLastDays"] !== "undefined"
                      ? scope.row.stores[0].platesLastDays
                      : "0"
                  }}
                </div>
                <div class="center-cell" v-else>
                  {{
                    countPlate(
                      scope.row.stores[0]["platesLastDays"],
                      scope.row.stores[1]["platesLastDays"]
                    )
                  }}
                </div>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="50">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[1].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            <div style="text-align: center;" v-if="!scope.row.flat_plates4">
              <el-button
                type="text"
                class="bth-count"
                size="mini"
                @click.stop="goRegisterPlate(scope.row.id)"
              >
                <div class="center-cell">
                  {{ scope.row.stores[1].count }}
                </div>
                <hr />
                <div class="center-cell">
                  {{
                    typeof scope.row.stores[1]["plateAll"] !== "undefined"
                      ? scope.row.stores[1].plateAll
                      : "0"
                  }}
                </div>
                <hr />
                <div class="center-cell">
                  {{
                    typeof scope.row.stores[1]["platesLastDays"] !== "undefined"
                      ? scope.row.stores[1].platesLastDays
                      : "0"
                  }}
                </div>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="50" style="text-align: center;">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[2].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            <div style="text-align: center;">
              <el-button
                type="text"
                class="bth-count"
                size="mini"
                @click.stop="goRegisterPlate(scope.row.id)"
              >
                <div class="center-cell">
                  {{ scope.row.stores[2].count }}
                </div>
                <hr />
                <div class="center-cell" v-if="!scope.row.flat_plates">
                  {{
                    typeof scope.row.stores[2]["plateAll"] !== "undefined"
                      ? scope.row.stores[2].plateAll
                      : "0"
                  }}
                </div>
                <div class="center-cell" v-else>
                  {{
                    countPlate(
                      scope.row.stores[2]["plateAll"],
                      scope.row.stores[3]["plateAll"]
                    )
                  }}
                </div>
                <hr />
                <div class="center-cell" v-if="!scope.row.flat_plates">
                  {{
                    typeof scope.row.stores[2]["platesLastDays"] !== "undefined"
                      ? scope.row.stores[2].platesLastDays
                      : "0"
                  }}
                </div>
                <div class="center-cell" v-else>
                  {{
                    countPlate(
                      scope.row.stores[2]["platesLastDays"],
                      scope.row.stores[3]["platesLastDays"]
                    )
                  }}
                </div>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="57">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[3].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            <div style="text-align: center;" v-if="!scope.row.flat_plates">
              <el-button
                type="text"
                class="bth-count"
                size="mini"
                @click.stop="goRegisterPlate(scope.row.id)"
              >
                <div class="center-cell">
                  {{ scope.row.stores[3].count }}
                </div>
                <hr />
                <div class="center-cell">
                  {{
                    typeof scope.row.stores[3]["plateAll"] !== "undefined"
                      ? scope.row.stores[3].plateAll
                      : "0"
                  }}
                </div>
                <hr />
                <div class="center-cell">
                  {{
                    typeof scope.row.stores[3]["platesLastDays"] !== "undefined"
                      ? scope.row.stores[3].platesLastDays
                      : "0"
                  }}
                </div>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <!-- новый тип -->
        <el-table-column width="50" style="text-align: center;">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[11].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            <div style="text-align: center;">
              <el-button
                type="text"
                class="bth-count"
                size="mini"
                @click.stop="goRegisterPlate(scope.row.id)"
              >
                <div class="center-cell">
                  {{ scope.row.stores[11].count }}
                </div>
                <hr />
                <div class="center-cell" v-if="!scope.row.flat_plates3">
                  {{
                    typeof scope.row.stores[11]["plateAll"] !== "undefined"
                      ? scope.row.stores[11].plateAll
                      : "0"
                  }}
                </div>
                <div class="center-cell" v-else>
                  {{
                    countPlate(
                      scope.row.stores[11]["plateAll"],
                      scope.row.stores[12]["plateAll"]
                    )
                  }}
                </div>
                <hr />
                <div class="center-cell" v-if="!scope.row.flat_plates3">
                  {{
                    typeof scope.row.stores[11]["platesLastDays"] !==
                    "undefined"
                      ? scope.row.stores[11].platesLastDays
                      : "0"
                  }}
                </div>
                <div class="center-cell" v-else>
                  {{
                    countPlate(
                      scope.row.stores[11]["platesLastDays"],
                      scope.row.stores[12]["platesLastDays"]
                    )
                  }}
                </div>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="57">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[12].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            <div style="text-align: center;" v-if="!scope.row.flat_plates3">
              <el-button
                type="text"
                class="bth-count"
                size="mini"
                @click.stop="goRegisterPlate(scope.row.id)"
              >
                <div class="center-cell">
                  {{ scope.row.stores[12].count }}
                </div>
                <hr />
                <div class="center-cell">
                  {{
                    typeof scope.row.stores[12]["plateAll"] !== "undefined"
                      ? scope.row.stores[12].plateAll
                      : "0"
                  }}
                </div>
                <hr />
                <div class="center-cell">
                  {{
                    typeof scope.row.stores[12]["platesLastDays"] !==
                    "undefined"
                      ? scope.row.stores[12].platesLastDays
                      : "0"
                  }}
                </div>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="62">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[4].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            <div style="text-align: center;">
              <el-button
                type="text"
                class="bth-count"
                size="mini"
                @click.stop="goRegisterPlate(scope.row.id)"
              >
                <div class="center-cell">
                  {{ scope.row.stores[4].count }}
                </div>
                <hr />
                <div class="center-cell" v-if="!scope.row.flat_plates2">
                  {{
                    typeof scope.row.stores[4]["plateAll"] !== "undefined"
                      ? scope.row.stores[4].plateAll
                      : "0"
                  }}
                </div>
                <div class="center-cell" v-else>
                  {{
                    countPlate(
                      scope.row.stores[4]["plateAll"],
                      scope.row.stores[5]["plateAll"]
                    )
                  }}
                </div>
                <hr />
                <div class="center-cell" v-if="!scope.row.flat_plates2">
                  {{
                    typeof scope.row.stores[4]["platesLastDays"] !== "undefined"
                      ? scope.row.stores[4].platesLastDays
                      : "0"
                  }}
                </div>
                <div class="center-cell" v-else>
                  {{
                    countPlate(
                      scope.row.stores[4]["platesLastDays"],
                      scope.row.stores[5]["platesLastDays"]
                    )
                  }}
                </div>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="65">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[5].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            <div style="text-align: center;" v-if="!scope.row.flat_plates2">
              <el-button
                type="text"
                class="bth-count"
                size="mini"
                @click.stop="goRegisterPlate(scope.row.id)"
              >
                <div class="center-cell">
                  {{ scope.row.stores[5].count }}
                </div>
                <hr />
                <div class="center-cell">
                  {{
                    typeof scope.row.stores[5]["plateAll"] !== "undefined"
                      ? scope.row.stores[5].plateAll
                      : "0"
                  }}
                </div>
                <hr />
                <div class="center-cell">
                  {{
                    typeof scope.row.stores[5]["platesLastDays"] !== "undefined"
                      ? scope.row.stores[5].platesLastDays
                      : "0"
                  }}
                </div>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="50">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[6].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            <div style="text-align: center;">
              <el-button
                type="text"
                class="bth-count"
                size="mini"
                @click.stop="goRegisterPlate(scope.row.id)"
              >
                <div class="center-cell">
                  {{ scope.row.stores[6].count }}
                </div>
                <hr />
                <div class="center-cell">
                  {{
                    typeof scope.row.stores[6]["plateAll"] !== "undefined"
                      ? scope.row.stores[6].plateAll
                      : "0"
                  }}
                </div>
                <hr />
                <div class="center-cell">
                  {{
                    typeof scope.row.stores[6]["platesLastDays"] !== "undefined"
                      ? scope.row.stores[6].platesLastDays
                      : "0"
                  }}
                </div>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="50">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[7].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            <div style="text-align: center;">
              <el-button
                type="text"
                class="bth-count"
                size="mini"
                @click.stop="goRegisterPlate(scope.row.id)"
              >
                <div class="center-cell">
                  {{ scope.row.stores[7].count }}
                </div>
                <hr />
                <div class="center-cell">
                  {{
                    typeof scope.row.stores[7]["plateAll"] !== "undefined"
                      ? scope.row.stores[7].plateAll
                      : "0"
                  }}
                </div>
                <hr />
                <div class="center-cell">
                  {{
                    typeof scope.row.stores[7]["platesLastDays"] !== "undefined"
                      ? scope.row.stores[7].platesLastDays
                      : "0"
                  }}
                </div>
              </el-button>
            </div>
          </template>
        </el-table-column>

        <el-table-column width="50">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[8].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            <div style="text-align: center;">
              <el-button
                type="text"
                class="bth-count"
                size="mini"
                @click.stop="goRegisterPlate(scope.row.id)"
              >
                <div class="center-cell">
                  {{ scope.row.stores[8].count }}
                </div>
                <hr />
                <div class="center-cell">
                  {{
                    typeof scope.row.stores[8]["plateAll"] !== "undefined"
                      ? scope.row.stores[8].plateAll
                      : "0"
                  }}
                </div>
                <hr />
                <div class="center-cell">
                  {{
                    typeof scope.row.stores[8]["platesLastDays"] !== "undefined"
                      ? scope.row.stores[8].platesLastDays
                      : "0"
                  }}
                </div>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="50">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[9].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            <div style="text-align: center;">
              <el-button
                type="text"
                class="bth-count"
                size="mini"
                @click.stop="goRegisterPlate(scope.row.id)"
              >
                <div class="center-cell">
                  {{ scope.row.stores[9].count }}
                </div>
                <hr />
                <div class="center-cell">
                  {{
                    typeof scope.row.stores[9]["plateAll"] !== "undefined"
                      ? scope.row.stores[9].plateAll
                      : "0"
                  }}
                </div>
                <hr />
                <div class="center-cell">
                  {{
                    typeof scope.row.stores[9]["platesLastDays"] !== "undefined"
                      ? scope.row.stores[9].platesLastDays
                      : "0"
                  }}
                </div>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="50">
          <template slot="header" slot-scope="">
            {{ this.PLATE_TYPES_LIST()[10].title.slice(4) }}
          </template>
          <template slot-scope="scope">
            <div style="text-align: center;">
              <el-button
                type="text"
                class="bth-count"
                size="mini"
                @click.stop="goRegisterPlate(scope.row.id)"
              >
                <div class="center-cell">
                  {{ scope.row.stores[10].count }}
                </div>
                <hr />
                <div class="center-cell">
                  {{
                    typeof scope.row.stores[10]["plateAll"] !== "undefined"
                      ? scope.row.stores[10].plateAll
                      : "0"
                  }}
                </div>
                <hr />
                <div class="center-cell">
                  {{
                    typeof scope.row.stores[10]["platesLastDays"] !==
                    "undefined"
                      ? scope.row.stores[10].platesLastDays
                      : "0"
                  }}
                </div>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="50">
          <template slot="header">
            <i class="el-icon-view"></i>
          </template>
          <template slot-scope="scope">
            <div class="center-cell" style="color: red;">
              <el-button
                type="text"
                style="color: red;  font-size: 12px; padding: 0;"
                size="mini"
                @click.stop="goRegisterPlate(scope.row.id)"
              >
                {{
                  typeof scope.row["dobavlenoAll"] !== "undefined"
                    ? scope.row.dobavlenoAll
                    : "0"
                }}
              </el-button>
            </div>
            <hr />
            <div class="center-cell" style="color: blue;">
              <el-button
                type="text"
                style="color: blue; font-size: 12px; padding: 0;"
                size="mini"
                @click.stop="goRegisterPlate(scope.row.id)"
              >
                {{
                  typeof scope.row["dobavlenoDay"] !== "undefined"
                    ? scope.row.dobavlenoDay
                    : "0"
                }}</el-button
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column width="50">
          <template slot="header">
            <i class="el-icon-finished"></i>
          </template>
          <template slot-scope="scope">
            <div
              class="center-cell"
              style="color: red;"
              @click.stop="goRegisterPlate(scope.row.id)"
            >
              <el-button
                type="text"
                style="color: blue; font-size: 12px; padding: 0;"
                size="mini"
                @click.stop="goRegisterPlate(scope.row.id)"
              >
                {{
                  typeof scope.row["auditAll"] !== "undefined"
                    ? scope.row.auditAll
                    : "0"
                }}
              </el-button>
            </div>
            <hr />
            <div class="center-cell" style="color: blue;">
              <el-button
                type="text"
                style="color: blue; font-size: 12px; padding: 0;"
                size="mini"
                @click.stop="goRegisterPlate(scope.row.id)"
              >
                {{
                  typeof scope.row["auditDay"] !== "undefined"
                    ? scope.row.auditDay
                    : "0"
                }}
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="50">
          <template slot="header">
            <i class="el-icon-document-delete"></i>
          </template>
          <template slot-scope="scope">
            <div class="center-cell" style="color: red;">
              <el-button
                type="text"
                style="color: blue; font-size: 12px; padding: 0;"
                size="mini"
                @click.stop="goRegisterPlate(scope.row.id)"
              >
                {{
                  typeof scope.row["defectAll"] !== "undefined"
                    ? scope.row.defectAll
                    : "0"
                }}
              </el-button>
            </div>
            <hr />
            <div class="center-cell" style="color: blue;">
              <el-button
                type="text"
                style="color: blue; font-size: 12px; padding: 0;"
                size="mini"
                @click.stop="goRegisterPlate(scope.row.id)"
              >
                {{
                  typeof scope.row["defectDay"] !== "undefined"
                    ? scope.row.defectDay
                    : "0"
                }}
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :modalAppendToBody="false"
      title="Зарегистрировать предприятие"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="650px"
    >
      <CompanyForm v-bind:company-id="id" v-on:updDialog="handleClose()" />
    </el-dialog>
    <el-dialog
      destroy-on-close
      :modalAppendToBody="false"
      title="Отправить новость"
      :visible.sync="newsFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="500px"
    >
      <NewsForm v-bind:company-id="id" v-on:closeModalNews="newsClose()" />
    </el-dialog>
    <el-dialog
      destroy-on-close
      :modalAppendToBody="false"
      title="Отправить пластин"
      :visible.sync="storeFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="600px"
    >
      <StoreForm
        v-bind:company-id="id"
        v-on:closeModalStores="storeClose()"
        :flatPlates="flat1"
        :flatPlates2="flat2"
        :flatPlates3="flat3"
        :flatPlates4="flat4"
      />
    </el-dialog>
    <el-dialog
      :modalAppendToBody="false"
      title="Информация"
      :visible.sync="dialogNewsVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
      width="700px"
    >
      <MessengerChat v-if="testNews" :newsAll="ALL_NEWS()" />
      <ChatInput
        :companyId="companyIdForChat"
        :chatBool="true"
        v-on:updChat="newsPost"
      />
      <div class="" v-if="Array.isArray(INFO_USERS()) && INFO_USERS().length">
        <el-table
          ref="singleTable"
          :data="INFO_USERS()"
          height="100%"
          size="mini"
          highlight-current-row
          style="width: 100% "
        >
          <el-table-column type="index" label="№"> </el-table-column>
          <el-table-column label="Пользователь" prop="user.login">
          </el-table-column>
          <el-table-column label="Прочитано/Не прочитано">
            <template slot-scope="scope">
              <span v-if="scope.row.confirmed">Прочитано</span>
              <span v-else>Не прочитано</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="footer-dialog">
        <el-button @click.stop="close">Закрыть</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import CompanyForm from "./CompanyForm";
import NewsForm from "./NewsForm";
import StoreForm from "./StoresUpdate";
import TabsMenu from "./TabsMenu";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import ChatInput from "./NewsForm.vue";
import MessengerChat from "./MessengerChat/MessengerChat.vue";
export default {
  components: {
    CompanyForm,
    NewsForm,
    StoreForm,
    TabsMenu,
    ChatInput,
    MessengerChat
  },
  data() {
    return {
      multipleSelection: [],
      dialogFormVisible: false,
      dialogNewsVisible: false,
      newsFormVisible: false,
      storeFormVisible: false,
      id: null,
      testNews: false,
      isModer: null,
      loading: true,
      flat1: null,
      flat2: null,
      flat3: null,
      flat4: null,
      newsIndex: 0,
      companyIdForChat: null,
      timeoutId: null
    };
  },
  methods: {
    ...mapActions([
      "GET_COMPANY",
      "GET_PLATE_TYPES",
      "RESET_HEAD",
      "GET_NAME_USER",
      "GET_NEWS_COMPANY",
      "GET_COMPANY_NAMES",
      "GET_NEWS_COMPANY_INFO_CONF",
      "RESET_HEAD",
      "GET_NEWS_ALL",
      "GET_NEWS_COMPANY_ALL",
      "SET_SCROLL",
      "RESET_SCROLL",
      "SET_ROWINDEX",
      "SET_PAGENUMBER"
    ]),
    ...mapGetters([
      "COMPANY",
      "PLATE_TYPES_LIST",
      "USERROLE",
      "INDEX",
      "MODER",
      "NEWS2",
      "COMPANY_NAMES",
      "INFO_USERS",
      "ROLES",
      "ALL_NEWS",
      "ROWINDEX",
      "SCROLLTOP"
    ]),
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    setCurrent(row) {
      this.$refs.table.setCurrentRow(row);
    },
    highlightRow(row) {
      if (!this.timeoutId) {
        this.timeoutId = setTimeout(() => {
          if (this.currentRow == row) {
            this.currentRow = null;
            this.RESET_SCROLL();

            return this.setCurrent();
          }
          this.setCurrent(row);
          this.currentRow = row;
          this.SET_SCROLL({
            name: this.$route.name,
            top: this.$refs["table"].bodyWrapper.scrollTop
          });
          this.SET_ROWINDEX(row.id);
          this.timeoutId = null;
        }, 500);
      }
    },
    handleClose() {
      this.dialogFormVisible = false;
    },
    newsPost() {
      this.newsFormVisible = false;
      //this.testNews = false;
      setTimeout(() => {
        this.GET_NEWS_COMPANY_ALL(this.companyIdForChat);
      }, 1000);
    },
    tableCellClassName(i) {
      if (i.columnIndex == 8 && i.row.flat_plates) return "border flat";
      if (i.columnIndex == 10 && i.row.flat_plates3) return "border flat";
      if (i.columnIndex == 12 && i.row.flat_plates2) return "border flat";
      if (i.columnIndex == 6 && i.row.flat_plates4) return "border flat";
      return "border";
    },
    close() {
      this.dialogFormVisible = false;
      this.dialogNewsVisible = false;
    },
    storeClose() {
      this.storeFormVisible = false;
    },
    openDialog() {
      this.id = null;
      this.dialogFormVisible = true;
    },
    newsClose() {
      this.newsFormVisible = false;
      this.dialogNewsVisible = false;
    },
    openNews() {
      this.id = null;
      this.newsFormVisible = true;
    },
    goToUsersPage(id) {
      this.$router.push({ name: "UsersCompany", params: { id } });
    },

    async sendNews(id) {
      this.companyIdForChat = id;
      this.newsIndex = id;
      await this.GET_NEWS_COMPANY_INFO_CONF(id);
      await this.GET_NEWS_COMPANY_ALL(id);
      this.testNews = true;
      this.dialogNewsVisible = true;
    },
    companyEdit(id) {
      this.id = id;
      this.dialogFormVisible = true;
    },
    goToStoreUpdate(id, flat1, flat2, flat3, flat4) {
      this.id = id;
      this.flat1 = flat1;
      this.flat2 = flat2;
      this.flat3 = flat3;
      this.flat4 = flat4;
      this.storeFormVisible = true;
    },
    goRegisterPlate(id) {
      this.RESET_HEAD("Реестр номерных знаков подлежащих проверке");
      this.$router.push({ name: "Register", params: { id } });
    },
    goRegisterPlateDb(row) {
      clearTimeout(this.timeoutId);
      const id = row.id;
      this.RESET_HEAD("Реестр номерных знаков подлежащих проверке");
      this.$router.push({ name: "Register", params: { id } });
    },
    countPlate(el1, el2) {
      let a = 0;

      if (typeof el1 !== "undefined") a += Number(el1);
      if (typeof el2 !== "undefined") a += Number(el2);

      return a;
    },
    deleteCompany() {
      let arr = [];
      this.multipleSelection.forEach(e => {
        arr.push(e.id);
      });

      axios.post("/api/company/delete/", { id: arr }).then(() => {
        this.GET_COMPANY();
      });
    },
    submitAllNews() {
      let arr = [];
      this.multipleSelection.forEach(e => {
        arr.push(e.id);
      });
      this.id = arr;
      this.newsFormVisible = true;
    }
  },
  created: function() {
    this.RESET_HEAD("Список предприятий");
    this.USERROLE() == "Модератор"
      ? (this.isModer = true)
      : (this.isModer = false);
    this.GET_PLATE_TYPES();
    this.GET_COMPANY();
    this.loading = false;
  },
  updated: function() {
    if (this.$refs["table"] && this.SCROLLTOP()(this.$route.name) > 0) {
      this.$refs["table"].bodyWrapper.scrollTop = this.SCROLLTOP()(
        this.$route.name
      );
      const id = this.ROWINDEX();
      this.currentRow = this.$refs.table.data.find(el => {
        if (el.id === id) {
          return true;
        }
        return false;
      });
      this.$refs.table.setCurrentRow(this.currentRow);
    }
  }
};
</script>

<style scoped>
.table-company {
  height: calc(100vh - 180px);
}
.el-table {
  color: black;
}
.head-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.center-cell {
  text-align: center;
  font-size: 12px;
}
.bth-count {
  color: black;
  font-size: 12px;
  padding: 0;
}
</style>
