<template>
  <div class="" style="display: flex; ">
    <div class="" style="    width: 90%;margin-left: 20px; ">
      <el-form :model="form">
        <el-input
          placeholder="Заголовок "
          v-model="form.title"
          style="margin: 10px 11px 12px 0px;"
        ></el-input>
        <el-input
          style="margin: 10px 11px 12px 0px;"
          type="textarea"
          :autosize="{ minRows: 3 }"
          placeholder="Текст"
          maxlength="65000"
          show-word-limit
          v-model="form.text"
        >
        </el-input>
      </el-form>
    </div>

    <div
      style="margin-top: 55px;
    margin-left: 10px;"
    >
      <el-button type="danger" @click="closeModal" v-if="!chatBool">
        Закрыть</el-button
      >
      <el-button
        type="success"
        @click="submit"
        icon="el-icon-s-promotion"
        circle
      >
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["companyId", "tableNews", "chatBool", "sendUser"],

  data() {
    return {
      form: {
        text: null,
        title: null
      }
    };
  },
  methods: {
    ...mapActions([
      "POST_NEWS",
      "GET_NEWS_COMPANY",
      "GET_NEWS_COMPANY_ALL",
      "POST_NEWS_USER"
    ]),
    closeModal() {
      this.$emit("closeModalNews");
      // Modal
    },

    async submit() {
      if (this.sendUser) {
        this.POST_NEWS_USER(this.form);
        this.form = {
          text: null,
          title: null
        };

        setTimeout(() => {
          this.GET_NEWS_COMPANY(this.$route.params.id);
        }, 1000);
      } else {
        let arr = [];
        if (Array.isArray(this.companyId) && this.companyId.length) {
          this.companyId.forEach(el => {
            arr.push(Object.assign({ companyId: el }, this.form));
          });
        } else {
          arr.push(Object.assign({ companyId: this.companyId }, this.form));
        }
        await this.$http.post(`/api/news`, arr).then(res => {
          if (res.status === 200) {
            this.form = {
              text: null,
              title: null
            };
            if (this.chatBool) {
              this.$emit("updChat");
            } else {
              this.$emit("closeModalNews");
            }

            setTimeout(() => {
              this.GET_NEWS_COMPANY_ALL(this.companyId);
            }, 1000);
          }
        });
      }
    }
  },
  created() {}
};
</script>

<style scoped>
.footer-dialog {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-select .el-input {
  width: 110px;
}
</style>
