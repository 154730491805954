<template>
  <div class="">
    <h3 style="text-align: center;">
      {{ this.NEWS()[newsIndex].title + nameCompany() }}
    </h3>
    <div class="">
      <span style="white-space: break-spaces;">{{
        this.NEWS()[newsIndex].text
      }}</span>
    </div>
    <div class="" style="text-align: center; margin-top: 10px">
      <el-button type="success" @click="putNews">
        Прочитано
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  props: {
    companyId: Number
  },
  data() {
    return {
      newsIndex: 0
    };
  },
  methods: {
    ...mapGetters(["NEWS"]),
    closeModal() {
      this.$emit("closeModalNews");
      // Modal
    },
    nextNews() {
      if (this.NEWS().length - 1 == this.newsIndex) {
        this.$emit("closeModalNews");
      } else {
        this.newsIndex++;
      }
    },
    nameCompany() {
      if (this.NEWS()[this.newsIndex].addUser) {
        return " (" + this.NEWS()[this.newsIndex].company.title + ")";
      }
      return "";
    },
    putNews() {
      axios
        .put("/api/news", {
          id: this.NEWS()[this.newsIndex].id
        })
        .then(res => {
          if (res.status == 200) this.nextNews();
        });
    }
  },
  created() {}
};
</script>

<style scoped>
.footer-dialog {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-select .el-input {
  width: 110px;
}
</style>
