<template>
  <div class="container ">
    <div class="head-table">
      <TabsMenu :is-admin="true" :active-index2="INDEX()" />
      <div class="" style="">
        <el-button type="danger" size="mini" @click="excel">Excel</el-button>
        <el-button
          type="danger"
          size="mini"
          @click="dialogVisible = !dialogVisible"
          >Настройки</el-button
        >
      </div>
    </div>
    <div class="table-company">
      <el-table
        stripe
        ref="table"
        border
        :cell-class-name="tableCellClassName"
        size="mini"
        highlight-current-row
        v-loading="loading"
        height="100%"
        style="width: 100%;  "
        :data="data"
        @cell-click="highlightRow"
        v-if="
          Array.isArray(data) &&
            data.length &&
            Array.isArray(this.PLATE_TYPES_LIST()) &&
            this.PLATE_TYPES_LIST().length
        "
        @selection-change="handleSelectionChange"
      >
        <el-table-column label="Название компание" prop="title">
        </el-table-column>
        <el-table-column width="69px" label="1xx" prop="Тип-1хх" sortable>
          <template slot-scope="scope">
            {{ scope.row["Тип-1хх"] ? scope.row["Тип-1хх"] : 0 }}
          </template>
        </el-table-column>
        <el-table-column width="69px" label="1xxx" prop="Тип-1ххх" sortable>
          <template slot-scope="scope">
            {{ scope.row["Тип-1ххх"] ? scope.row["Тип-1ххх"] : 0 }}
          </template>
        </el-table-column>
        <el-table-column width="71px" label="1Аxx" prop="Тип-1Аxx" sortable>
          <template slot-scope="scope">
            {{ scope.row["Тип-1Аxx"] ? scope.row["Тип-1Аxx"] : 0 }}
          </template>
        </el-table-column>
        <el-table-column width="77px" label="1Аxxх" prop="Тип-1Аxxx" sortable>
          <template slot-scope="scope">
            {{ scope.row["Тип-1Аxxx"] ? scope.row["Тип-1Аxxx"] : 0 }}
          </template>
        </el-table-column>
        <el-table-column width="80px" label="1БФхх" prop="Тип-1БФхх" sortable>
          <template slot-scope="scope">
            {{ scope.row["Тип-1БФхх"] ? scope.row["Тип-1БФхх"] : 0 }}
          </template>
        </el-table-column>
        <el-table-column width="86px" label="1БФххх" prop="Тип-1БФххх" sortable>
          <template slot-scope="scope">
            {{ scope.row["Тип-1БФххх"] ? scope.row["Тип-1БФххх"] : 0 }}
          </template>
        </el-table-column>
        <el-table-column
          width="95px"
          label="1А БФхх"
          prop="Тип-1А БФxx"
          sortable
        >
          <template slot-scope="scope">
            {{ scope.row["Тип-1А БФxx"] ? scope.row["Тип-1А БФxx"] : 0 }}
          </template>
        </el-table-column>
        <el-table-column
          width="100px"
          label="1А БФххх"
          prop="Тип-1А БФxxx"
          sortable
        >
          <template slot-scope="scope">
            {{ scope.row["Тип-1А БФxxx"] ? scope.row["Тип-1А БФxxx"] : 0 }}
          </template>
        </el-table-column>
        <el-table-column width="70px" label="1бхх" prop="Тип-1бхх" sortable>
          <template slot-scope="scope">
            {{ scope.row["Тип-1бхх"] ? scope.row["Тип-1бхх"] : 0 }}
          </template>
        </el-table-column>
        <el-table-column width="60px" label="2" prop="Тип-2" sortable>
          <template slot-scope="scope">
            {{ scope.row["Тип-2"] ? scope.row["Тип-2"] : 0 }}
          </template>
        </el-table-column>
        <el-table-column width="60px" label="3" prop="Тип-3" sortable>
          <template slot-scope="scope">
            {{ scope.row["Тип-3"] ? scope.row["Тип-3"] : 0 }}
          </template>
        </el-table-column>
        <el-table-column width="60px" label="4.1" prop="Тип-4.1" sortable>
          <template slot-scope="scope">
            {{ scope.row["Тип-4.1"] ? scope.row["Тип-4.1"] : 0 }}
          </template>
        </el-table-column>
        <el-table-column width="60px" label="4.2" prop="Тип-4.2" sortable>
          <template slot-scope="scope">
            {{ scope.row["Тип-4.2"] ? scope.row["Тип-4.2"] : 0 }}
          </template>
        </el-table-column>
        <el-table-column width="85px" label="Пленка" prop="Пленка" sortable>
          <template slot-scope="scope">
            {{ scope.row["Пленка"] ? scope.row["Пленка"] : 0 }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="Настройки"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-form ref="form" label-width="120px">
        <el-form-item label="Даты">
          <el-date-picker
            v-model="date"
            type="daterange"
            align="center"
            unlink-panels
            range-separator="До"
            start-placeholder="Дата начала"
            end-placeholder="Дата окончания"
            format="dd.MM.yyyy"
            value-format="dd.MM.yyyy"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Предприятия">
          <el-select
            v-model="company"
            filterable
            multiple
            size="mini"
            style="    width: 350px;"
            collapse-tags
            placeholder="Выберите предприятие"
          >
            <el-option
              v-for="item in COMPANY_NAMES()"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" @click="test">
            Отмена
          </el-button>
          <el-button type="success" @click="test">Принять</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import TabsMenu from "../TabsMenu";
import { mapActions, mapGetters } from "vuex";
import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import saveAs from "file-saver";
export default {
  components: {
    TabsMenu
  },
  data() {
    return {
      value1: [],
      data: null,
      dialogVisible: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      date: "",
      company: []
    };
  },
  methods: {
    ...mapActions([
      "RESET_SCROLL",
      "SET_SCROLL",
      "SET_ROWINDEX",
      "RESET_HEAD",
      "GET_PLATE_TYPES",
      "GET_COMPANY_NAMES"
    ]),
    ...mapGetters([
      "ROWINDEX",
      "INDEX",
      "COMPANY_NAMES",
      "PLATE_TYPES_LIST",
      "SCROLLTOP"
    ]),
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    setCurrent(row) {
      this.$refs.table.setCurrentRow(row);
    },
    highlightRow(row) {
      if (!this.timeoutId) {
        this.timeoutId = setTimeout(() => {
          if (this.currentRow == row) {
            this.currentRow = null;
            this.RESET_SCROLL();

            return this.setCurrent();
          }
          this.setCurrent(row);
          this.currentRow = row;
          this.SET_SCROLL({
            name: this.$route.name,
            top: this.$refs["table"].bodyWrapper.scrollTop
          });
          this.SET_ROWINDEX(row.id);
          this.timeoutId = null;
        }, 500);
      }
    },
    tableCellClassName(i) {
      if (i.columnIndex == 8 && i.row.flat_plates) return "border flat";
      if (i.columnIndex == 10 && i.row.flat_plates3) return "border flat";
      if (i.columnIndex == 12 && i.row.flat_plates2) return "border flat";
      if (i.columnIndex == 6 && i.row.flat_plates4) return "border flat";
      return "border";
    },
    selectCompany() {},
    findCount(data, id) {
      let a = data.find(el => {
        if (el.plateTypeId == id) {
          return el.count_sum;
        }
      });
      if (a) {
        return a.count_sum;
      }
      return 0;
    },
    handleClose() {
      this.dialogVisible = false;
      this.date = "";
      this.company = [];
    },
    test() {
      let company = [];
      if (this.company.length > 0) {
        company = this.company.join(",");
      } else {
        for (let i = 0; i < this.COMPANY_NAMES().length; i++) {
          company.push(this.COMPANY_NAMES()[i].id);
        }
      }
      let date = "";
      if (this.date) {
        date = "&date=" + this.date;
      }
      this.$http
        .get("api/company/admin/material?company=" + company + date)
        .then(res => {
          this.data = res.data;
        });
      this.dialogVisible = false;
    },
    changeTableSort(column) {
      this.data.sort((a, b) => {
        const id = a.data.findIndex(el => {
          if (el.plate_type == null) {
            return false;
          }
          if (el.plate_type.title == column.prop) {
            return true;
          }
          return false;
        });
        const id2 = b.data.findIndex(el => {
          if (el.plate_type == null) {
            return false;
          }
          if (el.plate_type.title == column.prop) {
            return true;
          }
          return false;
        });
        console.log(id, id2);
        if (id == -1 || id2 == -1) {
          return 0;
        }
        if (column.order == "ascending") {
          if (a.data[id].count_sum < b.data[id2].count_sum) {
            return -1;
          }
          if (a.data[id].count_sum > b.data[id2].count_sum) {
            return 1;
          }
          return 0;
        }
        if (column.order == "descending") {
          if (a.data[id].count_sum > b.data[id2].count_sum) {
            return -1;
          }
          if (a.data[id].count_sum < b.data[id2].count_sum) {
            return 1;
          }
          return 0;
        }
      });
    },
    excel() {
      let ExcelJSWorkbook = new ExcelJS.Workbook();
      let worksheet = ExcelJSWorkbook.addWorksheet('Отчёт', {
    pageSetup: { paperSize: 9, orientation: 'portrait', horizontalCentered: true },
  });
  worksheet.pageSetup.margins = {
    left: 1,
    right: 1,
    top: 1,
    bottom: 1,
    header: 1,
    footer: 1,
  };
      const columns = this.$refs.table.$refs.tableHeader.columns;
      console.log(columns);

      const alphabet = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
      ];
      for (let i = 0; i < columns.length; i++) {
        let currentColumnWidth = columns[i].width;
        worksheet.getColumn(alphabet[i]).width =
          currentColumnWidth !== undefined ? currentColumnWidth / 6 : 20;

        let cell = worksheet.getCell(`${alphabet[i]}1`);
        cell.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell.value = columns[i].label;
        cell.font = {
          name: "Times New Roman",
          size: 14,
          bold: true
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
      }
      const rowsTable = document.getElementsByClassName("el-table__row");
      worksheet.getColumn("A").width = 50;
      for (let i = 0, col = 2; i < rowsTable.length; i++, col++) {
        let cell = worksheet.getCell(`${alphabet[0]}${col}`);
        let cell1 = worksheet.getCell(`${alphabet[1]}${col}`);
        let cell2 = worksheet.getCell(`${alphabet[2]}${col}`);
        let cell3 = worksheet.getCell(`${alphabet[3]}${col}`);
        let cell4 = worksheet.getCell(`${alphabet[4]}${col}`);
        let cell5 = worksheet.getCell(`${alphabet[5]}${col}`);
        let cell6 = worksheet.getCell(`${alphabet[6]}${col}`);
        let cell7 = worksheet.getCell(`${alphabet[7]}${col}`);
        let cell8 = worksheet.getCell(`${alphabet[8]}${col}`);
        let cell9 = worksheet.getCell(`${alphabet[9]}${col}`);
        let cell10 = worksheet.getCell(`${alphabet[10]}${col}`);
        let cell11 = worksheet.getCell(`${alphabet[11]}${col}`);
        let cell12 = worksheet.getCell(`${alphabet[12]}${col}`);
        let cell13 = worksheet.getCell(`${alphabet[13]}${col}`);
        let cell14 = worksheet.getCell(`${alphabet[14]}${col}`);
        cell.value = rowsTable[i].childNodes[0].outerText;
        cell1.value = Number(rowsTable[i].childNodes[1].outerText);
        cell2.value = Number(rowsTable[i].childNodes[2].outerText);
        cell3.value = Number(rowsTable[i].childNodes[3].outerText);
        cell4.value = Number(rowsTable[i].childNodes[4].outerText);
        cell5.value = Number(rowsTable[i].childNodes[5].outerText);
        cell6.value = Number(rowsTable[i].childNodes[6].outerText);
        cell7.value = Number(rowsTable[i].childNodes[7].outerText);
        cell8.value = Number(rowsTable[i].childNodes[8].outerText);
        cell9.value = Number(rowsTable[i].childNodes[9].outerText);
        cell10.value = Number(rowsTable[i].childNodes[10].outerText);
        cell11.value = Number(rowsTable[i].childNodes[11].outerText);
        cell12.value = Number(rowsTable[i].childNodes[12].outerText);
        cell13.value = Number(rowsTable[i].childNodes[13].outerText);
        cell14.value = Number(rowsTable[i].childNodes[14].outerText);
        cell.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell1.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell2.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell3.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell4.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell5.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell6.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell7.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell8.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell9.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell10.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell11.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell12.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell13.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell14.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };

        cell.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell1.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell2.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell3.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell4.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell5.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell6.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell7.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell8.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell9.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell10.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell11.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell12.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell13.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell14.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };

        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell1.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell2.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell3.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell4.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell5.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell6.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell7.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell8.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell9.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell10.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell11.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell12.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell13.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell14.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
      }
      ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `ТаблицаМатериалов.xlsx`
        );
      });
    }
  },
  created: function() {
    this.GET_COMPANY_NAMES();
    this.RESET_HEAD("Статистика по материалам");
    // this.USERROLE() == "Модератор"
    //   ? (this.isModer = true)
    //   : (this.isModer = false);
    this.GET_PLATE_TYPES();

    setTimeout(() => {
      let arrId = [];

      for (let i = 0; i < this.COMPANY_NAMES().length; i++) {
        arrId.push(this.COMPANY_NAMES()[i].id);
      }
      this.$http
        .get("api/company/admin/material?company=" + arrId.join(","))
        .then(res => {
          this.data = res.data;
        });
    }, 1000);
    this.loading = false;
  },
  updated: function() {
    if (this.$refs["table"] && this.SCROLLTOP()(this.$route.name) > 0) {
      this.$refs["table"].bodyWrapper.scrollTop = this.SCROLLTOP()(
        this.$route.name
      );
      const id = this.ROWINDEX();
      this.currentRow = this.$refs.table.data.find(el => {
        if (el.id === id) {
          return true;
        }
        return false;
      });
      this.$refs.table.setCurrentRow(this.currentRow);
    }
  }
};
</script>

<style scoped>
.table-company {
  height: calc(100vh - 180px);
}
.el-table {
  color: black;
}
.head-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.center-cell {
  text-align: center;
  font-size: 12px;
}
.bth-count {
  color: black;
  font-size: 12px;
  padding: 0;
}
</style>
