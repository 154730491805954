<template>
  <div class="container ">
    <div class="head-table">
      <div class="" style="width = 100%">
        <TabsMenu :is-admin="true" />
      </div>
      <div
        class=""
        v-if="Array.isArray(COMPANY_COUNT()) && COMPANY_COUNT().length"
      >
        <el-button type="danger" size="mini" @click="test"
          >Только не обработанные</el-button
        >
        <el-button type="danger" size="mini" @click="all">Все заявки</el-button>
        <el-button
          type="danger"
          size="mini"
          style="margin-right: 10px;"
          icon="el-icon-tickets"
          @click="excel"
          >Excel</el-button
        >
        <el-select
          v-model="companyId"
          size="mini"
          filterable
          placeholder="Выберите предприятие"
          @change="changeCompany"
        >
          <el-option
            v-for="item in COMPANY_COUNT()"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
            <span style="float: right; color: red">{{ item.count }}</span>
            <span style="float: left; color: #8492a6; font-size: 13px">{{
              item.title
            }}</span>
          </el-option>
        </el-select>
      </div>
    </div>

    <MaterialCompanyTable
      ref="singleTable"
      v-if="Array.isArray(REGISTRY()) && REGISTRY().length"
      :reg-data="REGISTRY()"
      v-show="indexMenu == `1`"
    />
  </div>
</template>

<script>
import MaterialCompanyTable from "./MaterialRequestsTable";
import TabsMenu from "./TabsMenu";
import { mapActions, mapGetters } from "vuex";
import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import saveAs from "file-saver";
export default {
  components: {
    MaterialCompanyTable,
    TabsMenu
  },
  data() {
    return {
      activeName: "first",
      formVisible: false,
      activeIndex: "1",
      indexMenu: 1,
      companyId: null
    };
  },
  methods: {
    ...mapActions([
      "GET_PLATE_TYPES",
      "GET_REGISTRY_ALL",
      "GET_REGISTRY_COMPANY_COUNT",
      "RESET_HEAD"
    ]),
    ...mapGetters(["PLATE_TYPES_LIST", "REGISTRY", "COMPANY_COUNT"]),
    closeModal() {
      this.formVisible = false;
      if (this.companyId == null) {
        setTimeout(() => {
          this.GET_REGISTRY_ALL("delivered=false");
        }, 2000);
      } else {
        setTimeout(() => {
          this.GET_REGISTRY_ALL(`companyId=${this.companyId}`);
        }, 2000);
      }
    },
    test() {
      this.companyId = null;
      this.GET_REGISTRY_ALL("delivered=false");
    },
    all() {
      this.companyId = null;
      this.GET_REGISTRY_ALL("");
    },
    changeCompany(id) {
      this.GET_REGISTRY_ALL(`companyId=${id}`);
    },
    excel() {
      let ExcelJSWorkbook = new ExcelJS.Workbook();
      let worksheet = ExcelJSWorkbook.addWorksheet("ExcelJS sheet");

      const columns = this.$refs.singleTable.$children[0].$refs.tableHeader
        .columns;
      let arrCol = [];
      arrCol.push({
        label: "№",
        width: "50"
      });
      arrCol.push({
        label: columns[2].label,
        width: columns[2].width
      });
      arrCol.push({
        label: columns[3].label,
        width: columns[3].width
      });
      for (let i = 0; i < this.PLATE_TYPES_LIST().length; i++) {
        arrCol.push({
          label: this.PLATE_TYPES_LIST()[i].title,
          width: 100
        });
      }
      arrCol.push({
        label: "Пленка",
        width: 100
      });

      const alphabet = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
      ];
      for (let i = 0; i < arrCol.length; i++) {
        let currentColumnWidth = arrCol[i].width;
        worksheet.getColumn(alphabet[i]).width =
          currentColumnWidth !== undefined ? currentColumnWidth / 6 : 20;

        let cell = worksheet.getCell(`${alphabet[i]}2`);
        cell.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell.value = arrCol[i].label;
        cell.font = {
          name: "Times New Roman",
          size: 14,
          bold: true
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
      }
      const rowsTable = document.getElementsByClassName("el-table__row");
      for (let i = 0, col = 3; i < rowsTable.length; i++, col++) {
        let cell = worksheet.getCell(`${alphabet[0]}${col}`);
        let cell1 = worksheet.getCell(`${alphabet[1]}${col}`);
        let cell2 = worksheet.getCell(`${alphabet[2]}${col}`);
        let cell3 = worksheet.getCell(`${alphabet[3]}${col}`);
        let cell4 = worksheet.getCell(`${alphabet[4]}${col}`);
        let cell5 = worksheet.getCell(`${alphabet[5]}${col}`);
        let cell6 = worksheet.getCell(`${alphabet[5]}${col}`);
        let cell7 = worksheet.getCell(`${alphabet[6]}${col}`);
        let cell8 = worksheet.getCell(`${alphabet[7]}${col}`);
        let cell9 = worksheet.getCell(`${alphabet[8]}${col}`);
        let cell10 = worksheet.getCell(`${alphabet[9]}${col}`);
        let cell11 = worksheet.getCell(`${alphabet[10]}${col}`);
        let cell12 = worksheet.getCell(`${alphabet[11]}${col}`);
        let cell13 = worksheet.getCell(`${alphabet[12]}${col}`);
        let cell14 = worksheet.getCell(`${alphabet[13]}${col}`);
        let cell15 = worksheet.getCell(`${alphabet[14]}${col}`);
        cell.value = rowsTable[i].childNodes[0].outerText;
        cell1.value = rowsTable[i].childNodes[2].outerText;
        cell2.value = rowsTable[i].childNodes[3].outerText;
        cell3.value = rowsTable[i].childNodes[4].outerText;
        cell4.value = rowsTable[i].childNodes[5].outerText;
        cell5.value = rowsTable[i].childNodes[6].outerText;
        cell6.value = rowsTable[i].childNodes[7].outerText;
        cell7.value = rowsTable[i].childNodes[8].outerText;
        cell8.value = rowsTable[i].childNodes[9].outerText;
        cell9.value = rowsTable[i].childNodes[10].outerText;
        cell10.value = rowsTable[i].childNodes[11].outerText;
        cell11.value = rowsTable[i].childNodes[12].outerText;
        cell12.value = rowsTable[i].childNodes[13].outerText;
        cell13.value = rowsTable[i].childNodes[14].outerText;
        cell14.value = rowsTable[i].childNodes[15].outerText;
        cell15.value = rowsTable[i].childNodes[16].outerText;
        cell.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell1.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell2.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell3.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell4.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell5.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell6.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell7.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell8.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell9.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell10.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell11.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell12.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell13.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell14.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell15.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell1.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell2.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell3.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell4.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell5.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell6.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell7.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell8.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell9.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell10.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell11.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell12.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell13.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell14.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell15.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell1.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell2.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell3.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell4.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell5.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell6.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell7.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell8.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell9.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell10.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell11.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell12.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell13.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell14.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell15.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
      }
      ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `ТаблицаМатериалов.xlsx`
        );
      });
    }
  },
  created: async function() {
    this.RESET_HEAD("Список заявок на материал");
    await this.GET_PLATE_TYPES();
    await this.GET_REGISTRY_COMPANY_COUNT();
    await this.GET_REGISTRY_ALL("");

    if (!(Array.isArray(this.REGISTRY()) && this.REGISTRY().length)) {
      await this.GET_REGISTRY_ALL(`companyId=${this.COMPANY_COUNT()[0].id}`);
    }
    if (this.COMPANY_COUNT().length == 1) {
      this.companyId = this.COMPANY_COUNT()[0];
    }

    // this.companyId = this.COMPANY_COUNT()[0].id;
    // await this.GET_REGISTRY_ALL(`companyId=${this.COMPANY_COUNT()[0].id}`);
  }
};
</script>

<style scoped>
.head-table {
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.header-bth {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
