/* eslint-disable prettier/prettier */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Admin from "../views/Admin.vue";
import TableCompany from "../components/TableCompany.vue";
import TableUsers from "../components/TableUsers.vue";
import Company from "../views/Company.vue";
import TablePlate from "../components/TablePlate.vue";
import store from "../store";
import RegistryPlateTable from "../components/RegistryPlateTable.vue"
import jwt_decode from "jwt-decode";
import AuditPlates from "../components/AuditPlates.vue"
import AuditPlatesAgent from "../components/AuditPlatesAgent.vue"
import RegistryCompany from "../components/RegistryCompany.vue"
import MaterialRequests from "../components/MaterialRequests.vue"
import NewsTable from "../components/NewsTable.vue"
import NewsTableAdmin from "../components/NewsTableAdmin.vue"
import OtchetsAgent from "../components/OtchetAgent.vue"
import StatisticPage from "../components/StatisticPage/StatisticPage.vue"
import LogsAdmin from '../components/Logs.vue'
import StatMat from '../components/StaticticMaterial/AdminStatistic.vue'
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,

  },
  {
    path: "/company/:id",
    name: "Company",
    component: Company,
    redirect: { name: "CompanyPlate" },
    children: [
      {
        path: "plate",
        name: "CompanyPlate",
        component: TablePlate
      },
      {
        path: "statistic",
        name: "StatisticPage",
        component: StatisticPage,
        props: { adminAcc: false}
      },
      {
        path: "registry",
        name: "RegistryCompany",
        component: RegistryCompany
      },
      {
        path: "news",
        name: "NewsTable",
        component: NewsTable
      },
      {
        path:'audit/:plateId',
        name: "AuditAgent",
        component: AuditPlatesAgent
      },
      {
        path:'audit2/:plateId',
        name: "Audit2Agent",
        component: AuditPlatesAgent
      },
    ]
  },
  {
    path: "/admin",
    name: "Admin",
    redirect: { name: "CompanyAdmin" },
    component: Admin,

    children: [
      {
        path: "news",
        name: "NewsTableAdmin",
        component: NewsTableAdmin
      },
      {
        path: "otchets",
        name: "OtchetsAgent",
        component: OtchetsAgent
      },
      {
        path: "statistic",
        name: "StatisticPageAdmin",
        component: StatisticPage,
        props: { adminAcc: true}
      },
      {
        path: "logs",
        name: "LogsAdmin",
        component: LogsAdmin,
        props: { adminAcc: true}
      },

      {
        path: "company",
        name: "CompanyAdmin",
        component: TableCompany
      }, {
        path: "statistic-material",
        name: "STATMAT",
        component: StatMat
      },
      {
        path: "users",
        name: "UsersTable",
        component: TableUsers
      },
      {
        path: "users/:id",
        name: "UsersCompany",
        component: TableUsers
      },
      {
        path: "register/:id",
        name: "Register",
        component: RegistryPlateTable,
      },
      {
        path:'register/:id/audit/:plateId',
        name: "Audit",
        component: AuditPlates
      },
      {
        path:'register/:id/audit2/:plateId',
        name: "Audit2",
        component: AuditPlates
      },
      {
        path:'register/:id/audit3/:plateId',
        name: "Audit3",
        component: AuditPlates
      },
      {
        path: 'materials',
        name: 'MaterialRequests',
        component: MaterialRequests
      }
    ]
  }
];

// router.beforeEach((to, from, next) => {
//   if(to.matched.some(record => record.meta.requiresAuth)){
//     if ()
//   }
// });

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {

  if(to.name == null)
  {
    return next({
      name:"Home"
    });
  }

  if (to.name == "Home" && (store.getters.JWTTOKEN == "")) {
    return next();
  } else {

    const jwtDec = jwt_decode(store.getters.JWTTOKEN);

    if((to.matched[0].name == "Company") && to.params.id == jwtDec.companyId && jwtDec.userRole =="Агент") return next();

    if((to.matched[0].name == "Admin") && (jwtDec.userRole == "Админ" || jwtDec.userRole == "Младший Админ" || jwtDec.userRole == "Модератор")) return next();



    if (to.name === "Home" && store.getters.JWTTOKEN) {
      if (jwtDec.userRole == "Админ" || jwtDec.userRole == "Младший Админ" || jwtDec.userRole == "Модератор")
      return next({ name: "Admin" });
      if (jwtDec.userRole =="Агент")
      return next({
          name: "Company",
          params: { id: jwtDec.companyId }
        });
    }

  }
  ("object");
  next({
    name:"Home"
  });
});
export default router;
