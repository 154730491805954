import axios from "axios";
import Moment from "moment";
const getDefaultState = () => ({
  registryTable: Array,
  registryTableNew: Array,
  registryForm: Object,
  companyCount: Array
});

const state = getDefaultState();

const getters = {
  REGISTRY: state => {
    return state.registryTable;
  },
  REGISTRY_NEW: state => {
    return state.registryTableNew;
  },
  REGISTRY_FORM: state => {
    return state.registryForm;
  },
  COMPANY_COUNT: state => {
    return state.companyCount;
  }
};

const actions = {
  RESET_REGISTRY: async ({ commit }) => {
    commit("resetState");
  },
  // eslint-disable-next-line no-unused-vars
  POST_REGISTRY: async ({ commit }, data) => {
    await axios.post("/api/registry", data);
  },
  GET_REGISTRY: async ({ commit }, companyId) => {
    await axios.get(`/api/registry/${companyId}`).then(res => {
      commit("SET_REGISTRY", res.data);
    });
  },
  GET_REGISTRY_ALL: async ({ commit }, query) => {
    await axios.get(`/api/registry/all?${query}`).then(res => {
      commit("SET_REGISTRY", res.data);
    });
  },
  GET_REGISTRY_NEW: async ({ commit }, companyId) => {
    await axios.get(`/api/registry/new${companyId}`).then(res => {
      commit("SET_REGISTRY_NEW", res.data);
    });
  },
  GET_REGISTRY_COMPANY_COUNT: async ({ commit }) => {
    await axios.get(`/api/registry/company-count`).then(res => {
      commit("SET_REGISTRY_COMPANY_COUNT", res.data);
    });
  },
  // eslint-disable-next-line no-unused-vars
  PUT_REGISTRY: async ({ commit }, date) => {
    await axios.put(`/api/registry`, date);
  },

  // eslint-disable-next-line no-unused-vars
  PUT_STORES: async ({ commit }, date) => {
    await axios.post(`/api/registry/store`, date);
  },
  // eslint-disable-next-line no-unused-vars
  PUT_REGISTRY_USER: async ({ commit }, date) => {
    await axios.put("/api/registry/user", date);
  },
  SET_FORM_REGISTRY: async ({ commit }, form) => {
    commit("SET_FORM", form);
  },
  // eslint-disable-next-line no-unused-vars
  DEL_REGISTRY_USER: async ({ commit }, id) => {
    await axios.delete(`/api/registry/` + id);
  },
  SORT_REGISTRY: async ({ commit }, type) => {
    commit("SORT_REGISTRY_MUT", type);
  }
};

const mutations = {
  SORT_REGISTRY_MUT: (state, table) => {
    if (table.fieldName == "date") {
      if (table.sortingType == "descending") {
        state.registryTable.sort(
          (a, b) =>
            new Moment(b.date, "DD.MM.YYYY").format("YYYYMMDD") -
            new Moment(a.date, "DD.MM.YYYY").format("YYYYMMDD")
        );
      } else {
        state.registryTable.sort(
          (a, b) =>
            new Moment(a.date, "DD.MM.YYYY").format("YYYYMMDD") -
            new Moment(b.date, "DD.MM.YYYY").format("YYYYMMDD")
        );
      }
    }
  },
  SET_REGISTRY: (state, data) => {
    state.registryTable = data;
  },
  SET_REGISTRY_NEW: (state, data) => {
    state.registryTableNew = data;
  },
  SET_FORM: (state, data) => {
    state.registryForm = data;
  },
  SET_REGISTRY_COMPANY_COUNT: (state, data) => {
    state.companyCount = data;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
