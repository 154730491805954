<template>
  <section class="messenger">
    <main class="main-area">
      <section class="chat-section">
        <div class="messages-list" ref="listmsg">
          <ul class="messages">
            <template v-for="news in NEWS2()">
              <li class="msg" :key="news.id" v-if="news.addUser == 0">
                <div class="msg-text">
                  <h5 style="text-align: center; margin: 5px; font-size: 14px;" v-if="news.productId">
                  <el-link @click="goTo(news.productId)"><span style="font-size: 14px;
    font-weight: 600;
    color: black;">{{news.title}}</span></el-link>
                  </h5>
                  <h5 style="text-align: center; margin: 5px; font-size: 14px;" v-else>
                  {{ news.title }}
                  </h5>
                  <span v-html="newsText(news.text)"> </span><br />
                  <div class="" style="float: right; font-size: 10px;">
                   {{ infoUser(news) }}
                    {{ dateFormat(news.createdAt) }}
                    <div
                      class=""
                      v-if="news.user_list_confirmeds.length > 0"
                      style="display: inline; position: relative; margin-left: 5px; margin-right: 10px;"
                    >
                      <i
                        class="el-icon-check"
                        style="position: absolute;
                        top: 0;
                        left: 0;"
                      ></i>
                      <i
                        class="el-icon-check"
                        style="position: absolute;
                        top: 0;
                        left: -3px;
                        z-index: 10;"
                      ></i>
                    </div>
                  </div>
                </div>
              </li>
              <li class="msg me" v-else :key="news.id">
                <div class="msg-text">
                  <h5 style="text-align: center; margin: 5px; font-size: 14px;" v-if="news.productId">
                                    <el-link @click="goTo(news.productId)"><span style="font-size: 14px;
    font-weight: 600;
    color: black;">{{news.title}}</span></el-link>
                  </h5>
                  <h5 style="text-align: center; margin: 5px; font-size: 14px;" v-else>
                  {{ news.title }}
                  </h5>
                  <span v-html="newsText(news.text)"> </span><br />
                  <div class="" style="float: right; font-size: 10px;">
                    {{ infoUser(news) }}
                    {{ dateFormat(news.createdAt) }}

                    <div
                      class=""
                      v-if="news.user_list_confirmeds.length > 0"
                      style="display: inline; position: relative; margin-left: 5px; margin-right: 10px;"
                    >
                      <i
                        class="el-icon-check"
                        style="position: absolute;
                        top: 0;
                        left: 0;"
                      ></i>
                      <i
                        class="el-icon-check"
                        style="position: absolute;
                        top: 0;
                        left: -3px;
                        z-index: 10;"
                      ></i>
                    </div>
                  </div>
                </div>
              </li>
            </template>
          </ul>
        </div>
        <!-- <div class="chat-footer">
          <textarea
            class="chat-input-msg"
            placeholder="Your message here..."
          ></textarea>
          <button class="btn send-msg">Send</button>
        </div> -->
      </section>
    </main>
  </section>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  props: ["newsAll"],
  methods: {
    ...mapGetters(["NEWS2"]),
    newsText(text) {
      return text.replace(/\n/g, "<br>");
    },
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    infoUser(msg) {
      if (msg.user) {
        return msg.user.role.role + "(" + msg.user.login + ")"
      }
      return ""
    },
    goTo(id){

        this.$router.push({ name: "AuditAgent", params: { plateId: id } });
    }
  },
  created: function() {},
  updated: function() {
    document.getElementsByClassName(
      "messages"
    )[0].scrollTop = document.getElementsByClassName(
      "messages"
    )[0].scrollHeight;
  }
};
</script>

<style scoped>
.messenger {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
}

.messenger-header {
  color: #fff;
  background: #4c90ff;
  padding: 8px;
  line-height: calc(8px * 5);
  font-weight: bold;
  text-align: center;
}

.main-area {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
}

.users-section {
  width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgb(230, 15, 15);
}

.users,
.messages {
  height: 440px;
  overflow: hidden;
  overflow-y: auto;
}

.users:empty:after {
  content: "No users in chat.";
  white-space: pre-wrap;
  text-align: center;
  font-style: italic;
  color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - calc(8px * 15));
  padding: 8px;
}

.user {
  padding: 8px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user:hover {
  background: rgba(0, 0, 0, 0.03);
  cursor: pointer;
}

.user-info {
  width: 100%;
  margin: 0 8px;
}

.user-info > * {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-avatar {
  min-width: calc(8px * 5);
  min-height: calc(8px * 5);
  max-width: calc(8px * 5);
  max-height: calc(8px * 5);
  background: rgb(255, 255, 255);
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.user-fullname {
  font-weight: bold;
  font-size: 1.1em;
  text-transform: capitalize;
}

.user-email {
  font-size: 0.9em;
  color: rgb(255, 255, 255);
}

.user-actions {
  visibility: hidden;
}

.user:hover .user-actions {
  visibility: visible;
}

.user-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(8px * 6);
  border-top: 1px solid #ccc;
}

.btn {
  background: rgb(255, 255, 255);
  border: none;
  outline: none;
  padding: 7px 14px;
  border-radius: 5em;
  color: #4c90ff;
  font-weight: bold;
}

.btn:hover {
  cursor: pointer;
  opacity: 0.6;
}

.btn:active {
  opacity: 0.3;
}

.chat-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.messages {
  padding: 8px;
  padding-bottom: calc(8px * 4);
}

.messages:empty:after {
  content: "Нет сообщений для отображения.";
  white-space: pre-wrap;
  text-align: center;
  font-style: italic;
  color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 8px * 17);
  padding: 8px;
}

.msg {
  display: flex;
  align-items: flex-start;
  min-width: 10px;
  width: 40vw;
  max-width: 600px;
  font-size: 14px;
  margin-bottom: calc(8px * 2);
}

.msg-text {
  font-size: 14px;
  margin: 0 8px;
  padding: 8px;
  background: #ffffff;
  border-radius: 8px;
  user-select: text;
  color: rgb(0, 0, 0);
}

.msg-text p {
  user-select: text;
}

.msg-text p + p {
  margin-top: 8px;
}

.msg-time {
  align-self: flex-end;
  color: rgb(0, 0, 0);
  font-size: 0.8em;
  visibility: hidden;
  font-style: italic;
}

.msg.me {
  flex-direction: row-reverse;
  float: right;
  padding-left: 50vw;
}

.msg.me .msg-text {
  background: #def1fd;
  color: rgb(0, 0, 0);
}

.msg.me .msg-time {
  margin-bottom: calc(8px / 2);
}

.msg:hover .msg-time {
  visibility: visible;
}

.status-msg {
  text-align: center;
  width: 100%;
  color: #ccc;
  margin-bottom: 8px;
  display: block;
  float: left;
  font-size: 0.8em;
}

.messages-list {
  position: relative;
  background: #74b5e0;
  height: 500px;
}

.typing-status {
  position: absolute;
  bottom: 8px;
  left: 8px;
  font-size: 0.7em;
  font-style: italic;
  padding: 8px;
  border-radius: 8px;
  background: #eee;
  color: #999;
}

.typing-status:empty {
  visibility: hidden;
}

.users:empty .typing-status {
  display: none;
}

.chat-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(8px * 6);
  border-top: 1px solid #ccc;
}

.chat-input-msg {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 8px;
  resize: none;
}

.send-msg {
  margin: 8px;
}
</style>
