<template>
  <div class="container ">
    <div class="head-table">
      <TabsMenu :is-admin="false" :active-index2="INDEX()" v-if="isComputer" />

      <div
        v-if="isComputer"
        class=""
        style="display: flex; height: 24px; align-items: baseline;"
      >
        <div class="" style="height: 24px;">
          <el-input
            style="height: 20px;"
            v-model="search"
            :disabled="search2.length > 0"
            @input="searchPlate"
            size="small"
            placeholder="Поиск по номеру"
          />
        </div>
        <div class="" style="height: 24px;">
          <el-input
            style="height: 20px;"
            v-model="search2"
            :disabled="search.length > 0"
            @input="searchPlate2"
            size="small"
            placeholder="Поиск по Фамилии, ООО, ИП"
          />
        </div>
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          style="margin-left: 5px;"
          @click.stop="openDialogPlate"
          >Добавить знак</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click.stop="openDialogDefect"
          >Добавить брак</el-button
        >

        <el-button
          size="mini"
          type="primary"
          icon="el-icon-tickets"
          @click="excelDownload = true"
          >Excel</el-button
        >
        <el-button
          size="mini"
          v-if="REPORT_PERM()"
          type="primary"
          icon="el-icon-tickets"
          @click.stop="contractDialog = true"
          >Отчет</el-button
        >
      </div>
      <div class="" style="width: 90%; " v-else>
        <div class="" style="margin-left: 30%;">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-tickets"
            @click.stop="mobileTable = !mobileTable"
            >Статистика по пластинам</el-button
          >
        </div>
        <el-input
          style="margin: 10px"
          v-model="search"
          @input="searchPlate"
          size="small"
          placeholder="Поиск по номеру"
        />
        <el-input
          style="margin: 10px"
          v-model="search2"
          :disabled="search.length > 0"
          @input="searchPlate2"
          size="small"
          placeholder="Поиск по Фамилии, ООО, ИП"
        />
      </div>
    </div>

    <div
      v-bind:class="{
        'table-company': showHead,
        'table-company2': !showHead,
        'table-companyM': !isComputer
      }"
    >
      <el-table
        @sort-change="changeTableSort"
        stripe
        :cell-class-name="tableCellClassName"
        ref="table"
        v-if="Array.isArray(dataTable) && dataTable.length && renderComponent"
        size="mini"
        height="100%"
        highlight-current-row
        @filter-change="test"
        class="test"
        style="width: 100%;  "
        :data="dataTable"
        :row-class-name="tableRowClassName"
        @cell-click="highlightRow"
        @cell-dblclick="editPlateDb"
        @selection-change="handleSelectionChange"
      >
        >
        <el-table-column
          prop="status"
          type="index"
          width="60px"
          :filters="filterStatus"
          :filter-method="filterHandler"
        >
          <template slot="header" slot-scope="">
            <span style=" font-size: 10px;" @click.stop="forceRerender">{{
              textLabels[0]
            }}</span>
            <i
              class="el-icon-setting"
              style="margin-right: 2px"
              @click.stop="forceRerender"
            ></i>
          </template>
          <template slot-scope="scope">
            <div class="">
              <div style="text-align: center;">
                {{ scope.row.num }}
              </div>
              <div style="text-align: center;">
                <i
                  class="el-icon-unlock"
                  v-if="
                    scope.row.statusProductId == 1 ||
                      scope.row.statusProductId == 3
                  "
                >
                </i>
                <i class="el-icon-lock" v-if="scope.row.statusProductId == 2">
                </i>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="40">
          <template slot-scope="scope">
            <div>
              <el-button
                size="mini"
                type="text"
                v-if="scope.row.statusProductId"
                @click.stop="editPlate(scope.row.id, scope.row.statusProductId)"
              >
                <i class="el-icon-edit-outline bth-style"> </i>
              </el-button>
              <el-button
                size="mini"
                type="text"
                v-else
                @click.stop="
                  editPlateDef(scope.row.id, scope.row.statusProductId)
                "
              >
                <i class="el-icon-edit-outline bth-style"> </i>
              </el-button>
            </div>
            <div>
              <el-button
                size="mini"
                type="text"
                v-if="scope.row.statusProductId"
                @click.stop="copyPlate(scope.row.id, true)"
              >
                <i class="el-icon-document-copy bth-style"> </i>
              </el-button>
              <el-button
                type="text"
                size="mini"
                v-else
                @click.stop="copyPlateDef(scope.row.id, false)"
              >
                <i class="el-icon-document-copy bth-style"> </i>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          prop="date_create"
          :filters="filterDate"
          :sortable="'custom'"
          :filter-method="filterHandler"
          column-key="date"
          :label="textLabels[1]"
        ></el-table-column>
        <el-table-column
          width="100"
          :label="textLabels[2]"
          prop="plateTypeId"
          :filters="filterPlate"
          column-key="plateTypeId"
          :filter-method="filterHandler"
          :sortable="'custom'"
        >
          <template slot-scope="scope">
             <el-popover
    placement="top-start"
    title="Ошибка"
    :disabled="scope.row.statusProductId != 3"
    width="200"
    trigger="hover"
    :content="lolkek(scope.row)">
    <div slot="reference" >
      <div class="" style="font-weight: bold;">
              {{
                scope.row.plateTypeId
                  ? PLATE_TYPES_LIST()[scope.row.plateTypeId - 1].title
                  : ""
              }}
              {{
                scope.row.hasOwnProperty("document_statement") ? "" : "(Брак)"
              }}
            </div>

            <div>
              <el-button
                style="font-weight: bold; color: #409EFF; font-size: 13px;"
                size="mini"
                v-if="scope.row.statusProductId"
                type="text"
                @click.stop="editPlate(scope.row.id, scope.row.statusProductId)"
              >
                {{ scope.row.license_plate }}
              </el-button>
              <el-button
                style="font-weight: bold; color: #409EFF; font-size: 13px;"
                size="mini"
                v-else
                type="text"
                @click.stop="
                  editPlateDef(scope.row.id, scope.row.statusProductId)
                "
              >
                {{ scope.row.license_plate }}
              </el-button>
            </div>

            {{ scope.row.serial_number }}
    </div>
  </el-popover>

          </template>
        </el-table-column>
        <el-table-column width="109" :label="textLabels[3]">
          <template slot-scope="scope">
            {{
              scope.row.typeDocumentTransportId
                ? DOCUMENT_TYPES_LIST()[scope.row.typeDocumentTransportId - 1]
                    .title
                : ""
            }}<br />
            {{ scope.row.document }}
            <br />
            {{ scope.row.date_issuing_transport_document }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="isComputer"
          header-align="center"
          :label="textLabels[4]"
        >
          <el-table-column width="140" :label="textLabels[5]">
            <template slot-scope="scope">
              <div v-if="scope.row.ownerTypeId == 1">
                {{
                  scope.row.individual_document_surname +
                    " " +
                    scope.row.individual_document_name +
                    " " +
                    scope.row.individual_document_patronymic
                }}
              </div>
              <div v-else>
                {{ scope.row.entity_document_name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="120" prop="name" :label="textLabels[6]">
            <template slot-scope="scope">
              <div v-if="scope.row.ownerTypeId == 1">
                <div v-if="scope.row.typeDocumentOwnerId == 1">
                  {{
                    scope.row.typeDocumentOwnerId
                      ? OWNER_TYPES_DOC_LIST()[
                          scope.row.typeDocumentOwnerId - 1
                        ].title
                      : " "
                  }}
                </div>
                <div v-else>
                  {{ scope.row.individual_document_other_type }}
                </div>
                {{ scope.row.individual_document_serial_number }}<br />
                {{ scope.row.individual_document_date_issuing }}
              </div>
              <div v-else>
                {{ scope.row.entity_document_inn }}<br />
                {{ scope.row.entity_document_orgn }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          v-if="isComputer"
          header-align="center"
          prop="name"
          :label="textLabels[7]"
        >
          <el-table-column width="145" prop="name" :label="textLabels[8]">
            <template slot-scope="scope">
              {{
                scope.row.representative_individual_document_surname
                  ? scope.row.representative_individual_document_surname +
                    " " +
                    scope.row.representative_individual_document_name +
                    " " +
                    scope.row.representative_individual_document_patronymic
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column width="120" prop="name" :label="textLabels[9]">
            <template slot-scope="scope">
              <div v-if="scope.row.representative_document_type_ownerId == 1">
                {{
                  scope.row.typeDocumentOwnerId
                    ? OWNER_TYPES_DOC_LIST()[scope.row.typeDocumentOwnerId - 1]
                        .title
                    : ""
                }}
              </div>
              <div v-else>
                {{ scope.row.representative_individual_document_other_type }}
              </div>
              {{ scope.row.representative_individual_document_serial_number
              }}<br />
              {{ scope.row.representative_individual_document_date_issuing }}

              <div>
                {{ scope.row.representative_entity_document_inn }}<br />
                {{ scope.row.representative_entity_document_orgn }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          v-if="isComputer"
          width="100"
          prop="date_issuing"
          :label="textLabels[10]"
        >
        </el-table-column>
        <el-table-column
          width="50"
          prop="name"
          v-if="isComputer"
          :label="textLabels[11]"
        >
          <template slot-scope="scope">
            <el-button
              v-if="
                scope.row.hasOwnProperty('document_statement') &&
                  scope.row.documents_statements.length > 0
              "
              type="warning"
              icon="el-icon-folder-opened"
              size="mini"
              circle
              @click.stop="onPreview(scope.row.documents_statements)"
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column
          v-if="isComputer"
          width="50"
          prop="name"
          :label="textLabels[12]"
        >
          <template slot-scope="scope">
            <el-button
              type="warning"
              v-if="
                scope.row.hasOwnProperty('document_statement') &&
                  scope.row.documents_statement_tcs.length > 0
              "
              icon="el-icon-folder-opened"
              size="mini"
              circle
              @click.stop="onPreview(scope.row.documents_statement_tcs)"
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column
          v-if="isComputer"
          width="50"
          prop="count_plate"
          :label="textLabels[13]"
        ></el-table-column>
      </el-table>
      <div class="" v-else>
        <h1 style="text-align: center;">Нету данных</h1>
      </div>
      <div class="" style="text-align: center;">
        <el-pagination
          small
          layout="prev, pager, next"
          :page-size="250"
          :current-page.sync="currentPage"
          :total="PLATES().length"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :modalAppendToBody="false"
      title="Добавить знак"
      :show-close="false"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="1200px"
    >
      <PlateForm
        v-on:updDialog="dialogUpd"
        :plate-id="plateId"
        :status-id="statusId"
        :new-plate="newPlate"
      />
    </el-dialog>
    <el-dialog
      :modalAppendToBody="false"
      title="Статистика по пластинам"
      :show-close="false"
      :visible.sync="mobileTable"
      width="500px"
    >
      <div
        class=""
        style="width: 468px;
    overflow: scroll;"
      >
        <PlateCountTable />
      </div>
    </el-dialog>
    <el-dialog
      :modalAppendToBody="false"
      title="Отчет"
      :show-close="false"
      :visible.sync="contractDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="600px"
    >
      <ContractForm v-on:closeModalReport="closeReport" />
    </el-dialog>

    <el-dialog
      :modalAppendToBody="false"
      title="Добавить брак"
      :show-close="false"
      :visible.sync="defectFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <DefectForm
        :plate-id="plateId"
        :status-id="statusId"
        :new-plate="newPlate"
        v-on:closeDefect="updDefect"
      />
    </el-dialog>
    <el-dialog
      :modalAppendToBody="false"
      title="Выгрузка пластин"
      :visible.sync="excelDownload"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
      width="700px"
    >
    <el-form :inline="true" :v-model="excelForm">
      <el-form-item label="От">
          <el-input
            v-model="excelForm.start"
            size="small"
            placeholder="дд.мм.гггг"
            v-mask="'##.##.####'"
          ></el-input>
      </el-form-item>
      <el-form-item label="До">
          <el-input
            v-model="excelForm.end"
            size="small"
            placeholder="дд.мм.гггг"
            v-mask="'##.##.####'"
          ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="excel2('')">Скачать</el-button>
        <el-button size="small" type="danger" @click="excel2('all')">Скачать всё</el-button>
      </el-form-item>
      <el-form-item label="Фильтры по статусу" class="border-check"> 
        <el-checkbox-group v-model="excelForm.filterStatus">
        <el-checkbox v-for="status in filterStatus" :label="status.value" :key="status.value">{{status.text}}</el-checkbox>
  </el-checkbox-group>
      </el-form-item>
      <el-form-item label="Фильтры по типу пластин">
        <el-checkbox-group v-model="excelForm.filterPlate">
        <el-checkbox v-for="plate in filterPlate" :label="plate.value" :key="plate.value" >{{plate.text}}</el-checkbox>
  </el-checkbox-group>
      </el-form-item>
    </el-form>
    </el-dialog>
    <MobileUpload
      :dialogMobileForm="dialogMobileForm"
      :productId="plateId"
      :productPhoto="productPhoto"
      v-on:closeDialogMobile="closeDialogMobile"
      v-on:updateCloseMobile="updateCloseMobile"
    />
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgList"
      :initial-index="indexImg"
    />
  </div>
</template>

<script>
import { MessageBox } from "element-ui";
import PlateForm from "../components/PlateForm";
import DefectForm from "../components/DefectForm";
import { mapActions, mapGetters } from "vuex";
import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import saveAs from "file-saver";
import TabsMenu from "../components/TabsMenu";
import ElImageViewer from "../../node_modules/element-ui/packages/image/src/image-viewer";
import Moment from "moment";
import ContractForm from "./ContractForm.vue";
import MobileUpload from "./MobileUpload.vue";
import PlateCountTable from "./PlateCountTable.vue";
export default {
  components: {
    PlateForm,
    DefectForm,
    ElImageViewer,
    TabsMenu,
    ContractForm,
    MobileUpload,
    PlateCountTable
  },
  data() {
    return {
      timeoutId: null,
      excelDownload: false,
      productPhoto: {
        documents_statements: [],
        documents_statement_tcs: []
      },
      timeoutId2: null,
      mobileTable: false,
      dialogMobileForm: false,
      multipleSelection: [],
      isComputer: true,
      showViewer: false,
      indexImg: 0,
      imgList: [],
      dialogFormVisible: false,
      defectFormVisible: false,
      plateId: null,
      newPlate: false,
      currentRow: null,
      excelForm: {
        start: "",
        end: "",
        companyId : this.$route.params.id,
        filterPlate: [],
        filterStatus: [],
      },
      showHead: true,
      currentPage: 1,
      formLabelWidth: "120px",
      statusId: null,
      dataTable: null,
      filterDate: [],
      filterPlate: [],
      filterStatus: [],
      filterPlateActive: new Set(),
      filterStatusActive: new Set(),
      search: "",
      search2: "",
      fullscreen: false,
      contractDialog: false,
      renderComponent: true,
      textLabels: [
        "№",
        "Дата изготовления гос. регистрационного знака",
        "Cведения о государственном регистрационном номере транспортного средства",
        "Документ, идентифицирующий транспортное средство",
        "Сведения о владельце траспортного средства",
        "ФИО(при наличии)/наимование юридического лица(при наличии)",
        "Вид, серия, номер, дата выдачи документа, удостоверяющего личность /ИНН/ОГРН",
        "Сведения о представителя владельца траспортного средства",
        "ФИО(при наличии) представителя, в том числе представителя специциалированной организации",
        "Вид, серия, номер, дата выдачи документа, удостоверяющего личность",
        "Дата выдачи гос. регистрациионного знака",
        "Скан заявления",
        "Скан документа ТС",
        "Кол-во пластин"
      ]
    };
  },
  methods: {
    ...mapActions([
      "GET_PLATES_ALL",
      "SORT_PLATE_DATE",
      "GET_DOCUMENT_TYPES",
      "GET_OWNER_TYPES",
      "GET_PLATE_TYPES",
      "GET_OWNER_DOCUMENT_TYPES",
      "GET_PLATES_DEFECT",
      "POST_DEFECT_PLATES",
      "POST_PLATES",
      "GET_PLATES_COUNT",
      "RESET_TABLE_PLATE",
      "COPY_PLATE_POST",
      "GET_STATUS",
      "RESET_HEAD",
      "SET_SCROLL",
      "RESET_SCROLL",
      "SET_ROWINDEX",
      "SET_PAGENUMBER",
      "GET_LOGS",
      "GET_COMPANY_NAME"
    ]),
    ...mapGetters([
      "PLATES",
      "ROWINDEX",
      "JWTTOKEN",
      "OWNER_TYPES_LIST",
      "DOCUMENT_TYPES_LIST",
      "PLATE_TYPES_LIST",
      "OWNER_TYPES_DOC_LIST",
      "STATUS_PLATE",
      "INDEX",
      "INFO_COMPANY",
      "REPORT_PERM",
      "SCROLLTOP",
      "PAGENUMBER",
      "COMPANY_NAME"
    ]),
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    closeDialogMobile() {
      this.dialogMobileForm = false;
    },
    async updateCloseMobile() {
      await this.GET_PLATES_ALL(this.$route.params.id);
      await this.GET_LOGS();
      await this.startSort();
      this.dataTable = this.PLATES().slice(0, 250);
    },
    setCurrent(row) {
      this.$refs.table.setCurrentRow(row);
    },
    highlightRow(row) {
      if (!this.timeoutId) {
        this.timeoutId = setTimeout(() => {
          if (this.currentRow == row) {
            this.currentRow = null;
            this.RESET_SCROLL();
            this.SET_PAGENUMBER(1);
            return this.setCurrent();
          }
          this.setCurrent(row);
          this.currentRow = row;
          this.SET_SCROLL({
            name: this.$route.name,
            top: this.$refs["table"].bodyWrapper.scrollTop
          });
          this.SET_PAGENUMBER(this.currentPage);
          this.SET_ROWINDEX(row.id);
          this.timeoutId = null;
        }, 500);
      }
    },
    tableRowClassName() {
      return "plate-row";
      // if (row.statusProductId == 3) {
      //   return "warning-row ";
      // }
      // if (row.statusProductId == 4) {
      //   return "pereproverit-row ";
      // }
      // return "";
    },
    searchPlate() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (this.search) {
          this.$http
            .get("/api/plate/searche-plate?searchMode=1&search=" + this.search)
            .then(res => {
              this.dataTable = res.data;
            });
        } else {
          this.dataTable = this.PLATES().slice(0, 250);
          this.currentPage = 1;
        }
      }, 1000);
    },
    searchPlate2() {
      if (this.timeoutId2) {
        clearTimeout(this.timeoutId2);
      }
      this.timeoutId2 = setTimeout(() => {
        if (this.search2) {
          this.$http
            .get("/api/plate/searche-plate?searchMode=2&search=" + this.search2)
            .then(res => {
              this.dataTable = res.data;
            });
        } else {
          this.dataTable = this.PLATES().slice(0, 250);
          this.currentPage = 1;
        }
      }, 1000);
      //   this.dataTable = this.PLATES().slice(0, 250);
      //   this.currentPage = 1;
    },
    openDialogPlate() {
      this.newPlate = true;
      this.plateId = null;
      this.statusId = 0;
      this.fullscreen = false;
      this.dialogFormVisible = true;
    },
    openDialogDefect() {
      this.newPlate = true;
      this.plateId = null;
      this.statusId = 1;
      this.defectFormVisible = true;
    },
    editPlate(id) {
      if (this.isComputer) {
        this.plateId = id;
        this.newPlate = false;
        this.statusId = 0;

        this.RESET_HEAD("Проверка знака");
        this.$router.push({ name: "AuditAgent", params: { plateId: id } });
      } else {
        const product = this.PLATES().find(el => {
          if (el.id == id) {
            return el;
          }
        });
        this.plateId = id;
        this.productPhoto = {
          documents_statements: product.documents_statements,
          documents_statement_tcs: product.documents_statement_tcs
        };
        this.dialogMobileForm = true;
      }
    },
    editPlateDb(row) {
      clearTimeout(this.timeoutId);
      if (this.isComputer) {
        this.plateId = row.id;
        this.newPlate = false;
        this.statusId = 0;

        this.RESET_HEAD("Проверка знака");
        this.$router.push({ name: "AuditAgent", params: { plateId: row.id } });
      } else {
        this.plateId = row.id;
        this.dialogMobileForm = true;
      }
    },
    editPlateDef(id) {
      this.plateId = id;
      this.newPlate = false;
      this.statusId = 1;
      this.PLATES().forEach(el => {
        if (el.id == id && el.statusProductId == undefined) {
          this.defectFormVisible = true;
        }
      });
    },
    closeReport() {
      this.contractDialog = false;
    },
    dialogUpd() {
      this.dialogFormVisible = false;
      this.plateId = null;
      setTimeout(() => {
        this.GET_PLATES_COUNT(this.$route.params.id);
      }, 2000);
      setTimeout(async () => {
        await this.GET_PLATES_ALL(this.$route.params.id);
        this.dataTable = this.PLATES().slice(0, 250);
        await this.startSort();
        this.currentPage = 1;
        await this.GET_LOGS();
      }, 2000);
    },
    excel2(type){
             const loading = this.$loading({
            lock: true,
            text: "Загрузка",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          if (type == "all"){
            this.excelForm.type = "all"
          } else {
            this.excelForm.type = ""
          }
          console.log(this.excelForm)
          this.$http
            .post("/api/printout/admin", this.excelForm, {
              responseType: "blob"
            })
            .then(res => {
              saveAs(
                res.data,
                `${this.INFO_COMPANY().head_company} Полная.xlsx`
              );

            });
          loading.close();

    },
    updDefect() {
      this.defectFormVisible = false;
      this.plateId = null;
      setTimeout(() => {
        this.GET_PLATES_COUNT(this.$route.params.id);
      }, 1000);
      setTimeout(async () => {
        await this.GET_PLATES_ALL(this.$route.params.id);
        this.dataTable = this.PLATES().slice(0, 250);
        await this.startSort();
        this.currentPage = 1;
        await this.GET_LOGS();
      }, 2000);
    },
    handleCurrentChange(val) {
      this.dataTable = this.PLATES().slice(
        val == 1 ? 0 : (val - 1) * 250,
        250 * val
      );
    },
    copyPlate(id) {
      MessageBox.confirm("Сделать копию знака?", "Копирование", {
        confirmButtonText: "Скопировать",
        cancelButtonText: "Отменить",
        type: "warning"
      }).then(() => {
        this.plateId = id;
        this.newPlate = true;
        this.statusId = 0;
        this.dialogFormVisible = true;

        setTimeout(() => {
          this.GET_PLATES_COUNT(this.$route.params.id);
          this.GET_LOGS();
        }, 2000);
        setTimeout(() => {
          this.GET_PLATES_ALL(this.$route.params.id);
          this.startSort();
        }, 2000);
      });
    },
    test(e) {
      if (e.date) {
        if (e.date.length == 0) {
          this.dataTable = this.PLATES().slice(0, 250);
          return;
        }
      }
      if (e.plateTypeId) {
        if (e.plateTypeId.length == 0) {
          this.dataTable = this.PLATES().slice(0, 250);
          return;
        }
      }
    },
    copyPlateDef(id) {
      MessageBox.confirm("Сделать копию знака?", "Копирование", {
        confirmButtonText: "Скопировать",
        cancelButtonText: "Отменить",
        type: "warning"
      }).then(() => {
        this.plateId = id;
        this.newPlate = true;
        this.statusId = 1;
        this.defectFormVisible = true;
        // this.COPY_PLATE_POST({
        //   id,
        //   typePlate: false
        // });
        setTimeout(() => {
          this.GET_PLATES_COUNT(this.$route.params.id);
          this.GET_LOGS();
        }, 2000);
        setTimeout(() => {
          this.GET_PLATES_ALL(this.$route.params.id);
          this.startSort();
        }, 2000);
      });
    },
    closeViewer() {
      this.showViewer = false;
    },
    changeTableSort(column) {
      this.SORT_PLATE_DATE({
        fieldName: column.prop,
        sortingType: column.order
      });
      this.dataTable = this.PLATES().slice(0, 250);
      this.currentPage = 1;
    },
    dateArray() {
      Moment.locale("ru");

      for (let i = 0; i < 6; i++) {
        this.filterDate.push({
          text: Moment()
            .startOf("month")
            .subtract(i, "months")
            .format("MMMM YYYY"),
          value: Moment()
            .startOf("month")
            .subtract(i, "months")
            .format("YYYYMMDD")
        });
      }
    },
    plateArray() {
      this.PLATE_TYPES_LIST().map(el => {
        this.filterPlate.push({
          text: el.title,
          value: el.id
        });
      });
      this.filterPlate.push({
        text: "Брак",
        value: "defect"
      });
    },
    statusArray() {
      this.STATUS_PLATE().map(el => {
        this.filterStatus.push({
          text: el.title,
          value: el.id
        });
      });
    },

    excel() {
      console.log("tabble 4")
      let ExcelJSWorkbook = new ExcelJS.Workbook();
      let worksheet = ExcelJSWorkbook.addWorksheet("Отчет");
      //       worksheet.mergeCells("A1:A2");
      //
      const columns = this.$refs.table.$refs.tableHeader.columns;
      let arrCol = [];
      arrCol.push({
        label: "№",
        width: 50
      });
      for (let i = 2; i < columns.length; i++) {
        if (i != 10 && i != 11 && i != 1) {
          arrCol.push({
            label: columns[i].label,
            width: columns[i].width
          });
        }
      }
      const alphabet = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
      ];
      for (let i = 0; i < arrCol.length; i++) {
        if (i == 4 || i == 6) {
          worksheet.mergeCells(`${alphabet[i]}1:${alphabet[i + 1]}1`);
          let cell2 = worksheet.getCell(`${alphabet[i]}1`);
          if (i == 4)
            cell2.value = "Сведения о владельце траспортного средства";
          else
            cell2.value =
              "Сведения о представителя владельца траспортного средства";
          cell2.font = {
            name: "Times New Roman",
            size: 14,
            bold: true
          };
          cell2.alignment = {
            wrapText: true,
            vertical: "middle",
            horizontal: "center"
          };
          cell2.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          };
        }
        let currentColumnWidth = arrCol[i].width;
        worksheet.getColumn(alphabet[i]).width =
          currentColumnWidth !== undefined ? currentColumnWidth / 6 : 20;
        if (i < 4 || i > 7)
          worksheet.mergeCells(`${alphabet[i]}1:${alphabet[i]}2`);

        let cell = worksheet.getCell(`${alphabet[i]}2`);
        cell.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell.value = arrCol[i].label;
        cell.font = {
          name: "Times New Roman",
          size: 14,
          bold: true
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
      }

      const rowsTable = document.getElementsByClassName("plate-row");

      for (let i = 0, col = 3; i < rowsTable.length; i++, col++) {
        let cell = worksheet.getCell(`${alphabet[0]}${col}`);
        let cell1 = worksheet.getCell(`${alphabet[1]}${col}`);
        let cell2 = worksheet.getCell(`${alphabet[2]}${col}`);
        let cell3 = worksheet.getCell(`${alphabet[3]}${col}`);
        let cell4 = worksheet.getCell(`${alphabet[4]}${col}`);
        let cell5 = worksheet.getCell(`${alphabet[5]}${col}`);
        let cell6 = worksheet.getCell(`${alphabet[6]}${col}`);
        let cell7 = worksheet.getCell(`${alphabet[7]}${col}`);
        let cell8 = worksheet.getCell(`${alphabet[8]}${col}`);
        let cell9 = worksheet.getCell(`${alphabet[9]}${col}`);

        cell.value = rowsTable[i].childNodes[0].outerText;
        cell1.value = rowsTable[i].childNodes[2].outerText;
        cell2.value = rowsTable[i].childNodes[3].outerText;
        cell3.value = rowsTable[i].childNodes[4].outerText;
        cell4.value = rowsTable[i].childNodes[5].outerText;
        cell5.value = rowsTable[i].childNodes[6].outerText;
        cell6.value = rowsTable[i].childNodes[7].outerText;
        cell7.value = rowsTable[i].childNodes[8].outerText;
        cell8.value = rowsTable[i].childNodes[9].outerText;
        cell9.value = rowsTable[i].childNodes[12].outerText;
        cell.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell1.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell2.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell3.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell4.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell5.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell6.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell7.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell8.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell9.alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center"
        };
        cell.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell1.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell2.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell3.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell4.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell5.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell6.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell7.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell8.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell9.font = {
          name: "Times New Roman",
          size: 14,
          bold: false
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell1.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell2.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell3.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell4.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell5.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell6.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell7.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell8.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell9.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
      }
      ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `ТаблицаПластин.xlsx`
        );
      });
    },
    onPreview(arr) {
      this.showViewer = true;
      this.imgList = [];
      arr.map(el => {
        this.imgList.push("/" + el.url);
      });
    },
    excelLol1(i) {
      let str = "";
      if (this.PLATES()[i].ownerTypeId == 1) {
        if (this.PLATES()[i].typeDocumentOwnerId == 1) {
          str += this.PLATES()[i].typeDocumentOwnerId
            ? this.OWNER_TYPES_DOC_LIST()[
                this.PLATES()[i].typeDocumentOwnerId - 1
              ].title
            : " ";
        } else {
          str += this.PLATES()[i].individual_document_other_type;
        }
        str +=
          this.PLATES()[i].individual_document_serial_number +
          "\n" +
          this.PLATES()[i].individual_document_date_issuing;
      } else {
        str +=
          this.PLATES()[i].entity_document_inn +
          "\n" +
          this.PLATES()[i].entity_document_orgn;
      }
      return str;
    },
    excelLol2(i) {
      let str = "";
      if (this.PLATES()[i].representative_document_type_ownerId == 1) {
        if (this.PLATES()[i].typeDocumentOwnerId == 1) {
          str += this.PLATES()[i].typeDocumentOwnerId
            ? this.OWNER_TYPES_DOC_LIST()[
                this.PLATES()[i].typeDocumentOwnerId - 1
              ].title
            : " ";
        } else {
          str += this.PLATES()[i].representative_individual_document_other_type;
        }
        str +=
          this.PLATES()[i].representative_individual_document_serial_number +
          "\n" +
          this.PLATES()[i].representative_individual_document_date_issuing;
      } else {
        str +=
          this.PLATES()[i].representative_entity_document_inn +
          "\n" +
          this.PLATES()[i].representative_entity_document_orgn;
      }
      return str;
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      if (property == "plateTypeId") {
        if (this.filterPlateActive.has(value)) {
          this.filterPlateActive.delete(value)
        } else {
          this.filterPlateActive.add(value)
        }
        if (value === "defect") {
          // eslint-disable-next-line no-prototype-builtins
          if (!row.hasOwnProperty("document_statement")) {
            return true;
          }
        } else {
          if (
            row[property] === value &&
            // eslint-disable-next-line no-prototype-builtins
            row.hasOwnProperty("document_statement")
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
      if (property == "date_create") {
        const start = Moment(value).format("YYYYMMDD");
        const end = Moment(value)
          .endOf("month")
          .format("YYYYMMDD");
        this.dataTable = this.PLATES();
        return (
          start <= Moment(row.date_create, "DD.MM.YYYY").format("YYYYMMDD") &&
          end >= Moment(row.date_create, "DD.MM.YYYY").format("YYYYMMDD")
        );
      }
      if (property == "status") {
        if (this.filterStatusActive.has(value)) {
          this.filterStatusActive.delete(value)
        } else {
          this.filterStatusActive.add(value)
        }
        if (value == row.statusProductId) return true;
        else return false;
      }
    },
    tableCellClassName({ row }) {
      if (
        !this.isComputer &&
        row.statusProductId &&
        row.documents_statement_tcs.length == 0
      ) {
        return "need-photo";
      }
      if (row.statusProductId == 3) {
        return "warning-row no-border";
      }
      if (row.statusProductId == 4) {
        return "pereproverit-row no-border";
      }
      return "no-border";
    },
    lolkek(scope){
      if (scope.info_edit_messages != undefined) {
        return  scope.info_edit_messages.length > 0 ? scope.info_edit_messages[0].message : ''
      }
      return ""
    },
    forceRerender() {
      this.showHead = !this.showHead;
      localStorage.setItem("showHead", this.showHead);
      if (this.showHead) {
        this.textLabels = [
          "№",
          "Дата изготовления гос. регистрационного знака",
          "Cведения о государственном регистрационном номере транспортного средства",
          "Документ, идентифицирующий транспортное средство",
          "Сведения о владельце траспортного средства",
          "ФИО(при наличии)/наимование юридического лица(при наличии)",
          "Вид, серия, номер, дата выдачи документа, удостоверяющего личность /ИНН/ОГРН",
          "Сведения о представителя владельца траспортного средства",
          "ФИО(при наличии) представителя, в том числе представителя специциалированной организации",
          "Вид, серия, номер, дата выдачи документа, удостоверяющего личность",
          "Дата выдачи гос. регистрациионного знака",
          "Скан заявления",
          "Скан документа ТС",
          "Кол-во пластин"
        ];
      } else {
        this.textLabels = [
          "№",
          "Дата",
          "Свед",
          "Док.",
          "Свед. влад.",
          "ФИО",
          "Док.",
          "Свед. пред.",
          "ФИО",
          "Док.",
          "Дата выд.",
          "Заяв.",
          "ТС",
          "Кол"
        ];
      }

      // Сначала скроем компонент
      this.renderComponent = false;

      this.$nextTick(() => {
        // А потом покажем снова
        this.renderComponent = true;
      });
    },
    async startSort() {
      if (localStorage.getItem("date_create")) {
        await this.SORT_PLATE_DATE({
          fieldName: "date_create",
          sortingType: localStorage.getItem("date_create")
        });
      }
      if (localStorage.getItem("plateTypeId")) {
        await this.SORT_PLATE_DATE({
          fieldName: "plateTypeId",
          sortingType: localStorage.getItem("plateTypeId")
        });
      }

      this.dataTable = this.PLATES().slice(0, 250);
      this.currentPage = 1;
    }
  },

  created: async function() {
    this.RESET_HEAD("Список изготовленных знаков");
    if (window.innerWidth < 800) {
      this.isComputer = false;
    }
    const loading = this.$loading({
      lock: true,
      text: "Загрузка",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)"
    });
    await this.GET_DOCUMENT_TYPES();
    await this.GET_OWNER_TYPES();
    await this.GET_PLATE_TYPES();
    await this.plateArray();
    await this.GET_OWNER_DOCUMENT_TYPES();
    await this.GET_PLATES_ALL(this.$route.params.id);
    await this.GET_STATUS();
    this.dateArray();
    this.statusArray();
    await this.startSort();
    loading.close();
    if (localStorage.getItem("showHead") == "false") {
      this.textLabels = [
        "№",
        "Дата",
        "Свед",
        "Док.",
        "Свед. влад.",
        "ФИО",
        "Док.",
        "Свед. пред.",
        "ФИО",
        "Док.",
        "Дата выд.",
        "Заяв.",
        "ТС",
        "Кол"
      ];
    }
    if (this.PAGENUMBER() != 1) {
      this.currentPage = this.PAGENUMBER();
      this.handleCurrentChange(this.PAGENUMBER());
    } else {
      this.dataTable = this.PLATES().slice(0, 250);
    }
  },
  updated: function() {
    if (this.$refs["table"] && this.SCROLLTOP()(this.$route.name) > 0) {
      this.$refs["table"].bodyWrapper.scrollTop = this.SCROLLTOP()(
        this.$route.name
      );
      const id = this.ROWINDEX();
      this.currentRow = this.$refs.table.data.find(el => {
        if (el.id === id) {
          return true;
        }
        return false;
      });
      this.$refs.table.setCurrentRow(this.currentRow);
    }
  },
  mounted: function() {
    // setTimeout(() => {
    //   this.RESET_SCROLL(this.$route.name);
    // }, 4000);
  }
};
</script>

<style scoped>
.table-company {
  height: calc(100vh - 220px);
}
.table-company2 {
  height: calc(100vh - 220px);
}
.table-companyM {
  height: calc(100vh - 180px);
}
.table-companyM2 {
  height: calc(100vh - 240px);
}

.el-table {
  color: black;
}
.head-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bth-style {
  font-size: 15px;
  color: #409eff;
}
.border-check {
  border-radius: 4px
}
</style>
