<template>
  <div class="">
    <el-form
      :model="REGISTRY_FORM()"
      label-width="auto"
      ref="form"
      label-position="left"
      autocomplete="off"
    >
      <div
        style="display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;"
      >
        <div v-if="regId == null">
          <div
            v-for="(item, i) in PLATE_TYPES_LIST()"
            :key="i"
            class="text item"
          >
            <el-form-item
              size="mini"
              :label="item.title"
              v-if="item.title != 'Тип-2'"
            >
              <el-input-number
                :disabled="
                  (INFO_COMPANY().flat_plates && i == 3) ||
                    (INFO_COMPANY().flat_plates2 && i == 5) ||
                    (INFO_COMPANY().flat_plates3 && i == 12) ||
                    (INFO_COMPANY().flat_plates4 && i == 1)
                "
                size="mini"
                v-model="REGISTRY_FORM().list_requested_materials[i].count"
                :min="0"
              ></el-input-number>
            </el-form-item>
          </div>
        </div>
        <div v-else>
          <div
            v-for="(item, i) in REGISTRY_FORM().list_requested_materials"
            :key="i"
            class="text item"
          >
            <el-form-item size="mini" :label="PLATE_TYPES_LIST()[i].title">
              <el-input-number
                size="mini"
                v-model="REGISTRY_FORM().list_requested_materials[i].count"
                :min="0"
              ></el-input-number>
            </el-form-item>
          </div>
        </div>

        <el-form-item label="Пленка" v-if="!regId">
          <el-input-number
            size="mini"
            v-model="REGISTRY_FORM().membrane"
            :min="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="Пленка2" v-else>
          <el-input-number
            size="mini"
            v-model="REGISTRY_FORM().list_requested_materials[10].count"
            :min="0"
          ></el-input-number>
        </el-form-item>
      </div>

      <el-form-item label="Комментарий">
        <el-input
          type="textarea"
          placeholder="Комментарий"
          v-model="REGISTRY_FORM().comment_user"
          maxlength="255"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="footer-dialog">
      <el-button type="danger" @click="closeModal">
        Закрыть
      </el-button>
      <el-button type="danger" v-if="regId" @click="deleteReg">
        Удалить
      </el-button>
      <el-button type="success" @click="submit" v-if="!regId">
        Создать
      </el-button>
      <el-button type="success" @click="update" v-else>
        Обновить
      </el-button>
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["regId"],
  data() {
    return {
      form: {
        companyId: null,
        membrane: null,
        date: null,
        comment_user: null,

        list_requested_materials: []
      }
    };
  },
  methods: {
    ...mapActions([
      "GET_PLATE_TYPES",
      "POST_REGISTRY",
      "GET_REGISTRY",
      "SET_FORM_REGISTRY",
      "PUT_REGISTRY_USER",
      "GET_REGISTRY_NEW",
      "DEL_REGISTRY_USER"
    ]),
    ...mapGetters(["PLATE_TYPES_LIST", "REGISTRY_FORM", "INFO_COMPANY"]),
    closeModal() {
      this.$emit("closeModal");
      this.$emit("closeModal2");
      // Modal
    },
    update() {
      this.PUT_REGISTRY_USER(this.REGISTRY_FORM());
      this.$emit("closeModal");
      setTimeout(() => {
        this.GET_REGISTRY(this.$route.params.id);
        this.GET_REGISTRY_NEW(this.$route.params.id);
      }, 2000);
    },
    deleteReg() {
      this.DEL_REGISTRY_USER(this.REGISTRY_FORM().id);
      this.$emit("closeModal");
      setTimeout(() => {
        this.GET_REGISTRY(this.$route.params.id);
        this.GET_REGISTRY_NEW(this.$route.params.id);
      }, 2000);
    },
    submit() {
      this.POST_REGISTRY(this.REGISTRY_FORM());
      this.$emit("closeModal");
      setTimeout(() => {
        this.GET_REGISTRY(this.$route.params.id);
        this.GET_REGISTRY_NEW(this.$route.params.id);
      }, 2000);
    }
  },
  watch: {
    regId: function(val) {
      if (val) {
        this.SET_FORM_REGISTRY(val);
      } else {
        this.SET_FORM_REGISTRY(this.form);
        this.PLATE_TYPES_LIST().forEach(el => {
          this.REGISTRY_FORM().list_requested_materials.push({
            plateTypeId: el.id,
            count: 0
          });
        });
      }
    }
  },
  created: function() {
    this.form.companyId = this.$route.params.id;
    if (this.regId) {
      this.SET_FORM_REGISTRY(this.regId);
    } else {
      this.SET_FORM_REGISTRY(this.form);
      this.PLATE_TYPES_LIST().forEach(el => {
        this.REGISTRY_FORM().list_requested_materials.push({
          plateTypeId: el.id,
          count: 0
        });
      });
    }
  }
};
</script>

<style scoped>
.footer-dialog {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-select {
  display: block;
}
</style>
