import axios from "axios";

const getDefaultState = () => ({
  company: Array,
  formCompany: Object,
  company_name: String,
  company_names: Array,
  info_company: Object
});

// initial state
const state = getDefaultState();

const getters = {
  COMPANY: state => {
    return state.company;
  },
  COMPANY_ONE: state => {
    return state.formCompany;
  },
  COMPANY_NAME: state => {
    return state.company_name;
  },
  COMPANY_NAMES: state => {
    return state.company_names;
  },
  INFO_COMPANY: state => {
    return state.info_company;
  }
};

const actions = {
  RESET_COMPANY: async ({ commit }) => {
    commit("resetState");
  },
  GET_COMPANY: async ({ commit }) => {
    axios
      .get("/api/company/")
      .then(res => {
        commit("SET_COMPANY", res.data);
      })
      .catch(e => {
        console.log(e);
      });
  },
  GET_COMPANY_NAME: async ({ commit }, id) => {
    axios
      .get(`/api/company/${id}`)
      .then(res => {
        commit("SET_COMPANY_NAME", res.data.title);
      })
      .catch(e => {
        console.log(e);
      });
  },
  GET_COMPANY_NAMES: async ({ commit }) => {
    axios
      .get(`/api/company/names`)
      .then(res => {
        commit("SET_COMPANY_NAMES", res.data);
      })
      .catch(e => {
        e;
      });
  },
  GET_COMPANY_NAME_PERSON: async ({ commit }, id) => {
    axios
      .get(`/api/company/head/${id}`)
      .then(res => {
        commit("SET_COMPANY_NAMES_PERSON", res.data);
      })
      .catch(e => {
        e;
      });
  },
  GET_COMPANY_ONE: async ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/company/${id}`)
        .then(res => {
          commit("SET_COMPANY_ONE", res.data);
          resolve();
        })
        .catch(e => {
          reject();
          e;
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  CREATE_COMPANY: async ({ commit }, companyData) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/company", companyData)
        .then(res => {
          delete res.data["message"];

          resolve();
        })
        .catch(e => {
          e;
          reject();
        });
    });
  },
  UPDATE_COMPANY: async ({ commit }, companyData) => {
    return new Promise((resolve, reject) => {
      axios
        .put("/api/company", companyData)
        .then(() => {
          commit("SET_COMPANY_ONE", companyData);
          resolve();
        })
        .catch(e => {
          e;
          reject();
        });
    });
  },
  GET_COMPANY_STAT_MAT: async ({ commit }) => {
    let arrId = [];
    this.COMPANY_NAMES().forEach(el => {
      arrId.push(el.id);
    });
    axios
      .get("api/company/admin/material?company=" + arrId.join(","))
      .then(res => {
        commit("SET_COMPANY_NAMES", res.data);
      })
      .catch(e => {
        e;
      });
  }
};

const mutations = {
  SET_COMPANY: (state, data) => {
    state.company = data;
  },

  SET_COMPANY_ONE: (state, data) => {
    state.formCompany = data;
  },
  SET_COMPANY_NAME: (state, data) => {
    state.company_name = data;
  },
  SET_COMPANY_NAMES: (state, data) => {
    state.company_names = data;
  },
  SET_COMPANY_NAMES_PERSON: (state, data) => {
    state.info_company = data;
  },

  resetState(state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
